import * as React from 'react';
import { Dispatch, ReactElement } from 'react';

import { pluralize } from '../../utils';
import Panel from '../common/Panel';

import { IAction, IState } from './reducer';
import BuilderSearch, {
    BuilderSearchItemRenderer,
    IProps as IBuilderSearchProps,
} from './search/BuilderSearch';
import BuilderSearchItem from './search/BuilderSearchItem';

export interface IProps {
    dispatch: Dispatch<IAction>;
    searchImplementations: IBuilderSearchProps['implementations'];
    state: IState;
}

export default function BuilderManager({
    dispatch,
    searchImplementations,
    state,
}: IProps): ReactElement {
    const renderItem: BuilderSearchItemRenderer = (
        item,
        model,
    ): ReactElement => {
        return (
            <BuilderSearchItem
                canBeAdded={
                    // Legacy search doesn't return online and correction_type so we need to check more strictly
                    !state.items.some((i) => i.item.uri === item.uri) &&
                    item.online !== false &&
                    (item.correction_type === undefined ||
                        item.correction_type !== 'None')
                }
                dispatch={dispatch}
                item={item}
                key={`${model.name}_${item.id}`}
                model={model}
                state={state}
            />
        );
    };

    return (
        <Panel icon="wrench" title="Manage">
            <div className="list-group">
                <div className="list-group-item">
                    <strong className="fs-3">
                        {pluralize(state.workHours, 'hour')}
                    </strong>
                    <div className="text-muted">
                        {pluralize(state.items.length, 'project')} in{' '}
                        {pluralize(state.blocks.length, 'block')}
                    </div>
                </div>

                {state.canEdit && (
                    <BuilderSearch
                        implementations={searchImplementations}
                        renderItem={renderItem}
                    />
                )}
            </div>
        </Panel>
    );
}
