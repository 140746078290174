import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import ErrorAlert from '../common/ErrorAlert';
import ErrorInline from '../common/ErrorInline';
import Spinner from '../common/Spinner';

import { useContainerContext } from './ContainerContext';
import FocusSupervisor from './FocusSupervisor';
import QuestionProgress from './QuestionProgress';
import Time from './Time';
import TimeProgress from './TimeProgress';
import { isInProgress } from './api-utils';

export default function Header(): ReactElement {
    const {
        error,
        evaluationQuiz,
        progression,
        submitting,
    } = useContainerContext();

    const [offsetFromTop, setOffsetFromTop] = useState<number>(0);
    const [offsetFromTopScrolled, setOffsetFromTopScrolled] = useState<number>(
        0,
    );
    const [top, setTop] = useState<number>(0);

    const computeTop = (): void => {
        const scrollingElement = document.querySelector('main');

        let offsetFromTop = 0;
        let offsetFromTopScrolled = 0;

        // Top header contains the menu in mobile mode
        const topHeader = document.getElementsByClassName('topbar');
        if (topHeader.length === 1) {
            // The top header is static so even scrolled, we keep it visible
            offsetFromTopScrolled += (topHeader[0] as HTMLElement).offsetHeight;
            offsetFromTop += offsetFromTopScrolled;
        }

        // Layout bars contain school location selector, impersonate bar, etc. There can be none.
        const layoutBars = document.getElementById('layout-bars');
        if (layoutBars) {
            offsetFromTop += layoutBars.offsetHeight;
        }

        setOffsetFromTop(offsetFromTop);
        setOffsetFromTopScrolled(offsetFromTopScrolled);

        setTop(
            Math.max(
                offsetFromTopScrolled,
                offsetFromTop - scrollingElement.scrollTop,
            ),
        );
    };

    useEffect(() => {
        computeTop();
    }, []);

    useEffect(() => {
        const onResize = (): void => {
            computeTop();
        };
        window.addEventListener('resize', onResize);

        const scrollingElement = document.querySelector('main');
        const onScroll = (): void => {
            setTop(
                Math.max(
                    offsetFromTopScrolled,
                    offsetFromTop - scrollingElement.scrollTop,
                ),
            );
        };
        document.querySelector('main').addEventListener('scroll', onScroll);

        return (): void => {
            window.removeEventListener('resize', onResize);
            scrollingElement.removeEventListener('scroll', onScroll);
        };
    }, [offsetFromTop, offsetFromTopScrolled]);

    const hasGlobalError =
        (!progression || !isInProgress(progression)) && error;

    return (
        <div className="header">
            <div className="level1" style={{ top }}>
                <div className="left">
                    <h1>{evaluationQuiz.name}</h1>
                </div>

                <div className="center">
                    {isInProgress(progression) && (
                        <div>
                            <TimeProgress />
                            <div className="message text-danger">
                                <FocusSupervisor />
                                {error && <ErrorInline error={error} />}
                            </div>
                        </div>
                    )}
                </div>

                <div className="right">
                    {isInProgress(progression) && <QuestionProgress />}

                    {!isInProgress(progression) &&
                        evaluationQuiz.timeAllowed > 0 && (
                            <Time
                                label="time allowed"
                                value={evaluationQuiz.timeAllowed}
                            />
                        )}
                </div>
            </div>

            <div className="level2" style={{ marginTop: `${top}px` }}>
                {isInProgress(progression) && (
                    <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: progression.html }}
                    />
                )}

                {hasGlobalError && (
                    <div className="row">
                        <div className="col-md-6 col-md-offset-3">
                            <ErrorAlert error={error} />
                        </div>
                    </div>
                )}

                {!progression && submitting && <Spinner />}
            </div>
        </div>
    );
}
