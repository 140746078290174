import * as React from 'react';
import { ReactElement } from 'react';

import ErrorInline from '../common/ErrorInline';

import { ISearchResponse } from './api-rest-adapter';

interface IProps {
    result: ISearchResponse;
}

export default function SearchNoResult({ result }: IProps): ReactElement {
    return (
        <>
            {result.error ? (
                <ErrorInline error={result.error} />
            ) : (
                <span className="text-muted">
                    No results found for: <em>{result.query}</em>
                </span>
            )}
        </>
    );
}
