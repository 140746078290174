import { EventRsvpStatus, IEventRsvp } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { put } from '../../api/utils';
import ErrorInline from '../common/ErrorInline';
import Icon from '../common/Icon';

interface IRsvpAction {
    icon: string;
    status: EventRsvpStatus;
    text: string;
    variant: string;
}

const RSVP_ACTIONS: IRsvpAction[] = [
    {
        icon: 'check',
        status: 'Yes',
        text: 'I will attend',
        variant: 'success',
    },
    {
        icon: 'times',
        status: 'No',
        text: 'Not attending',
        variant: 'danger',
    },
];

interface IProps {
    csrfToken: string;
    eventRsvp: IEventRsvp;
}

export default function Rsvp({
    csrfToken,
    eventRsvp: initialEventRsvp,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [eventRsvp, setEventRsvp] = useState<IEventRsvp>(initialEventRsvp);
    const [loadingStatus, setLoadingStatus] = useState<EventRsvpStatus | null>(
        null,
    );

    const updateEventRsvp = async (status: EventRsvpStatus): Promise<void> => {
        if (loadingStatus !== null) {
            return;
        }
        const msg = 'Are you sure you want to change your RSVP for this event?';
        if (eventRsvp.status !== 'Pending' && !confirm(msg)) {
            return;
        }

        setError(null);
        setLoadingStatus(status);

        try {
            const res = await put<IEventRsvp>(eventRsvp.uri, csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                event_rsvp: { status: status.toLowerCase() },
                /* eslint-enable @typescript-eslint/camelcase */
            });
            setEventRsvp(res);
        } catch (err) {
            setError(err.message);
        }

        setLoadingStatus(null);
    };

    return (
        <div
            className="d-flex flex-column gap-3 mb-0 p-4 well"
            style={{ width: 'fit-content' }}
        >
            <strong>Will you be attending this event?</strong>

            <div className="d-flex gap-3 flex-wrap">
                {RSVP_ACTIONS.map((action) => (
                    <button
                        className={`btn btn-sm btn-${
                            eventRsvp.status === action.status
                                ? action.variant
                                : 'default'
                        } no-outline`}
                        disabled={loadingStatus !== null}
                        key={action.status}
                        onClick={(): void => {
                            eventRsvp.status !== action.status &&
                                updateEventRsvp(action.status);
                        }}
                    >
                        <Icon
                            fixedWidth
                            name={
                                loadingStatus === action.status
                                    ? 'circle-o-notch'
                                    : action.icon
                            }
                            spin={loadingStatus === action.status}
                            text={action.text}
                        />
                    </button>
                ))}
            </div>

            {error && <ErrorInline error={error} />}
        </div>
    );
}
