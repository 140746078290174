import { IUserContainer } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import CopyOnClick from '../common/CopyOnClick';
import Icon from '../common/Icon';

interface IProps {
    container: IUserContainer;
}

function containerPortURL(
    container: IUserContainer,
    port: number,
    https?: boolean,
): string {
    let url = container.host;

    if (https) {
        url = `https://${url}`;
    } else {
        url = `http://${url}`;
    }

    if (port !== 80 && port !== 443) {
        url += `:${port}`;
    }

    return url;
}

function portToStr(port: number): string {
    const portMapping = {
        21: 'FTP',
        22: 'SSH',
        3306: 'MySQL',
        443: 'HTTPS',
        80: 'HTTP',
    };

    return portMapping[port];
}

export default function UserContainerDetail({
    container,
}: IProps): ReactElement {
    const httpPorts = Object.entries(container.ports)
        .map((p) => +p[0])
        .filter((p) => (p === 80 || p >= 3000) && p !== 3306);
    const httpsPort = Object.entries(container.ports)
        .map((p) => +p[0])
        .find((p) => p === 443);

    return (
        <div className="d-flex flex-wrap gap-5 mt-3">
            {/* Credentials */}
            <div className="p-4" style={{ flexShrink: 0 }}>
                <div className="d-flex flex-column gap-3">
                    <h4 className="mt-0">
                        <Icon className="text-info" name="user" />
                        <span className="ml-2">Credentials</span>
                    </h4>

                    <div className="d-flex gap-2">
                        <strong>Host</strong>
                        <CopyOnClick copyText={container.host}>
                            <small>{container.host}</small>
                        </CopyOnClick>
                    </div>
                    <div className="d-flex gap-2">
                        <strong>Username</strong>
                        <CopyOnClick
                            copyText={container.container_id.slice(0, 12)}
                        >
                            <small>{container.container_id.slice(0, 12)}</small>
                        </CopyOnClick>
                    </div>
                    <div className="d-flex gap-2">
                        <strong>Password</strong>
                        <CopyOnClick copyText={container.password}>
                            <small>{container.password}</small>
                        </CopyOnClick>
                    </div>
                </div>
            </div>

            {/* HTTP & HTTPS */}
            <div className="p-4" style={{ flex: '1 1 40%' }}>
                <div className="d-flex flex-column gap-3">
                    <h4 className="mt-0">
                        <Icon className="text-info" name="globe" />
                        <span className="ml-2">Web access</span>
                    </h4>

                    <div className="align-items-center d-flex flex-wrap gap-2">
                        {httpsPort && (
                            <a
                                className="btn btn-outline-primary"
                                href={containerPortURL(
                                    container,
                                    httpsPort,
                                    true,
                                )}
                                rel="noreferrer"
                                target="_blank"
                            >
                                HTTPS
                            </a>
                        )}
                        {httpPorts.map((port) => (
                            <a
                                className={`btn btn-${
                                    portToStr(port)
                                        ? 'outline-primary'
                                        : 'default'
                                }`}
                                href={containerPortURL(container, port)}
                                key={port}
                                rel="noreferrer"
                                target="_blank"
                            >
                                {portToStr(port) || port}
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            {/* Port mapping */}
            <div className="p-4" style={{ flex: '1 1 35%' }}>
                <div className="d-flex flex-column gap-3">
                    <h4 className="mt-0">
                        <Icon className="text-info" name="map-signs" />
                        <span className="ml-2">Port mapping</span>
                    </h4>

                    <div className="align-items-center d-flex flex-wrap">
                        {Object.entries(container.ports).map((port) => (
                            <div
                                className={`align-items-center ${
                                    portToStr(+port[0]) ? 'text-primary' : ''
                                } d-flex gap-2`}
                                key={`${port[0]} --> ${port[1]}`}
                                style={{ padding: '5px 10px' }}
                            >
                                <strong>
                                    {portToStr(+port[0]) || port[0]}
                                </strong>
                                <Icon name="long-arrow-right" />
                                <span>{port[1]}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
