import { ISearchModel } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import { ISearchResult } from '../../../search/SearchBar';
import SearchResultList from '../../../search/SearchResultList';
import { ISearchResponse } from '../../../search/api-rest-adapter';
import { BuilderSearchItemRenderer } from '../BuilderSearch';

interface IProps {
    csrfToken: string;
    model: ISearchModel;
    renderItem: BuilderSearchItemRenderer;
    result: ISearchResponse;
}

export default function BuilderSearchItemList({
    csrfToken,
    model,
    renderItem,
    result,
}: IProps): ReactElement {
    const elemID = `${model.name}-list-collapse`;
    const loadMoreURI = result.results[model.name].loadMoreURI;
    const results = result.results[model.name].items;

    const [hasMoreToLoad, setHasMoreToLoad] = useState<boolean>(false);
    const [items, setItems] = useState<ISearchResult[]>(results);

    useEffect(() => setHasMoreToLoad(loadMoreURI !== undefined), [loadMoreURI]);
    useEffect(() => setItems(results), [results]);

    const onLoadedMoreItems = (
        newItems: ISearchResult[],
        nextURI?: string,
    ): void => {
        setItems((i) => i.concat(newItems));
        setHasMoreToLoad(nextURI !== undefined);
    };

    return (
        <div className="list-group-item">
            {items.length > 0 ? (
                <a
                    className="align-items-center d-flex justify-content-between"
                    data-toggle="collapse"
                    href={`#${elemID}`}
                    style={{ textDecoration: 'none' }}
                >
                    <span>{model.human}</span>
                    <span className="badge">
                        {items.length}
                        {hasMoreToLoad && ' +'}
                    </span>
                </a>
            ) : (
                model.human
            )}
            <SearchResultList
                className="collapse"
                csrfToken={csrfToken}
                id={elemID}
                items={items}
                loadMoreURI={loadMoreURI}
                model={model}
                onLoadedMoreItems={onLoadedMoreItems}
                renderItem={renderItem}
                style={{
                    marginBottom: 0,
                    marginTop: '10px',
                    maxHeight: '200px',
                    overflowY: 'scroll',
                }}
            />
        </div>
    );
}
