import { IProject } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Tooltip from '../../common/Tooltip';

interface IProps {
    project: IProject;
    size: number;
}

export default function ProjectCircleLabel({
    project,
    size,
}: IProps): ReactElement {
    if (project.score.mandatory === null && project.score.optional === null) {
        return null;
    }

    return (
        <div
            className="p-1 position-absolute project-circle-score text-center"
            style={{
                borderRadius: 10,
                width: size - 25,
            }}
        >
            {project.score.mandatory !== null && (
                <Tooltip title="Score of Mandatory Tasks">
                    <span className="d-block">{project.score.mandatory}%</span>
                </Tooltip>
            )}

            {project.isOptional && project.score.optional === 0 && (
                <small className="text-muted">
                    {size >= 90 && 'Advanced'}
                    {size < 90 && 'Advan.'}
                </small>
            )}

            {project.score.optional > 0 && (
                <Tooltip title="Score of Advanced Tasks">
                    <small className="text-muted">
                        {project.score.optional}%
                    </small>
                </Tooltip>
            )}
        </div>
    );
}
