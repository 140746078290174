import * as React from 'react';
import { CSSProperties, ReactElement, ReactNode, useState } from 'react';

import Icon from './Icon';

export interface IPanelHeadingTag {
    text?: string;
    variant?: 'default' | 'success' | 'warning';
}

interface IProps {
    children: ReactNode;
    className?: string;
    collapsed?: boolean;
    collapsible?: boolean;
    icon?: string;
    iconSpin?: boolean;
    id?: string;
    onExpand?: () => Promise<void>;
    renderActions?: () => ReactElement;
    style?: CSSProperties;
    tags?: IPanelHeadingTag[];
    title?: string;
    variant?: 'default' | 'primary' | 'info' | 'warning' | 'danger';
}

export default function Panel({
    children,
    className = '',
    collapsed: initialCollapsed = true,
    collapsible = false,
    icon,
    iconSpin,
    id,
    onExpand,
    renderActions,
    style = {},
    tags,
    title,
    variant = 'default',
}: IProps): ReactElement {
    const [collapsed, setCollapsed] = useState<boolean>(initialCollapsed);

    const variantClass = variant ? `panel-${variant}` : '';
    const fullClass = `panel ${variantClass} ${className}`;

    const onChevronClick = (): void => {
        if (collapsed && onExpand) {
            onExpand();
            setCollapsed(false);
        }
    };

    return (
        <div className={fullClass} id={id} style={style}>
            {title && (
                <div
                    className={`panel-heading${
                        renderActions ? ' panel-heading-actions' : ''
                    }`}
                >
                    <h3
                        className={`${tags ? 'd-inline-flex ' : ''}panel-title`}
                    >
                        {icon && (
                            <Icon
                                className="panel-title-icon"
                                name={icon}
                                spin={iconSpin}
                            />
                        )}
                        {collapsible && (
                            <a
                                aria-controls={`collapse-${id}`}
                                aria-expanded={!collapsed}
                                data-parent={`#${id}`}
                                data-toggle="collapse"
                                href={`#collapse-${id}`}
                                onClick={onChevronClick}
                                role="button"
                            >
                                <Icon
                                    className="mr-2"
                                    fixedWidth
                                    name="chevron-right"
                                />
                            </a>
                        )}
                        {tags ? (
                            <div>
                                <div>{title}</div>
                                <div
                                    className="fs-5"
                                    style={{ marginTop: '6px' }}
                                >
                                    {tags.map((tag, i) => (
                                        <span
                                            className={`${
                                                tag.variant
                                                    ? 'label label-' +
                                                      tag.variant +
                                                      ''
                                                    : 'text-muted'
                                            } mr-1`}
                                            key={`${i}-${tag.text}`}
                                        >
                                            {tag.text}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <span>{title}</span>
                        )}
                    </h3>
                    {renderActions?.()}
                </div>
            )}
            {collapsible ? (
                <div
                    className={`panel-collapse collapse${
                        collapsed ? '' : ' in'
                    }`}
                    id={`collapse-${id}`}
                    role="tabpanel"
                >
                    {children}
                </div>
            ) : (
                children
            )}
        </div>
    );
}
