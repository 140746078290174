import { IProject } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { isPathColored, isRowFromLeftToRight } from '../utils';

interface IProps {
    currentProject?: IProject;
    rows: IProject[][];
    rowIndex: number;
    spaceBetweenTwoRows: number;
    thickness: number;
    zIndex: number;
}

const RADIUS = 200;

export default function Curve({
    currentProject,
    rows,
    rowIndex,
    spaceBetweenTwoRows,
    thickness,
    zIndex,
}: IProps): ReactElement | null {
    const fromLTR = isRowFromLeftToRight(rows, rowIndex);
    const isFirstRow = rowIndex === 0;

    if (isFirstRow) {
        return null;
    }

    return (
        <div
            // We use the first project of the previous row
            className={`progress-bordered ${
                isPathColored(rows[rowIndex - 1][0], currentProject)
                    ? 'active'
                    : ''
            }`}
            style={{
                borderBottomLeftRadius: fromLTR ? undefined : RADIUS,
                borderBottomRightRadius: fromLTR ? RADIUS : undefined,
                borderStyle: 'solid',
                borderTopLeftRadius: fromLTR ? undefined : RADIUS,
                borderTopRightRadius: fromLTR ? RADIUS : undefined,
                borderWidth: `${thickness}px ${
                    fromLTR ? thickness : 0
                }px ${thickness}px  ${fromLTR ? 0 : thickness}px`,
                height: spaceBetweenTwoRows + thickness / 2,
                left: fromLTR ? undefined : -spaceBetweenTwoRows / 2,
                position: 'absolute',
                right: fromLTR ? -spaceBetweenTwoRows / 2 : undefined,
                top: -(spaceBetweenTwoRows / 2) - thickness / 4,
                width: '60%',
                zIndex,
            }}
        />
    );
}
