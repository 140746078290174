import * as React from 'react';
import { ChangeEvent, ReactElement } from 'react';

import { IColumns } from './DataTable';
import { IAction, IState, SelectOne } from './reducer';

interface IProps<T, C> {
    columns: IColumns<T, C>;
    dispatch: (action: IAction) => void;
    group: {
        items: T[];
        label: string;
    };
    renderItem: (item: T) => ReactElement;
    state: IState<T>;
}

export default function DataTableRowsGroup<T, C>({
    columns,
    dispatch,
    group,
    renderItem,
    state,
}: IProps<T, C>): ReactElement {
    return (
        <>
            <tr className="bg-info">
                <td colSpan={1 + columns.columns.length}>
                    <span className="fw-bold">{group.label}</span>
                </td>
            </tr>

            {group.items.map((item) => (
                <tr key={state.keyExtractor(item)}>
                    <td>
                        <div className="align-items-center d-flex gap-4">
                            <label className="d-block m-0 px-2">
                                <input
                                    checked={state.selected.includes(
                                        state.keyExtractor(item),
                                    )}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ): void =>
                                        dispatch(
                                            new SelectOne(
                                                state.keyExtractor(item),
                                                e.target.checked,
                                            ),
                                        )
                                    }
                                    type="checkbox"
                                />
                            </label>

                            {renderItem(item)}
                        </div>
                    </td>

                    {columns.columns?.map((col, index) => (
                        <td key={columns.keyExtractor(col)}>
                            {columns.renderCell(item, index, col)}
                        </td>
                    ))}
                </tr>
            ))}
        </>
    );
}
