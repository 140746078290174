import { ICurrentActivity } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import DateTime from '../common/DateTime';
import Icon from '../common/Icon';

import Card from './Card';

interface IProps {
    currentActivities: Record<string, ICurrentActivity[]>;
}

export default function CurrentActivities({
    currentActivities,
}: IProps): ReactElement {
    const activityKeys = Object.keys(currentActivities).filter(
        (k) => currentActivities[k].length > 0,
    );
    const hasHeading = activityKeys.length > 1;

    return (
        <Card title={`Current ${hasHeading ? 'activities' : 'projects'}`}>
            {activityKeys.length > 0 ? (
                activityKeys.map((k) => (
                    <div className="position-relative mb-2" key={k}>
                        {hasHeading && (
                            <div
                                className="list-group-item position-sticky fw-500 text-primary"
                                style={{
                                    paddingBottom: 4,
                                    paddingTop: 4,
                                    top: 0,
                                    zIndex: 2,
                                }}
                            >
                                {k}
                            </div>
                        )}
                        {currentActivities[k].map((activity) => (
                            <a
                                className="d-flex flex-column gap-2 list-group-item"
                                href={activity.uri}
                                key={activity.id}
                            >
                                <span className="d-flex align-items-baseline">
                                    <span className="flex-grow-1">
                                        {activity.name}
                                    </span>
                                    {activity.label && (
                                        <span
                                            className="label label-default fs-small fw-400"
                                            style={{
                                                maxWidth: '100px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {activity.label}
                                        </span>
                                    )}
                                </span>
                                {activity.starts_at && (
                                    <span className="fs-6">
                                        <DateTime
                                            showTime={activity.show_time}
                                            value={activity.starts_at}
                                        />
                                        {activity.ends_at && (
                                            <span>
                                                {' to '}
                                                <DateTime
                                                    showTime={
                                                        activity.show_time
                                                    }
                                                    value={activity.ends_at}
                                                />
                                            </span>
                                        )}
                                    </span>
                                )}
                            </a>
                        ))}
                    </div>
                ))
            ) : (
                <div className="empty-state fs-5 border-0 mt-5">
                    <Icon name="graduation-cap" />
                    <span className="empty-state-title">
                        No current {hasHeading ? 'activity' : 'project'}
                    </span>
                </div>
            )}
        </Card>
    );
}
