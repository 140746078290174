import * as React from 'react';
import { ReactElement, useRef } from 'react';

import Icon from './Icon';

interface IProps {
    onSearch: (search: string) => void;
    placeholder?: string;
    search: string;
    width?: string;
}

export function match(query: string, s: string): boolean {
    if (!query || !s) {
        return false;
    }

    return s.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

export default function Search({
    onSearch,
    placeholder = 'Search ...',
    search,
    width = '100%',
}: IProps): ReactElement {
    const inputRef = useRef<HTMLInputElement>(null);

    const onClearSearch = (): void => {
        onSearch('');
        inputRef.current?.focus();
    };

    return (
        <div
            className="align-items-center d-flex"
            style={{ position: 'relative', width: width }}
        >
            <input
                className="form-control"
                onChange={(e): void => onSearch(e.currentTarget.value)}
                placeholder={placeholder}
                ref={inputRef}
                type="search"
                value={search}
            />
            {search && (
                <span
                    role="button"
                    style={{
                        position: 'absolute',
                        right: '5px',
                        zIndex: 3,
                    }}
                >
                    <Icon
                        color="#9EB2B9"
                        fixedWidth
                        name="times-circle"
                        onClick={onClearSearch}
                    />
                </span>
            )}
        </div>
    );
}
