import * as React from 'react';
import { ReactElement } from 'react';

import UserFileLinks from './UserFileLinks';
import { IState } from './reducer';

interface IProps {
    state: IState;
}

export default function Matches({ state }: IProps): ReactElement {
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th>Subject</th>
                    <th>Source</th>
                    <th>Similarity (%)</th>
                </tr>
            </thead>
            <tbody>
                {state.matchPage.matches.map((match) => (
                    <tr key={match.id}>
                        <td>
                            <UserFileLinks matchUser={match.subject} />
                        </td>
                        <td>
                            <UserFileLinks matchUser={match.source} />
                        </td>
                        <td>{match.similarity}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
