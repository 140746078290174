import * as React from 'react';
import { ReactElement } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { useContainerContext } from './ContainerContext';
import { isInProgress } from './api-utils';

export default function QuestionProgress(): ReactElement {
    const { progression } = useContainerContext();

    return (
        isInProgress(progression) && (
            <div className="question-progress">
                <CircularProgressbar
                    styles={{
                        path: {
                            stroke: '#FF2666',
                        },
                        text: {
                            fill: '#FF2666',
                            fontSize: '1.3em',
                        },
                    }}
                    text={`${progression.nb_questions_done}/${progression.nb_questions}`}
                    value={
                        (progression.nb_questions_done /
                            progression.nb_questions) *
                        100
                    }
                />
            </div>
        )
    );
}
