import * as React from 'react';
import { ChangeEvent, ReactElement, useState } from 'react';

import { patch } from '../../../../api/utils';
import ErrorInline from '../../../common/ErrorInline';
import { IAction, IState, SetLoading, UpdateDailyWorkHours } from '../reducer';

interface IProps {
    dispatch: (action: IAction) => void;
    state: IState;
}

export default function BatchDailyWorkHours({
    dispatch,
    state,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [value, setValue] = useState<number>(state.dailyWorkHours);

    const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const newValue = parseFloat(e.currentTarget.value);
        if (Number.isNaN(newValue)) {
            setValue(state.validationRules.daily_work_hours.min);
        } else {
            setValue(newValue);
        }
    };

    const onSubmit = async (): Promise<void> => {
        setError(null);
        dispatch(new SetLoading(true));

        try {
            await patch(state.updateBatchURI, state.csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                batch: {
                    daily_work_hours: value,
                },
                /* eslint-enable @typescript-eslint/camelcase */
            });
            dispatch(new UpdateDailyWorkHours(value));
        } catch (err) {
            setError(err.message);
        }

        dispatch(new SetLoading(false));
    };

    return (
        <div>
            <div className="field">
                <label htmlFor="planner-daily-work-hours-input">
                    Daily work hours
                </label>
                <input
                    className="form-control"
                    disabled={state.loading}
                    id="planner-daily-work-hours-input"
                    max={state.validationRules.daily_work_hours.max}
                    min={state.validationRules.daily_work_hours.min}
                    onBlur={onSubmit}
                    onChange={onChange}
                    step={0.5}
                    type="number"
                    value={value}
                />
            </div>

            {error && <ErrorInline error={error} />}
        </div>
    );
}
