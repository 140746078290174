import * as React from 'react';
import { FormEvent, ReactElement, useEffect, useState } from 'react';

import Code from '../common/Code';
import FadeIn from '../common/FadeIn';
import Spinner from '../common/Spinner';

import AnswerInput from './AnswerInput';
import AnswerOption from './AnswerOption';
import { useContainerContext } from './ContainerContext';
import NavigationButton from './NavigationButton';
import { submitAnswers } from './api-rest-adapter';
import { isInProgress, isMultipleChoiceQuestion } from './api-utils';

export default function Question(): ReactElement {
    const context = useContainerContext();
    const { progression, submitting } = context;

    const [hasTouchedAnswers, setHasTouchedAnswers] = useState<boolean>(false);
    const [answers, setAnswers] = useState<string[]>([]);

    useEffect(() => {
        if (isInProgress(progression)) {
            if (progression.question.answers) {
                setAnswers(progression.question.answers);
            } else {
                setAnswers([]);
            }

            setHasTouchedAnswers(false);
        }
    }, [progression]);

    if (!isInProgress(progression)) {
        return null;
    }

    const { question } = progression;
    /* eslint-disable @typescript-eslint/camelcase */
    const { category, title, type } = question;
    const isAutoSubmit = question.auto_submit;
    const isRequired = question.need_answer;
    const subTitle = question.sub_title;
    /* eslint-enable @typescript-eslint/camelcase */

    const canSubmit =
        hasTouchedAnswers &&
        (!isRequired || (isRequired && answers.length > 0));

    const onChangeAnswers = async (newAnswers: string[]): Promise<void> => {
        setAnswers(newAnswers);
        setHasTouchedAnswers(true);

        if (isAutoSubmit) {
            await submitAnswers(context, newAnswers);
        }
    };

    const onSubmit = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        await submitAnswers(context, answers);
    };

    return (
        <FadeIn>
            <div className="question">
                <div className="header">
                    <span className="label label-info">{category}</span>

                    <h2
                        className="title"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />

                    <div className="sub-title">
                        <Code content={subTitle} />
                    </div>
                </div>

                <form onSubmit={onSubmit}>
                    <div>
                        {type === 'Input' && (
                            <AnswerInput
                                answers={answers}
                                onChangeAnswers={onChangeAnswers}
                                question={question}
                            />
                        )}

                        {isMultipleChoiceQuestion(question) && (
                            <div className="answer-option-container">
                                {question.items.map((item) => (
                                    <AnswerOption
                                        answers={answers}
                                        item={item}
                                        key={item.value}
                                        onChangeAnswers={onChangeAnswers}
                                        question={question}
                                    />
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="actions">
                        <div>
                            <span
                                style={{
                                    display: 'inline-block',
                                    marginRight: '5px',
                                }}
                            >
                                <NavigationButton way="Beginning" />
                            </span>
                            <NavigationButton way="Prev" />
                        </div>

                        <div>
                            {submitting && <Spinner />}

                            {!submitting && !isAutoSubmit && (
                                <button
                                    className="btn btn-lg btn-primary"
                                    disabled={!canSubmit}
                                    type="submit"
                                >
                                    Submit
                                </button>
                            )}
                        </div>

                        <div>
                            <span
                                style={{
                                    display: 'inline-block',
                                    marginRight: '5px',
                                }}
                            >
                                <NavigationButton way="Next" />
                            </span>
                            <NavigationButton way="LastAnswered" />
                        </div>
                    </div>
                </form>
            </div>
        </FadeIn>
    );
}
