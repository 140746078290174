import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';
import Search from '../common/Search';

export type VideoRoomsLayout = 'grid' | 'list';

interface IProps {
    createVideoRoomURI: string | null;
    layout: VideoRoomsLayout;
    search: string;
    setLayout: (layout: VideoRoomsLayout) => void;
    setSearch: (search: string) => void;
}

export default function VideoRoomsHeadPanel({
    createVideoRoomURI,
    layout,
    search,
    setLayout,
    setSearch,
}: IProps): ReactElement {
    return (
        <div className="panel panel-default video-rooms-head">
            {/* Title / Layout */}
            <div className="panel-heading panel-heading-actions">
                <h3 className="panel-title">Video conferencing rooms</h3>
                <div>
                    <div className="btn-group" role="group">
                        <div
                            className={`btn ${
                                layout === 'grid' ? 'btn-info' : 'btn-default'
                            }`}
                            onClick={(): void => setLayout('grid')}
                        >
                            <Icon fixedWidth name="th" />
                        </div>
                        <div
                            className={`btn ${
                                layout === 'list' ? 'btn-info' : 'btn-default'
                            }`}
                            onClick={(): void => setLayout('list')}
                        >
                            <Icon fixedWidth name="list-ul" />
                        </div>
                    </div>
                    {createVideoRoomURI && (
                        <a
                            className="btn btn-primary"
                            href={createVideoRoomURI}
                            style={{ marginLeft: '12px' }}
                        >
                            <Icon name="plus-circle" text="New room" />
                        </a>
                    )}
                </div>
            </div>

            {/* Search */}
            <div className="panel-body">
                <div style={{ alignItems: 'center', display: 'flex' }}>
                    <Icon fixedWidth name="search" />
                    <Search
                        onSearch={setSearch}
                        placeholder="Search for rooms or interests"
                        search={search}
                    />
                </div>
            </div>
        </div>
    );
}
