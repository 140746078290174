import { IBatch, ISchoolLocation } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import Tag from '../../tags/Tag';

interface IProps {
    batches: IBatch[];
    id: string;
    name: string;
    schoolLocations: ISchoolLocation[];
    value: number[];
}

function batchNumbers(batches: IBatch[]): number[] {
    return Array.from(new Set(batches.map((b) => b.number)));
}

export default function MultiSLBatches({
    batches: allBatches,
    id: inputId,
    name: inputName,
    schoolLocations,
    value,
}: IProps): ReactElement {
    const [batches, setBatches] = useState<IBatch[]>([]);
    const [numbers, setNumbers] = useState<Set<number>>(new Set());
    const [schoolLocationIds, setSchoolLocationIds] = useState<Set<number>>(
        new Set(schoolLocations.map((sl) => sl.id)),
    );

    useEffect(() => {
        const selectedBatches = allBatches.filter((b) => value.includes(b.id));
        setBatches(selectedBatches);
        setNumbers(new Set(selectedBatches.map((b) => b.number)));
        if (value.length > 0) {
            setSchoolLocationIds(
                new Set(selectedBatches.map((b) => b.school_location.id)),
            );
        }
    }, [value]);

    const filterBatches = (
        numbers: Set<number>,
        schoolLocationIds: Set<number>,
    ): void => {
        const newBatches = allBatches.filter(
            (b) =>
                numbers.has(b.number) &&
                schoolLocationIds.has(b.school_location.id),
        );
        setBatches(newBatches);
    };

    const onRemoveBatch = (id: number): void => {
        const newBatches = batches.filter((b) => b.id !== id);
        setBatches(newBatches);

        const newNumbers = batchNumbers(newBatches);
        if (Array.from(numbers).some((n) => !newNumbers.includes(n))) {
            setNumbers(new Set(newNumbers));
        }
    };

    const onToggleNumber = (number: number): void => {
        const newSet = new Set(numbers);
        newSet.has(number) ? newSet.delete(number) : newSet.add(number);
        setNumbers(newSet);
        filterBatches(newSet, schoolLocationIds);
    };

    const onToggleSchoolLocationId = (id: number): void => {
        const newSet = new Set(schoolLocationIds);
        newSet.has(id) ? newSet.delete(id) : newSet.add(id);
        setSchoolLocationIds(newSet);
        filterBatches(numbers, newSet);
    };

    return (
        <div className="d-flex flex-column gap-5 ml-2">
            <div className="d-flex flex-column gap-2">
                <span className="text-muted" style={{ whiteSpace: 'nowrap' }}>
                    Filter by school location
                </span>
                <div className="align-items-center d-flex flex-wrap gap-3 ml-1">
                    {schoolLocations.map(({ id, name }) => (
                        <span
                            className={`toggle-token${
                                schoolLocationIds.has(id) ? ' active' : ''
                            }`}
                            key={id}
                            onClick={(): void => onToggleSchoolLocationId(id)}
                            role="button"
                        >
                            {name}
                        </span>
                    ))}
                </div>
            </div>

            <div className="d-flex flex-column gap-2">
                <span className="text-muted" style={{ whiteSpace: 'nowrap' }}>
                    Filter by cohort number
                </span>

                <div className="align-items-center d-flex flex-wrap gap-3 ml-1">
                    {batchNumbers(
                        allBatches.filter((b) =>
                            schoolLocationIds.has(b.school_location.id),
                        ),
                    ).map((n) => (
                        <span
                            className={`toggle-token${
                                numbers.has(n) ? ' active' : ''
                            }`}
                            key={n}
                            onClick={(): void => onToggleNumber(n)}
                            role="button"
                        >
                            C#{n}
                        </span>
                    ))}
                </div>
            </div>

            {batches.length > 0 ? (
                <div className="d-flex flex-column gap-2">
                    <strong style={{ whiteSpace: 'nowrap' }}>
                        Individual cohorts invited
                    </strong>
                    <div className="align-items-center d-flex flex-wrap gap-3 ml-1">
                        {batches.map((batch) => (
                            <span key={batch.id}>
                                <input
                                    id={inputId}
                                    name={inputName}
                                    type="hidden"
                                    value={batch.id}
                                />
                                <Tag
                                    fontSize={12}
                                    label={batch.full_name}
                                    onDeleteClick={(): void => {
                                        onRemoveBatch(batch.id);
                                    }}
                                    variant="info"
                                />
                            </span>
                        ))}
                    </div>
                </div>
            ) : (
                /* Rails require the field to be present to reset all the values if none is provided */
                <input id={inputId} name={inputName} type="hidden" />
            )}
        </div>
    );
}
