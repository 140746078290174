import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';
import Tooltip from '../common/Tooltip';

import { IVideo } from './types';

interface IProps {
    height?: string;
    selected: boolean;
    onClick?: (video: IVideo) => void;
    video: IVideo;
    width?: string;
}

export default function VideoThumbnail({
    height,
    selected,
    onClick,
    video,
    width,
}: IProps): ReactElement {
    return (
        <Tooltip title={video.shortDescription}>
            <div
                onClick={
                    selected || !onClick ? null : (): void => onClick(video)
                }
                style={{
                    cursor: selected || !onClick ? null : 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '10px',
                }}
            >
                <div style={{ position: 'relative' }}>
                    <div
                        style={{
                            backgroundColor: '#000',
                            backgroundImage: `url(${video.poster})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            borderRadius: '3px',
                            height: height,
                            width: width,
                        }}
                    />
                    {selected && (
                        <div
                            style={{
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: '#eee',
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: '3em',
                                height: '100%',
                                justifyContent: 'center',
                                left: 0,
                                position: 'absolute',
                                top: 0,
                                width: '100%',
                            }}
                        >
                            <Icon name="play" />
                            <span
                                style={{ fontSize: '14px', marginTop: '6px' }}
                            >
                                Currently playing
                            </span>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        display: '-webkit-box',
                        fontSize: '1.4rem',
                        fontWeight: 'bold',
                        lineHeight: '2rem',
                        marginTop: '6px',
                        maxHeight: '4rem',
                        maxWidth: '192px',
                        overflow: 'hidden',
                        padding: '0 4px',
                        textDecoration: selected ? 'underline' : null,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                    }}
                >
                    {video.title}
                </div>
            </div>
        </Tooltip>
    );
}
