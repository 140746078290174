import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    color?: string | undefined;
    size?: number;
}

export default function ColoredIcon({
    color = undefined,
    size = 70,
}: IProps): ReactElement {
    return (
        <div
            className="colored-icon border-info"
            style={{
                border: `1px solid ${color}`,
                borderRadius: `${(size * 12) / 70}px`,
                height: `${size}px`,
                padding: `${(size * 16) / 70}px`,
                width: `${size}px`,
            }}
        />
    );
}
