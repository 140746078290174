import { IEvaluationQuizQuestion } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ChangeEvent, ReactElement } from 'react';

import { useContainerContext } from './ContainerContext';

interface IProps {
    answers: string[];
    onChangeAnswers: (newAnswers: string[]) => Promise<void>;
    question: IEvaluationQuizQuestion;
}

export default function AnswerInput({
    answers,
    onChangeAnswers,
    question,
}: IProps): ReactElement {
    const { submitting } = useContainerContext();

    /* eslint-disable @typescript-eslint/camelcase */
    const isRequired = question.need_answer;
    /* eslint-enable @typescript-eslint/camelcase */

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        const { value } = e.target;
        onChangeAnswers([value]);
    };

    return (
        <textarea
            className="form-control"
            disabled={submitting}
            onChange={onChange}
            required={isRequired}
            value={answers.length === 1 ? answers[0] : ''}
        />
    );
}
