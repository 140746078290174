import { IGroupActivityGroupUser, IUser } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Tooltip from './Tooltip';

interface IProps {
    border?: boolean;
    color?: string;
    size: number;
    tooltip?: boolean;
    user: IUser | IGroupActivityGroupUser;
}

export default function CircleProfilePicture({
    border = true,
    color = 'white',
    size,
    tooltip = true,
    user,
}: IProps): ReactElement {
    const pictureUrl = user.picture_url
        ? user.picture_url
        : '/images/missing/profile.png';
    const pictureCover = user.picture_url ? 'center / cover' : 'center / 75%';

    const circleElement = (
        <div
            className={`${border ? `border-${color}` : ''} rounded-circle`}
            style={{
                background: `url(${pictureUrl}) ${pictureCover} no-repeat, gray`,
                border: border ? '4px solid' : undefined,
                boxShadow: 'inset 0 0 0.5rem rgba(0,0,0, 0.25)',
                height: size,
                width: size,
            }}
        />
    );

    if (tooltip) {
        return <Tooltip title={user.full_name}>{circleElement}</Tooltip>;
    }

    return circleElement;
}
