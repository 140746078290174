import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

import Player from './Player';
import VideoDescription from './VideoDescription';
import { IVideo } from './types';

interface IProps {
    playerOptions?: VideoJsPlayerOptions;
    video: IVideo;
}

export default function PlayerDescription({
    playerOptions,
    video,
}: IProps): ReactElement {
    const [player, setPlayer] = useState<VideoJsPlayer>(null);

    useEffect(() => {
        if (player && video) {
            player.src([{ src: video.source, type: 'application/x-mpegURL' }]);
            player.poster(video.poster);
        }
    }, [player, video]);

    return (
        <div className="panel panel-default sm-gap">
            <div className="panel-heading">
                <h3 className="panel-title">{video.title}</h3>
            </div>
            <div className="list-group">
                <div className="list-group-item">
                    <div className="player-description-container">
                        <div className="player-container">
                            <Player
                                onPlayerDispose={setPlayer}
                                onPlayerInit={setPlayer}
                                playerOptions={playerOptions}
                            />
                        </div>
                        <div className="description-container">
                            <div className="description-inner-container">
                                <VideoDescription video={video} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
