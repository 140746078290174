import { IBatch } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

interface IProps {
    batches: IBatch[];
    id: string;
    name: string;
    value: number[];
}

export default function SingleSLBatches({
    batches: allBatches,
    id,
    name,
    value,
}: IProps): ReactElement {
    const [batchIds, setBatchIds] = useState<Set<number>>(new Set(value));

    const onToggleBatchId = (id: number): void => {
        setBatchIds((v) => {
            const newSet = new Set(v);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    return (
        <div className="d-flex flex-column gap-5 ml-2">
            <div className="align-items-center d-flex flex-wrap gap-3 ml-1">
                {allBatches.map((batch) => (
                    <span
                        className={`toggle-token${
                            batchIds.has(batch.id) ? ' active' : ''
                        }`}
                        key={id}
                        onClick={(): void => onToggleBatchId(batch.id)}
                        role="button"
                    >
                        {batch.name}
                        {batchIds.has(batch.id) && (
                            <input name={name} type="hidden" value={batch.id} />
                        )}
                    </span>
                ))}
            </div>

            {batchIds.size === 0 && (
                /* Rails require the field to be present to reset all the values if none is provided */
                <input name={name} type="hidden" />
            )}
        </div>
    );
}
