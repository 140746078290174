import { IGroupActivityGroupUser } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import TeamContribution from '../batch_planning_items/TeamContribution';

interface IProps {
    csrfToken: string;
    user: IGroupActivityGroupUser;
}

export default function UserRowMetadata({
    csrfToken,
    user,
}: IProps): ReactElement {
    if (user.metadata?.batch_planning_item?.contribution) {
        return (
            <TeamContribution
                contribution={user.metadata?.batch_planning_item?.contribution}
                csrfToken={csrfToken}
                userId={user.id}
            />
        );
    }

    return null;
}
