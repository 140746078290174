import { ISearchModel } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import SearchBar, { resultLength } from '../../../search/SearchBar';
import SearchNoResult from '../../../search/SearchNoResult';
import { ISearchResponse } from '../../../search/api-rest-adapter';
import { BuilderSearchItemRenderer } from '../BuilderSearch';

import BuilderSearchItemList from './BuilderSearchItemList';

export interface IProps {
    csrfToken: string;
    renderItem: BuilderSearchItemRenderer;
    searchModels: ISearchModel[];
    searchURI: string;
}

export default function BuilderSearchLocal({
    csrfToken,
    renderItem,
    searchModels,
    searchURI,
}: IProps): ReactElement {
    const [result, setResult] = useState<ISearchResponse | null>(null);

    return (
        <div>
            <div className="list-group-item">
                <SearchBar
                    csrfToken={csrfToken}
                    models={searchModels.map((m) => m.name)}
                    onResult={(res): void => setResult(res)}
                    searchURI={searchURI}
                />
            </div>

            {result && result.query.length > 0 && (
                <>
                    {resultLength(result.results) > 0 ? (
                        <>
                            {searchModels.map((model) => (
                                <BuilderSearchItemList
                                    csrfToken={csrfToken}
                                    key={model.name}
                                    model={model}
                                    renderItem={renderItem}
                                    result={result}
                                />
                            ))}
                        </>
                    ) : (
                        <div className="list-group-item">
                            <SearchNoResult result={result} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
