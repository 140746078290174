import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';

interface IProps {
    children: ReactNode;
}

export default function FadeIn({ children }: IProps): ReactElement {
    const animation = useSpring({ from: { opacity: 0 }, to: { opacity: 1 } });

    return <animated.div style={animation}>{children}</animated.div>;
}
