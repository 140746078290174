import { IContainerSpec, IUserContainer } from 'holberton-school-intranet-api';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';

import { match } from '../common/Search';

export interface IUserContainerState {
    containerSpec: IContainerSpec;
    error: string | null;
    info: string | null;
    loading: boolean;
}

export interface IState {
    allSpecs: IUserContainerState[];
    onlyRunning: boolean;
    searchQuery: string;
    searchedSpecs: IUserContainerState[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAction {}

export class SearchContainerSpecs implements IAction {
    constructor(public searchQuery: string) {}
}

export class ToggleOnlyRunning implements IAction {}

class ContainerAction implements IAction {
    constructor(public id: number) {}
}
export class SetError extends ContainerAction {
    constructor(id: number, public error: string) {
        super(id);
    }
}
export class SetLoading extends ContainerAction {
    constructor(id: number, public info: string) {
        super(id);
    }
}
export class RestartContainer extends ContainerAction {
    constructor(id: number) {
        super(id);
    }
}
export class StopContainer extends ContainerAction {
    constructor(id: number) {
        super(id);
    }
}
export class UpdateContainer extends ContainerAction {
    constructor(id: number, public container: IUserContainer) {
        super(id);
    }
}

function searchSpecs(draft: WritableDraft<IState>): void {
    draft.searchedSpecs = draft.allSpecs.filter((cs) => {
        if (draft.onlyRunning && !cs.containerSpec.container) {
            return false;
        }
        if (draft.searchQuery === '') {
            return true;
        }
        return (
            match(draft.searchQuery, cs.containerSpec.name) ||
            match(draft.searchQuery, cs.containerSpec.description)
        );
    });
}

export default function reducer(state: IState, action: IAction): IState {
    if (action instanceof SearchContainerSpecs) {
        const searchQuery = action.searchQuery;

        return produce(state, (draft) => {
            draft.searchQuery = searchQuery;
            searchSpecs(draft);
        });
    }

    if (action instanceof ToggleOnlyRunning) {
        return produce(state, (draft) => {
            draft.onlyRunning = !draft.onlyRunning;
            searchSpecs(draft);
        });
    }

    if (action instanceof ContainerAction) {
        const id = action.id;
        const idx = state.allSpecs.findIndex((c) => c.containerSpec.id === id);

        if (action instanceof SetError) {
            const error = action.error;

            return produce(state, (draft) => {
                draft.allSpecs[idx].error = error;
                draft.allSpecs[idx].info = null;
                draft.allSpecs[idx].loading = false;
                searchSpecs(draft);
            });
        }

        if (action instanceof SetLoading) {
            const info = action.info;

            return produce(state, (draft) => {
                draft.allSpecs[idx].error = null;
                draft.allSpecs[idx].info = info;
                draft.allSpecs[idx].loading = true;
                searchSpecs(draft);
            });
        }

        if (action instanceof RestartContainer) {
            return produce(state, (draft) => {
                draft.allSpecs[idx].info = null;
                draft.allSpecs[idx].loading = false;
                searchSpecs(draft);
            });
        }

        if (action instanceof StopContainer) {
            return produce(state, (draft) => {
                draft.allSpecs[idx].containerSpec.container = undefined;
                draft.allSpecs[idx].info = null;
                draft.allSpecs[idx].loading = false;
                searchSpecs(draft);
            });
        }

        if (action instanceof UpdateContainer) {
            const container = action.container;

            return produce(state, (draft) => {
                draft.allSpecs[idx].containerSpec.container = container;
                draft.allSpecs[idx].info = null;
                draft.allSpecs[idx].loading = false;
                searchSpecs(draft);
            });
        }
    }

    return state;
}
