import {
    GroupNaming,
    ICurrentUser,
    IGroupActivityGroup,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { capitalize, pluralize } from '../../utils';
import Panel, { IPanelHeadingTag } from '../common/Panel';
import { OnSelectHandler } from '../search/SearchAndSelect';

import GroupActions from './GroupActions';
import { OnJoinCompletedHandler } from './JoinButton';
import { OnCancelClickHandler, OnMoveClickHandler } from './UserControls';
import UserRow from './UserRow';
import { isGroupFull } from './utils';

interface IProps {
    csrfToken: string;
    currentUser: ICurrentUser;
    editingUserId: number | null;
    group: IGroupActivityGroup;
    groupMaxSize: number;
    groupNaming: GroupNaming;
    joinGroupURI: string;
    leaveGroupURI: string;
    loading: boolean;
    loadingUserId: number | null;
    onCancelMovingToAnotherGroupClick: OnCancelClickHandler;
    onJoinCompleted: OnJoinCompletedHandler;
    onLeaveCompleted: OnJoinCompletedHandler;
    onMoveToAnotherGroupClick: OnMoveClickHandler;
    onMoveToNewGroupClick: OnMoveClickHandler;
    onRemoveFromGroupClick: OnMoveClickHandler;
    onSelectPeerToMoveWith: OnSelectHandler;
    searchURI: string;
    studentCanChangeGroup: boolean;
}

export default function Group({
    csrfToken,
    currentUser,
    editingUserId,
    joinGroupURI,
    leaveGroupURI,
    group,
    groupMaxSize,
    groupNaming,
    loading,
    loadingUserId,
    onCancelMovingToAnotherGroupClick,
    onJoinCompleted,
    onLeaveCompleted,
    onMoveToAnotherGroupClick,
    onMoveToNewGroupClick,
    onRemoveFromGroupClick,
    onSelectPeerToMoveWith,
    searchURI,
    studentCanChangeGroup,
}: IProps): ReactElement {
    const tags: IPanelHeadingTag[] = [
        {
            text: pluralize(group.users.length, 'student'),
        },
    ];

    if (groupMaxSize) {
        const isFull = isGroupFull(group, groupMaxSize);

        if (isFull) {
            tags.push({
                text: isFull
                    ? 'Full'
                    : `${pluralize(
                          Math.max(0, groupMaxSize - group.users.length),
                          'seat',
                      )} left`,
                variant: isFull ? 'success' : 'warning',
            });
        }
    }

    return (
        <Panel
            className="overflow-visible"
            icon="users"
            key={group.id}
            renderActions={(): ReactElement => (
                <GroupActions
                    csrfToken={csrfToken}
                    currentUser={currentUser}
                    group={group}
                    groupMaxSize={groupMaxSize}
                    joinGroupURI={joinGroupURI}
                    leaveGroupURI={leaveGroupURI}
                    onJoinCompleted={onJoinCompleted}
                    onLeaveCompleted={onLeaveCompleted}
                    studentCanChangeGroup={studentCanChangeGroup}
                />
            )}
            tags={tags}
            title={`${capitalize(groupNaming)} ${group.id}`}
        >
            <>
                {group.users.length > 0 && (
                    <ul className="list-group">
                        {group.users.map((user) => (
                            <li className="list-group-item" key={user.id}>
                                <UserRow
                                    csrfToken={csrfToken}
                                    currentUser={currentUser}
                                    disabled={loading || !!loadingUserId}
                                    editing={editingUserId === user.id}
                                    groupNaming={groupNaming}
                                    loading={loadingUserId === user.id}
                                    onCancelMovingToAnotherGroupClick={
                                        onCancelMovingToAnotherGroupClick
                                    }
                                    onMoveToAnotherGroupClick={
                                        onMoveToAnotherGroupClick
                                    }
                                    onMoveToNewGroupClick={
                                        onMoveToNewGroupClick
                                    }
                                    onRemoveFromGroupClick={
                                        onRemoveFromGroupClick
                                    }
                                    onSelectPeerToMoveWith={
                                        onSelectPeerToMoveWith
                                    }
                                    searchURI={searchURI}
                                    user={user}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </>
        </Panel>
    );
}
