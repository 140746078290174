import { IUserContact } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { get } from '../../api/utils';
import CircleProfilePicture from '../common/CircleProfilePicture';
import CopyOnClick from '../common/CopyOnClick';
import DateTime from '../common/DateTime';
import ErrorAlert from '../common/ErrorAlert';
import Icon from '../common/Icon';
import Spinner from '../common/Spinner';
import Modal from '../common/modal/Modal';

export interface IProjectPeerReview {
    availableReviewersURI: string;
    canReviewPeerDirectly: boolean;
    correctCorrectionURI: string;
    qaReviewsURI: string;
    readyForReviewURI: string;
    reviewDeadline: string | null;
    synchronousManualReview: boolean;
}

interface IProps {
    csrfToken: string;
    peerReview: IProjectPeerReview;
}

export default function ProjectReadyForReviewBody({
    csrfToken,
    peerReview: {
        availableReviewersURI,
        canReviewPeerDirectly,
        correctCorrectionURI,
        reviewDeadline,
        qaReviewsURI,
        synchronousManualReview,
    },
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [availableReviewers, setAvailableReviewers] = useState<
        IUserContact[]
    >([]);

    const onLoadAvailableReviewers = async (): Promise<void> => {
        try {
            setError(null);
            setAvailableReviewers([]);
            setLoading(true);
            const reviewers = await get<IUserContact[]>(
                availableReviewersURI,
                csrfToken,
            );
            if (reviewers.length === 0) {
                setError('No peer is currently able to review your work.');
            } else {
                setAvailableReviewers(reviewers);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {!synchronousManualReview && (
                <p>
                    Now that you are ready to be reviewed, share your link to
                    your peers. You can find some{' '}
                    <a
                        data-toggle="modal"
                        href="#available-reviewers-modal"
                        onClick={onLoadAvailableReviewers}
                    >
                        here
                    </a>
                    .
                </p>
            )}

            {synchronousManualReview && (
                <p>
                    Connect with{' '}
                    <a
                        data-toggle="modal"
                        href="#available-reviewers-modal"
                        onClick={onLoadAvailableReviewers}
                    >
                        one of your peers
                    </a>{' '}
                    now to review your work together. Your score will be updated
                    accordingly.
                </p>
            )}

            <Modal
                id="available-reviewers-modal"
                title="Contact one of your peers"
            >
                {loading && <Spinner />}

                {!loading && error && (
                    <div className="sm-gap">
                        <ErrorAlert error={error} />
                    </div>
                )}

                {availableReviewers.map((contact) => (
                    <div
                        className="align-items-center d-flex justify-content-between"
                        key={contact.user.id}
                    >
                        <CircleProfilePicture size={60} user={contact.user} />
                        <div className="flex-grow-1 ml-2">
                            {contact.user.full_name}
                        </div>
                        <div>
                            {contact.slack_direct_message_url && (
                                <a
                                    href={contact.slack_direct_message_url}
                                    rel="noreferrer"
                                    target="_blank"
                                    title="Slack"
                                >
                                    <Icon fixedWidth name="slack" />
                                </a>
                            )}
                            <a
                                href={`mailto:${contact.email}`}
                                rel="noreferrer"
                                target="_blank"
                                title={`Send email to ${contact.email}`}
                            >
                                <Icon fixedWidth name="envelope" />
                            </a>
                        </div>
                    </div>
                ))}
            </Modal>

            <div className="input-group d-block my-2">
                <span className="form-control user-select-all max-width-250-ellipsis">
                    {correctCorrectionURI}
                </span>
                <span className="input-group-btn">
                    <a className="btn btn-default">
                        <CopyOnClick copyText={correctCorrectionURI}>
                            <Icon fixedWidth name="clipboard" />
                        </CopyOnClick>
                    </a>
                </span>
            </div>

            <p>
                <span>
                    Don&apos;t forget to{' '}
                    <a href={qaReviewsURI}>review one of them</a>
                    {canReviewPeerDirectly ? '' : " once you've been reviewed"}.
                </span>
                {reviewDeadline && (
                    <strong>
                        {' '}
                        Reviews are due by <DateTime value={reviewDeadline} />
                    </strong>
                )}
            </p>
        </>
    );
}
