import { useEffect, useRef } from 'react';

type Callback = () => void;

export default function useInterval(
    callback: Callback,
    delay: number | null,
): void {
    const savedCallback = useRef<Callback>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay === null) {
            return;
        }

        const id = setInterval(() => {
            savedCallback.current();
        }, delay);

        return (): void => clearInterval(id);
    }, [delay]);
}
