import { IBlock, ICurriculumItem } from 'holberton-school-intranet-api';
import produce from 'immer';

import { ISearchResponse } from '../search/api-rest-adapter';

export interface IState {
    blocks: IBlock[];
    canEdit: boolean;
    createBlockURI: string;
    createItemURI: string;
    csrfToken: string;
    curriculumID: number;
    isLocked: boolean;
    items: ICurriculumItem[];
    trackID: number;
    workHours: number;
}

/* eslint-disable  @typescript-eslint/no-empty-interface */
export interface IAction {}
/* eslint-enable  @typescript-eslint/no-empty-interface */

/*
 * Block
 */
class BlockAction implements IAction {
    constructor(public block: IBlock) {}
}
export class CreateBlock extends BlockAction {}
export class DestroyBlock extends BlockAction {}
export class UpdateBlock extends BlockAction {}

/*
 * CurriculumItem
 */
class CurriculumItemAction implements IAction {
    constructor(public item: ICurriculumItem) {}
}
export class UpdateCurriculumItem extends CurriculumItemAction {}

class CollectionAction implements IAction {
    constructor(public items: ICurriculumItem[]) {}
}
export class CreateCurriculumItem extends CollectionAction {}
export class DestroyCurriculumItem extends CollectionAction {}

/*
 * Search
 */
export class SearchItems implements IAction {
    constructor(public result: ISearchResponse) {}
}

/**
 * Misc
 */
export class SetLock implements IAction {
    constructor(public isLocked: boolean) {}
}

export default function reducer(state: IState, action: IAction): IState {
    if (action instanceof BlockAction) {
        const block = action.block;

        if (action instanceof CreateBlock) {
            return produce(state, (draft) => {
                draft.blocks.push(block);
            });
        }
        if (action instanceof DestroyBlock) {
            return produce(state, (draft) => {
                draft.blocks = draft.blocks.filter((b) => b.id !== block.id);
            });
        }
        if (action instanceof UpdateBlock) {
            return produce(state, (draft) => {
                const b = draft.blocks.find((b) => b.id === block.id);
                b.name = block.name;

                draft.items = draft.items.map((item) => ({
                    ...item,
                    block: draft.blocks.find((b) => b.id === item.block.id),
                }));
            });
        }
    }

    if (action instanceof CollectionAction) {
        return produce(state, (draft) => {
            draft.items = [...action.items];
            draft.workHours = draft.items.reduce(
                (acc, i) => acc + i.item.work_hours || 0,
                0,
            );
        });
    }

    if (action instanceof CurriculumItemAction) {
        const item = action.item;
        if (action instanceof UpdateCurriculumItem) {
            return produce(state, (draft) => {
                const i = draft.items.find((i) => i.uri === item.uri);
                draft.items.splice(i.position - 1, 1);
                draft.items.splice(item.position - 1, 0, item);
                draft.items = draft.items.map((item, idx) => ({
                    ...item,
                    position: idx + 1,
                }));
                draft.items.sort((a, b) => a.position - b.position);
            });
        }
    }

    if (action instanceof SetLock) {
        return produce(state, (draft) => {
            draft.isLocked = action.isLocked;
        });
    }

    return state;
}
