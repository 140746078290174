import { IVideoRoom } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    videoRoom: IVideoRoom;
}

export default function VideoRoomLabels({ videoRoom }: IProps): ReactElement {
    return (
        <>
            {videoRoom.block && (
                <span className="label label-info">{videoRoom.block}</span>
            )}
            {videoRoom.level && (
                <span className="label label-primary">{videoRoom.level}</span>
            )}
        </>
    );
}
