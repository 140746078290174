import * as React from 'react';
import { ReactElement, useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';

import 'video.js/dist/video-js.css';
import 'videojs-hls-quality-selector/dist/videojs-hls-quality-selector.css';

interface IProps {
    height?: string | number;
    onPlayerDispose?: (player: VideoJsPlayer) => void;
    onPlayerInit?: (player: VideoJsPlayer) => void;
    playerOptions: VideoJsPlayerOptions;
    width?: string | number;
}

interface IVideoHits {
    [level: string]:
        | {
              [progress: number]: number | undefined;
          }
        | undefined;
}

type PlayerState = 'play' | 'pause';

const defaultPlayerOptions: VideoJsPlayerOptions = {
    aspectRatio: '16:9',
    autoplay: false,
    controls: true,
    userActions: { hotkeys: true },
};

export default function Player({
    height,
    onPlayerDispose,
    onPlayerInit,
    playerOptions,
    width,
}: IProps): ReactElement {
    const playerRef = useRef<HTMLDivElement>(null);
    const currentStatusRef = useRef<PlayerState>('pause');
    const latestPush = useRef<number>(-1);
    const previousProgress = useRef<number>(-1);
    const previousLevel = useRef<string>('undefined');
    const hits = useRef<IVideoHits>({});

    const onProgress = (
        level: string,
        progress: number,
        trackerURL: string,
    ): void => {
        // @progress is the watching progression and seeking in percent
        // converted in round integer, from 0 to 9.
        // We want to track watching progress only when the player is playing.
        if (currentStatusRef.current !== 'play' || progress > 9) {
            return;
        }

        let toUpdate = false;
        let forceUpdate = false;

        if (level !== previousLevel.current) {
            toUpdate = true;
            previousLevel.current = level;
        }
        if (progress !== previousProgress.current) {
            toUpdate = true;

            // Force Update is there to bypass 30 seconds delay
            // at the end of the video.
            if (progress === 9) {
                forceUpdate = true;
            }
            previousProgress.current = progress;
        }

        if (toUpdate) {
            if (hits.current[level] === undefined) {
                hits.current[level] = [];
            }

            if (hits.current[level][progress] === undefined) {
                hits.current[level][progress] = 0;
            }
            hits.current[level][progress] += 1;
        }

        // Convert hits object to string
        // ex: { '1200p': [1,1,1], '240p': [0,0,0,0,1,2,2] }
        // to string => "1200p:0-1|1-1|2-1,240p:4-1|5-2|6-2"
        const hitsArray = [];
        for (const [key, value] of Object.entries(hits.current)) {
            const h = [];
            (value as Array<number>).forEach((v, i) => {
                if (v !== 0) {
                    h.push(`${i}-${v}`);
                }
            });
            if (h.length > 0) {
                hitsArray.push(`${key}:${h.join('|')}`);
            }
        }
        const newHitsString = hitsArray.join(',');

        // Send log if something has changed during past 30 seconds
        const nowTick = Date.now();
        if (
            forceUpdate ||
            (nowTick - latestPush.current > 30000 && newHitsString.length > 0)
        ) {
            // Using image as tracker
            // adding 'tick' as query parameter to bypass web browser cache
            const img = new Image();
            img.src = `${trackerURL}${newHitsString}&${nowTick}`;

            // clean everything for next round
            latestPush.current = nowTick;
            hits.current = {};
        }
    };

    useEffect(() => {
        if (playerRef.current) {
            const videoEl = playerRef.current.querySelector('video');
            const player = videojs(
                videoEl,
                {
                    ...defaultPlayerOptions,
                    ...playerOptions,
                },
                () => {
                    player.hlsQualitySelector({ displayCurrentQuality: true });

                    player.on('dblclick', (): void => {
                        if (player.isFullscreen()) {
                            player.exitFullscreen();
                        } else {
                            player.requestFullscreen();
                        }
                    });
                    player.on('pause', (): void => {
                        currentStatusRef.current = 'pause';
                    });
                    player.on('play', (): void => {
                        currentStatusRef.current = 'play';
                        // to push first traking hit in 3 seconds
                        latestPush.current = Date.now() - 27000;
                    });
                    player.on('timeupdate', (): void => {
                        const pCent = Math.round(
                            (player.currentTime() / player.duration()) * 100,
                        );
                        const qualityLevels = player.qualityLevels();
                        const selectedIndex = qualityLevels.selectedIndex;
                        const selectedQuality = qualityLevels[selectedIndex];
                        if (selectedQuality === undefined) {
                            return;
                        }

                        const info = selectedQuality.id;
                        const level = info.split('=')[1];
                        const trackerURL =
                            info.substr(info.match('/').index).split('.')[0] +
                            '/log?hits=';
                        onProgress(level, Math.round(pCent / 10), trackerURL);
                    });
                },
            );

            onPlayerInit && onPlayerInit(player);

            return (): void => {
                if (player) {
                    onPlayerDispose && onPlayerDispose(null);
                    player.dispose();
                }
            };
        }
    }, [onPlayerInit, onPlayerDispose]);

    return (
        <div
            className="player"
            ref={playerRef}
            style={{ height: height, width: width }}
        >
            <video className="video-js vjs-big-play-centered" />
        </div>
    );
}
