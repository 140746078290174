import * as React from 'react';
import { ReactElement, ReactNode } from 'react';

export type ModalSize = 'lg' | 'sm';

interface IProps {
    children: ReactNode;
    id?: string;
    size?: ModalSize;
    title: string;
}

export default function Modal({
    children,
    id,
    size,
    title,
}: IProps): ReactElement {
    return (
        <div className="modal fade" id={id}>
            <div className={`modal-dialog${size ? ` modal-${size}` : ''}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                        >
                            <span aria-hidden={true}>&times;</span>
                        </button>
                        <h4 className="modal-title">{title}</h4>
                    </div>
                    <div className="modal-body">{children}</div>
                </div>
            </div>
        </div>
    );
}
