import * as React from 'react';
import { ReactElement } from 'react';

import { pluralize } from '../../../utils';
import Spinner from '../../common/Spinner';

import { IState } from './reducer';

interface IProps {
    state: IState;
}

export default function Summary({ state }: IProps): ReactElement {
    const { loading, report } = state;

    return (
        <div className="align-items-center d-flex justify-content-between">
            <div>
                <div className="fs-4 fw-bold">
                    {pluralize(report.working_days, 'working day')}
                </div>
                <div className="text-muted">
                    {pluralize(
                        report.work_hours.available,
                        'available work hour',
                    )}
                </div>
                <div className="text-muted">
                    {pluralize(
                        report.work_hours.required,
                        'required work hour',
                    )}
                </div>
                {report.work_hours.missing > 0 && (
                    <div className="text-bold text-danger">
                        Missing{' '}
                        {pluralize(report.work_hours.missing, 'work hour')}
                    </div>
                )}
            </div>
            <div>{loading && <Spinner />}</div>
        </div>
    );
}
