import {
    ITaskFileComparison,
    ITaskFileComparisonTask,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { Key, ReactElement } from 'react';

import Dropdown from '../../common/Dropdown';
import Icon from '../../common/Icon';
import Tooltip from '../../common/Tooltip';
import SearchAndSelect from '../../search/SearchAndSelect';
import { ISearchResult } from '../../search/SearchBar';

import {
    IAction,
    IState,
    SetCurrentFile,
    SetCurrentTask,
    SetSearchedUser,
} from './reducer';

interface IProps {
    csrfToken: string;
    dispatch: (action: IAction) => void;
    searchURI: string;
    state: IState;
    taskFileComparisons: ITaskFileComparison[];
}

export default function Filters({
    csrfToken,
    dispatch,
    searchURI,
    state,
    taskFileComparisons,
}: IProps): ReactElement {
    const onClearUser = async (): Promise<void> => {
        dispatch(new SetSearchedUser(null));
    };
    const onSelectUser = async (item: ISearchResult): Promise<void> => {
        dispatch(new SetSearchedUser(item));
    };

    return (
        <div className="align-items-center d-flex gap-3">
            <Dropdown
                items={taskFileComparisons.reduce((a, t) => [...a, t.task], [])}
                keyExtractor={(task: ITaskFileComparisonTask): Key => task.id}
                onItemClick={(task: ITaskFileComparisonTask): void =>
                    dispatch(new SetCurrentTask(task))
                }
                placeholder="Task"
                renderItem={(task: ITaskFileComparisonTask): ReactElement => (
                    <>{task.title}</>
                )}
            >
                {state.currentTask.title}
            </Dropdown>

            <Dropdown
                items={taskFileComparisons.filter(
                    (tfc) => tfc.task.id === state.currentTask.id,
                )}
                keyExtractor={(tfc: ITaskFileComparison): Key => tfc.id}
                onItemClick={(tfc: ITaskFileComparison): void =>
                    dispatch(new SetCurrentFile(tfc.filename))
                }
                placeholder="File"
                renderItem={(tfc: ITaskFileComparison): ReactElement => (
                    <div
                        className={`align-items-center d-flex justify-content-between${
                            tfc.status === 'Pending' ? ' text-muted' : ''
                        }`}
                    >
                        {tfc.filename}
                        {tfc.status === 'Pending' && (
                            <Tooltip title={tfc.status}>
                                <Icon fixedWidth name="clock-o" />
                            </Tooltip>
                        )}
                    </div>
                )}
            >
                {state.currentFile}
            </Dropdown>

            <div className="position-relative w-full">
                <SearchAndSelect
                    csrfToken={csrfToken}
                    model="users"
                    name="user_id"
                    onClear={onClearUser}
                    onSelect={onSelectUser}
                    placeholder="Search for a subject student"
                    searchURI={searchURI}
                    selectedItem={state.searchedUser}
                />
            </div>
        </div>
    );
}
