import * as React from 'react';
import { ChangeEvent, FormEvent, ReactElement, useState } from 'react';

import ErrorInline from '../../common/ErrorInline';
import Icon from '../../common/Icon';
import Select from '../../common/select/Select';

import { ISelect } from './DataTable';
import { IState } from './reducer';

interface IProps<T> {
    selects: ISelect[];
    state: IState<T>;
}

export default function DataTableSelectsList<T>({
    selects,
    state,
}: IProps<T>): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<ISelect | null>(null);
    const [inputValue, setInputValue] = useState<string>('');

    const onSubmit = async (select: ISelect, v: string): Promise<void> => {
        setLoading(true);
        setError(null);

        try {
            await select.onSubmit(v, state.selected);
        } catch (err) {
            setError(err.message);
        } finally {
            setSelectedOption(null);
            setLoading(false);
        }
    };

    const onClickOption = async (
        select: ISelect,
        option?: string,
    ): Promise<void> => {
        if (loading) {
            return;
        }

        if (select.hasInput) {
            setSelectedOption(select);
            setInputValue('');
        } else {
            await onSubmit(select, option);
        }
    };

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
        setInputValue(e.target.value);
    };

    const onSubmitInput = async (e?: FormEvent): Promise<void> => {
        e.preventDefault();
        await onSubmit(selectedOption, inputValue);
    };

    return (
        <>
            {error && <ErrorInline className="mb-0" error={error} />}

            {selects.length === 0 && (
                <em className="mr-2 text-muted">No action available</em>
            )}

            {selectedOption ? (
                <form
                    className="align-items-center d-inline-flex gap-3"
                    onSubmit={onSubmitInput}
                >
                    <input
                        autoFocus={true}
                        className="form-control"
                        disabled={loading}
                        onChange={onChangeInput}
                        style={{
                            minWidth: '16ch',
                            width: `${inputValue.toString().length}ch`,
                        }}
                        {...selects[0].options}
                    />

                    <small className="d-inline-flex gap-2">
                        {loading ? (
                            <Icon name="circle-o-notch" spin />
                        ) : (
                            <>
                                <Icon name="check" onClick={onSubmitInput} />
                                <Icon
                                    className="text-danger"
                                    name="times"
                                    onClick={(): void =>
                                        setSelectedOption(null)
                                    }
                                />
                            </>
                        )}
                    </small>
                </form>
            ) : (
                selects.map((select) =>
                    select.hasInput ? (
                        <div
                            key={select.title}
                            onClick={(): Promise<void> => onClickOption(select)}
                            role="button"
                        >
                            <span>{select.title}</span>
                        </div>
                    ) : (
                        <Select
                            disabled={loading}
                            key={select.title}
                            onSubmit={(v: string): Promise<void> =>
                                onClickOption(select, v)
                            }
                            options={Object.keys(select.options).map((k) => ({
                                label: k,
                                value: select.options[k],
                            }))}
                            placement="right"
                            searchable={false}
                            value={select.value}
                        >
                            <span className="align-items-center d-flex gap-2">
                                <span>{select.title}</span>
                                <Icon
                                    fixedWidth
                                    name={
                                        loading
                                            ? 'circle-o-notch'
                                            : 'angle-down'
                                    }
                                    spin={loading}
                                />
                            </span>
                        </Select>
                    ),
                )
            )}
        </>
    );
}
