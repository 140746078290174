import { ICurriculumItem } from 'holberton-school-intranet-api';
import * as React from 'react';
import { Dispatch, ReactElement, ReactNode, useState } from 'react';

import { patch } from '../../api/utils';
import ErrorAlert from '../common/ErrorAlert';
import Panel from '../common/Panel';
import DNDTable from '../common/dnd/table/DNDTable';
import { OnItemDropped } from '../common/dnd/table/DNDTableRow';

import BuilderContentRow from './BuilderContentRow';
import { IAction, IState, UpdateCurriculumItem } from './reducer';

interface IProps {
    dispatch: Dispatch<IAction>;
    state: IState;
}

export default function BuilderContentTable({
    dispatch,
    state,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLocked, setIsLocked] = useState<boolean>(false);

    const onItemDropped: OnItemDropped<ICurriculumItem> = async (
        item: ICurriculumItem,
        index: number,
    ): Promise<void> => {
        setError(null);
        setIsLoading(true);

        try {
            const position = index + 1;
            await patch(item.uri, state.csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                curriculum_item: { position: position },
                /* eslint-enable @typescript-eslint/camelcase */
            });
            dispatch(new UpdateCurriculumItem({ ...item, position: position }));
        } catch (err) {
            setError(err.message);
            throw err;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Panel
            icon={isLoading ? 'spinner' : 'list'}
            iconSpin={isLoading}
            style={{ overflow: 'visible' }}
            title="Content"
        >
            {error && (
                <div className="p-3">
                    <ErrorAlert error={error} noMargin />
                </div>
            )}

            <DNDTable
                className="table"
                draggable={
                    state.canEdit && !state.isLocked && !isLoading && !isLocked
                }
                droppable={
                    state.canEdit && !state.isLocked && !isLoading && !isLocked
                }
                itemClassName={isLoading ? ' disabled' : ''}
                keyExtractor={(item: ICurriculumItem): string =>
                    `${item.item.type}_${item.id}`
                }
                list={state.items}
                onItemDropped={onItemDropped}
                renderItem={(item: ICurriculumItem): ReactNode => (
                    <BuilderContentRow
                        canEdit={state.canEdit && !isLoading}
                        dispatch={dispatch}
                        item={item}
                        key={`${item.item.type}_${item.id}`}
                        onLock={setIsLocked}
                        state={state}
                    />
                )}
            />
        </Panel>
    );
}
