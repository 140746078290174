import * as React from 'react';
import { ReactElement, useEffect, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const Highcharts = window.Highcharts;

interface IChartSeriesData {
    key: string;
    y: number;
}
type ChartSeriesData = IChartSeriesData | number;

export interface IChartOptions {
    chart?: {
        height?: number | string;
        type: string;
    };
    credits?: {
        enabled: boolean;
    };
    legend?: {
        enabled: boolean;
    };
    plotOptions?: {
        series: {
            cursor: string;
            point: {
                events: {
                    click: () => void;
                };
            };
        };
    };
    series: {
        data: ChartSeriesData[];
        name?: string;
    }[];
    title: {
        text: string | null;
    };
    tooltip?: {
        formatter: () => string;
    };
    xAxis?: {
        categories: string[];
        labels: {
            formatter: () => string;
        };
    };
    yAxis?: {
        allowDecimals: boolean;
        tickPositioner?: () => number[];
        title: {
            text: string;
        };
    };
}

interface IProps {
    allowChartUpdate?: boolean;
    immutable?: boolean;
    onCreated?: () => void;
    options: IChartOptions;
    type?: string;
    updateArgs?: boolean[];
}

export default function Chart({
    allowChartUpdate = true,
    immutable = false,
    onCreated,
    options,
    type,
    updateArgs = [true, true],
}: IProps): ReactElement {
    const containerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef(null);

    useEffect(() => {
        const createChart = (): void => {
            const constructorType = type || 'chart';

            // Create a chart
            chartRef.current = Highcharts[constructorType](
                containerRef.current,
                options,
                onCreated,
            );
        };

        if (!chartRef.current) {
            createChart();
        } else {
            if (allowChartUpdate !== false) {
                if (!immutable && chartRef.current) {
                    chartRef.current.update(options, ...updateArgs);
                } else {
                    createChart();
                }
            }
        }
    }, [options]);

    useEffect(() => {
        return (): void => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, []);

    return <div ref={containerRef} />;
}
