import { IProject } from 'holberton-school-intranet-api';

export function isRowFromLeftToRight(
    rows: IProject[][],
    rowIndex: number,
): boolean {
    if (rowIndex === rows.length - 1) {
        // The last row is always from left to right
        return true;
    }

    if ((rows.length - rowIndex) % 2 === 1) {
        // For the others, it's one out of two
        return true;
    }

    return false;
}

export function isPathColored(
    project: IProject,
    currentProject?: IProject,
): boolean {
    return (
        currentProject &&
        (project.completion > 0 || project.index <= currentProject.index)
    );
}
