import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../Icon';

interface IProps {
    highlightTimeOffs: boolean;
    onToggleHighlightTimeOffs: () => void;
    onToggleShowColumns: () => void;
    showColumns: boolean;
}

export default function Toolbar({
    highlightTimeOffs,
    onToggleHighlightTimeOffs,
    onToggleShowColumns,
    showColumns,
}: IProps): ReactElement {
    return (
        <div className="align-items-center d-flex justify-content-between py-3">
            <div>
                <a
                    className="btn btn-sm btn-default"
                    onClick={onToggleShowColumns}
                    role="button"
                >
                    <Icon
                        name={`eye${showColumns ? '-slash' : ''}`}
                        text={`${showColumns ? 'Hide' : 'Show'} columns`}
                    />
                </a>
            </div>
            <div>
                <div className="btn-group" role="group">
                    <button
                        aria-expanded="false"
                        aria-haspopup="true"
                        className="btn btn-sm btn-default dropdown-toggle"
                        data-toggle="dropdown"
                        type="button"
                    >
                        Legend <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">
                        {[
                            {
                                onClick: onToggleHighlightTimeOffs,
                                state: highlightTimeOffs,
                                title: 'time off',
                            },
                        ].map(({ onClick, state, title }) => (
                            <li key={title}>
                                <a
                                    className={`align-items-center d-flex gap-3 justify-content-${
                                        state ? 'between' : 'end'
                                    }`}
                                    onClick={onClick}
                                    role="button"
                                >
                                    {state && <Icon name="check" />}
                                    Highlight {title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
