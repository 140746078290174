import { IPlanning } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { pluralize } from '../../../utils';

interface IProps {
    planning: IPlanning;
}

export default function Result({ planning }: IProps): ReactElement {
    return (
        <ul className="list-group">
            <li className="list-group-item">
                <strong className="fs-3">
                    {pluralize(planning.working_days.length, 'working day')}
                </strong>
            </li>
        </ul>
    );
}
