import {
    IBatchPlanningItem,
    MilestoneType,
    ReleaseScope,
    SessionChangeDirection,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import { pluralize } from '../../../../utils';
import ErrorInline from '../../../common/ErrorInline';
import { ISession } from '../Projects';

import SessionHeader from './SessionHeader';
import SessionItem from './SessionItem';

interface IProps {
    dailyWorkHours: number;
    error?: string | null;
    onChangeSession?: (
        bpi: IBatchPlanningItem,
        direction: SessionChangeDirection,
    ) => Promise<void>;
    onSubmitMilestone?: (
        bpi: IBatchPlanningItem,
        type: MilestoneType,
        date: string,
        scope?: ReleaseScope,
    ) => Promise<void>;
    session: ISession;
}

export default function Session({
    dailyWorkHours,
    error,
    onChangeSession,
    onSubmitMilestone,
    session,
}: IProps): ReactElement {
    const workHours = session.items.reduce(
        (acc, i) => acc + i.bpi.project.work_hours,
        0,
    );
    const duration = moment(session.endDate).diff(session.startDate, 'day') + 1;

    return (
        <>
            <SessionHeader
                dailyWorkHours={dailyWorkHours}
                onSubmitMilestone={onSubmitMilestone}
                session={session}
            />

            <hr />

            {error && (
                <>
                    <ErrorInline error={error} />
                    <hr />
                </>
            )}

            <span className="text-muted">
                {pluralize(session.items.length, 'project')} -{' '}
                <span
                    className={`text-${
                        workHours <= duration * dailyWorkHours
                            ? 'success'
                            : 'danger'
                    }`}
                >
                    {pluralize(workHours, 'hour')}
                </span>
            </span>
            <div className="list-group mt-2">
                {session.items.map((item) => (
                    <SessionItem
                        item={item}
                        key={item.bpi.id}
                        onChangeSession={onChangeSession}
                        onSubmitMilestone={onSubmitMilestone}
                    />
                ))}
            </div>
        </>
    );
}
