import { IEvaluationQuizSubmitResponse } from 'holberton-school-intranet-api';
import * as React from 'react';
import {
    ReactElement,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react';

export interface IContainerProps {
    csrfToken: string;
    evaluationQuiz: {
        allowExternalAccess: boolean;
        id: number;
        name: string;
        timeAllowed: number; // In minutes : 0 = no limit
    };
    evaluationQuizCorrection: {
        id: number;
    };
    submitURI: string;
}

export interface IContainerContext extends IContainerProps {
    error: string | null;
    notFocusDuration: number; // seconds
    progression: IEvaluationQuizSubmitResponse | null;
    setError: (error: string | null) => void;
    setNotFocusDuration: (action: SetStateAction<number>) => void;
    setProgression: (progression: IEvaluationQuizSubmitResponse) => void;
    setSubmitting: (submitting: boolean) => void;
    submitting: boolean;
}

export type IContainerContextState = Pick<
    IContainerContext,
    'error' | 'notFocusDuration' | 'progression' | 'submitting'
>;

interface IProps {
    children: ReactNode;
    initial: IContainerProps;
    initialState?: IContainerContextState;
}

const ContainerContext = createContext<IContainerContext>(undefined);

export function useContainerContext(): IContainerContext {
    return useContext(ContainerContext);
}

export default function ContainerContextProvider({
    children,
    initial,
    // true because we always submit when mounting to fetch the progression
    initialState = {
        error: null,
        notFocusDuration: 0,
        progression: null,
        submitting: true,
    },
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(initialState.error);
    const [
        progression,
        setProgression,
    ] = useState<IEvaluationQuizSubmitResponse | null>(
        initialState.progression,
    );
    const [submitting, setSubmitting] = useState<boolean>(
        initialState.submitting,
    );
    const [notFocusDuration, setNotFocusDuration] = useState<number | null>(
        initialState.notFocusDuration,
    );

    return (
        <ContainerContext.Provider
            value={{
                ...initial,
                error,
                notFocusDuration,
                progression,
                setError,
                setNotFocusDuration,
                setProgression,
                setSubmitting,
                submitting,
            }}
        >
            {children}
        </ContainerContext.Provider>
    );
}
