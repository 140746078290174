import * as React from 'react';
import { ReactElement, ReactNode, useEffect, useRef } from 'react';

interface IProps {
    children: ReactNode;
    html?: boolean;
    placement?: string;
    title: string;
}

function jQueryTooltipCommand(
    element: HTMLDivElement | null,
    command?: string,
): void {
    if (element) {
        // TypeScript does not know about jQuery
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        window.$(element).tooltip(command);
    }
}

export default function Tooltip({
    children,
    html = false,
    placement = 'auto top',
    title,
}: IProps): ReactElement {
    const selfRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        jQueryTooltipCommand(selfRef.current);

        // Cleanup when component is unmounted
        return (): void => jQueryTooltipCommand(selfRef.current, 'destroy');
    });

    return (
        <span
            data-container="body"
            data-html={html}
            data-placement={placement}
            data-toggle="tooltip"
            ref={selfRef}
            title={title}
        >
            {children}
        </span>
    );
}
