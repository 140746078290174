import {
    IGroupActivity,
    IGroupActivityJoinGroupRequest,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { put } from '../../api/utils';
import Icon from '../common/Icon';

export type OnJoinCompletedHandler = (response: IGroupActivity) => void;

interface IProps {
    csrfToken: string;
    leaveGroupURI: string;
    onCompleted: OnJoinCompletedHandler;
}

export default function LeaveButton({
    csrfToken,
    leaveGroupURI,
    onCompleted,
}: IProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);

    const onClick = async (): Promise<void> => {
        try {
            setLoading(true);
            const response = await put<
                IGroupActivity,
                IGroupActivityJoinGroupRequest
            >(leaveGroupURI, csrfToken, {});
            onCompleted(response);
        } catch (err) {
            alert(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <button
            className="btn btn-danger btn-sm"
            disabled={loading}
            onClick={onClick}
        >
            <Icon name="sign-out" text="Leave" />
        </button>
    );
}
