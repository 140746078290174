import { IGroupActivityGroup } from 'holberton-school-intranet-api';

export function isGroupFull(
    group: IGroupActivityGroup,
    groupMaxSize: number | null,
): boolean {
    if (groupMaxSize === null) {
        return false;
    }
    return group.users.length >= groupMaxSize;
}
