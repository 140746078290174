import * as React from 'react';
import { Key, ReactElement, ReactNode } from 'react';

interface IProps<T> {
    children: ReactNode;
    items: T[];
    keyExtractor: (item: T) => Key;
    onItemClick: (item: T) => void;
    placeholder?: string;
    renderItem: (item: T) => ReactElement;
}

export default function Dropdown<T>({
    children,
    items,
    keyExtractor,
    onItemClick,
    placeholder,
    renderItem,
}: IProps<T>): ReactElement {
    return (
        <div className="input-group">
            {placeholder && (
                <span className="input-group-addon" id="basic-addon1">
                    {placeholder}
                </span>
            )}
            <div className="input-group-btn">
                <button
                    aria-expanded="true"
                    aria-haspopup="true"
                    className="btn btn-default dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                    {children} <span className="caret" />
                </button>
                <ul
                    className="dropdown-menu"
                    style={{ maxHeight: 300, overflowY: 'auto' }}
                >
                    {items.map((item) => (
                        <li key={keyExtractor(item)}>
                            <a
                                onClick={(): void => onItemClick(item)}
                                role="button"
                            >
                                {renderItem(item)}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
