import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    count: number;
    height: number;
    width: number;
}

export default function CircularProgressBarRadialSeparators({
    count,
    height,
    width,
}: IProps): ReactElement {
    const turns = 1 / count;
    return (
        <>
            {[...Array(count)].map((_, i) => (
                <div
                    key={`radial-${i}`}
                    style={{
                        height: '100%',
                        position: 'absolute',
                        transform: `rotate(${i * turns}turn)`,
                    }}
                >
                    <div
                        className="circular-progress-bar-radial-separator"
                        style={{
                            height: `${height}%`,
                            width,
                        }}
                    />
                </div>
            ))}
        </>
    );
}
