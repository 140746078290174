import * as React from 'react';
import { ReactElement, ReactNode, useState } from 'react';

import Tooltip from './Tooltip';

interface IProps {
    children: ReactNode;
    copyText?: string;
    title?: string;
    tooltipPlacement?: string;
}

function copyToClipboard(textToCopy: string): void {
    if (!navigator.clipboard) {
        // Old web browsers
        const textField = document.createElement('textarea');
        textField.innerText = textToCopy;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    } else {
        navigator.clipboard.writeText(textToCopy);
    }
}

export default function CopyOnClick({
    children,
    copyText,
    title = 'Click to copy',
    tooltipPlacement,
}: IProps): ReactElement {
    const [copied, setCopied] = useState<boolean>(false);

    const onClick = (): void => {
        if (copied) {
            return;
        }

        copyToClipboard(copyText ? copyText : children.toString());

        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };

    return (
        <Tooltip placement={tooltipPlacement} title={title}>
            <span onClick={onClick} role={copied ? undefined : 'button'}>
                {copied ? 'Copied !' : children}
            </span>
        </Tooltip>
    );
}
