import * as React from 'react';
import { ReactElement } from 'react';

import ContainerContextProvider, { IContainerProps } from './ContainerContext';
import End from './End';
import Header from './Header';
import Question from './Question';
import Start from './Start';

export default function Container(props: IContainerProps): ReactElement {
    return (
        <ContainerContextProvider initial={props}>
            <div className="evaluation-quiz">
                <Header />
                <Start />
                <End />
                <Question />
            </div>
        </ContainerContextProvider>
    );
}
