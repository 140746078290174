import { ITag } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Tag from './Tag';

interface IProps {
    tags: ITag[];
}

export default function Tags({ tags }: IProps): ReactElement | null {
    if (tags.length === 0) {
        return null;
    }

    return (
        <div className="align-items-center d-flex flex-wrap gap-3 my-2">
            {tags.map((tag) => (
                <Tag key={tag.id} label={tag.value} />
            ))}
        </div>
    );
}
