import {
    IGroupActivity,
    IGroupActivityGroup,
    IGroupActivityJoinGroupRequest,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { post } from '../../api/utils';
import Icon from '../common/Icon';

export type OnJoinCompletedHandler = (response: IGroupActivity) => void;

interface IProps {
    csrfToken: string;
    group: IGroupActivityGroup;
    joinGroupURI: string;
    onCompleted: OnJoinCompletedHandler;
}

export default function JoinButton({
    csrfToken,
    group,
    joinGroupURI,
    onCompleted,
}: IProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);

    const onClick = async (peerId: number): Promise<void> => {
        try {
            setLoading(true);
            const response = await post<
                IGroupActivity,
                IGroupActivityJoinGroupRequest
            >(joinGroupURI, csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                peer_id: peerId,
                /* eslint-enable @typescript-eslint/camelcase */
            });
            onCompleted(response);
        } catch (err) {
            alert(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <button
            className="btn btn-default btn-sm"
            disabled={loading}
            onClick={(): Promise<void> => onClick(group.users[0].id)}
        >
            <Icon name="plus" text="Join" />
        </button>
    );
}
