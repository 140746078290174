import * as React from 'react';
import { FormEvent, ReactElement, useRef, useState } from 'react';

import ErrorInline from '../ErrorInline';
import Icon from '../Icon';

import { IOption } from './Select';

interface IProps<T> {
    onClickOption: (option: IOption<T>) => void;
    onDestroyOption?: (option: T) => Promise<void>;
    onEditOption?: (option: T, name: string) => Promise<void>;
    option: IOption<T>;
    selected: boolean;
}

export default function SelectOption<T>({
    onClickOption,
    onDestroyOption,
    onEditOption,
    option,
    selected,
}: IProps<T>): ReactElement {
    const [editing, setEditing] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [inMenu, setInMenu] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const alterable = (onDestroyOption && option.destroyable) || onEditOption;

    const onClickDestroy = async (): Promise<void> => {
        setInMenu(false);
        if (!onDestroyOption) {
            return;
        }

        setLoading(true);
        setError(null);
        try {
            await onDestroyOption(option.value);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    const onSubmitForm = async (): Promise<void> => {
        setInMenu(false);
        setEditing(false);
        if (!onEditOption) {
            return;
        }

        const name = inputRef.current?.value;
        if (name && name !== option.label) {
            setLoading(true);
            setError(null);
            try {
                await onEditOption(option.value, name);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        }
    };

    return (
        <a className="list-group-item p-0">
            <span
                className={`align-items-center d-flex gap-2 justify-content-${
                    inMenu ? 'evenly' : 'between'
                }`}
            >
                {editing ? (
                    <form
                        onSubmit={(e: FormEvent<HTMLFormElement>): void => {
                            e.preventDefault();
                            onSubmitForm();
                        }}
                    >
                        <input
                            className="form-control"
                            defaultValue={option.label}
                            ref={inputRef}
                            type="text"
                        />
                    </form>
                ) : inMenu ? (
                    <span
                        className="d-flex justify-content-around"
                        style={{ flex: 1, padding: '10px 15px' }}
                    >
                        {onEditOption && (
                            <span
                                onClick={(): void => setEditing(true)}
                                role="button"
                            >
                                <Icon fixedWidth name="pencil" />
                            </span>
                        )}
                        {onDestroyOption && option.destroyable && (
                            <span
                                className="text-danger"
                                onClick={onClickDestroy}
                                role="button"
                            >
                                <Icon fixedWidth name="trash" />
                            </span>
                        )}
                    </span>
                ) : (
                    <span
                        className="align-items-center d-flex gap-2"
                        onClick={(): void => onClickOption(option)}
                        role={loading ? undefined : 'button'}
                        style={{ flex: 1, padding: '10px 15px' }}
                    >
                        <span style={{ width: '14px' }}>
                            {selected && <Icon name="check" />}
                        </span>
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {option.label}
                        </span>
                    </span>
                )}

                {alterable && (
                    <span
                        onClick={(): Promise<void> | void =>
                            editing
                                ? onSubmitForm()
                                : !loading && setInMenu((v) => !v)
                        }
                        role={loading ? undefined : 'button'}
                        style={{ padding: '10px 5px' }}
                    >
                        <Icon
                            fixedWidth
                            name={
                                editing
                                    ? 'check'
                                    : loading
                                    ? 'spinner'
                                    : 'ellipsis-v'
                            }
                            spin={loading}
                        />
                    </span>
                )}
            </span>

            {error && <ErrorInline error={error} />}
        </a>
    );
}
