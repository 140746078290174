import { SearchModel } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { BuilderSearchItemRenderer } from '../curriculums/search/BuilderSearch';

import SearchBar, { ISearchResult, resultLength } from './SearchBar';
import SearchNoResult from './SearchNoResult';
import SearchResultList from './SearchResultList';
import { ISearchResponse, SearchResponseNormalized } from './api-rest-adapter';

export interface IProps {
    csrfToken: string;
    model: SearchModel;
    placeholder?: string;
    renderItem?: BuilderSearchItemRenderer;
    searchURI: string;
}

export default function SearchBarWithResults({
    csrfToken,
    model,
    placeholder,
    renderItem,
    searchURI,
}: IProps): ReactElement {
    const [result, setResult] = useState<ISearchResponse>({
        query: '',
        results: {} as SearchResponseNormalized,
    });

    const shouldRenderResults = result.query.length > 0;

    const onLoadedMoreItems = (newItems: ISearchResult[]): void => {
        setResult((oldResults) => {
            const newResults = { ...oldResults };
            newResults.results[model].items = oldResults.results[
                model
            ].items.concat(newItems);
            return newResults;
        });
    };

    const defaultRenderItem = (item: ISearchResult): ReactElement => {
        return (
            <a className="list-group-item" href={item.link} key={item.id}>
                <code>{item.id}</code>
                <span className="ml-2">{item.label}</span>
                {item.badge && <span className="badge">{item.badge}</span>}
            </a>
        );
    };

    return (
        <>
            <SearchBar
                csrfToken={csrfToken}
                models={[model]}
                onResult={setResult}
                placeholder={placeholder}
                searchURI={searchURI}
                squaredBottom={shouldRenderResults}
            />
            {shouldRenderResults && (
                <div
                    className="search-results"
                    style={{ borderBottom: '1px solid #ccc' }}
                >
                    {resultLength(result.results) > 0 ? (
                        <SearchResultList
                            csrfToken={csrfToken}
                            items={result.results[model]?.items || []}
                            loadMoreURI={result.results[model]?.loadMoreURI}
                            model={{
                                // This is a workaround because the component needs to pass the model to render the items
                                camel: model,
                                human: model,
                                name: model,
                            }}
                            onLoadedMoreItems={onLoadedMoreItems}
                            renderItem={renderItem || defaultRenderItem}
                            style={{ height: 'inherit', marginBottom: 0 }}
                        />
                    ) : (
                        <div
                            className="list-group"
                            style={{ height: 'inherit', marginBottom: 0 }}
                        >
                            <div className="list-group-item">
                                <SearchNoResult result={result} />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}
