import {
    IBatchTimeOff,
    IBatchValidationRules,
    IPlanningReport,
    Weekday,
} from 'holberton-school-intranet-api';
import produce from 'immer';
import moment from 'moment';

export interface IState {
    batchID: number;
    createBatchTimeOffURI: string;
    csrfToken: string;
    dailyWorkHours: number;
    endDate: string;
    loading: boolean;
    noProjectDays: Weekday[];
    report: IPlanningReport;
    startDate: string;
    timeOffs: IBatchTimeOff[];
    updateBatchCurriculumURI: string;
    updateBatchURI: string;
    validationRules: IBatchValidationRules;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAction {}

class TimeOffAction implements IAction {
    constructor(public timeOff: IBatchTimeOff) {}
}
export class AddTimeOff extends TimeOffAction {}
export class RemoveTimeOff extends TimeOffAction {}

export class UpdateDailyWorkHours implements IAction {
    constructor(public dailyWorkHours: number) {}
}

export class UpdateStartEnd implements IAction {
    constructor(public startDate: string, public endDate: string) {}
}

export class UpdateNoProjectDays implements IAction {
    constructor(public noProjectDays: Weekday[]) {}
}

export class UpdateReport implements IAction {
    constructor(public report: IPlanningReport) {}
}

export class SetLoading implements IAction {
    constructor(public loading: boolean) {}
}

export default function reducer(state: IState, action: IAction): IState {
    if (action instanceof TimeOffAction) {
        const { timeOff } = action;

        if (action instanceof AddTimeOff) {
            return produce(state, (draft) => {
                draft.timeOffs.push({ ...timeOff });
                draft.timeOffs.sort((a, b) => {
                    const startDiff = moment(a.start_date).diff(
                        b.start_date,
                        'day',
                    );
                    return startDiff === 0
                        ? moment(a.end_date).diff(b.end_date, 'day')
                        : startDiff;
                });
            });
        }
        if (action instanceof RemoveTimeOff) {
            return produce(state, (draft) => {
                draft.timeOffs = draft.timeOffs.filter(
                    (t) => t.id !== timeOff.id,
                );
            });
        }
    }

    if (action instanceof UpdateDailyWorkHours) {
        return produce(state, (draft) => {
            draft.dailyWorkHours = action.dailyWorkHours;
        });
    }

    if (action instanceof UpdateStartEnd) {
        return produce(state, (draft) => {
            draft.endDate = action.endDate;
            draft.startDate = action.startDate;
        });
    }

    if (action instanceof UpdateNoProjectDays) {
        return produce(state, (draft) => {
            draft.noProjectDays = action.noProjectDays;
        });
    }

    if (action instanceof UpdateReport) {
        return produce(state, (draft) => {
            draft.report = action.report;
        });
    }

    if (action instanceof SetLoading) {
        return produce(state, (draft) => {
            draft.loading = action.loading;
        });
    }

    return state;
}
