import * as React from 'react';
import { ChangeEvent, ReactElement, useEffect, useRef } from 'react';

import { ISelect } from './DataTable';
import DataTableSelectsList from './DataTableSelectsList';
import {
    IAction,
    IState,
    SelectAll,
    SetFilterBy,
    SetGroupBy,
    SetSortBy,
} from './reducer';

interface IProps<T> {
    bulks: ISelect[];
    dispatch: (action: IAction) => void;
    grouping?: Record<string, string>;
    sorting?: Record<string, string>;
    state: IState<T>;
}

export default function DataTableHeading<T>({
    bulks,
    dispatch,
    grouping,
    sorting,
    state,
}: IProps<T>): ReactElement {
    const selectAllCheckboxRef = useRef<HTMLInputElement>(null);

    const actions = [];
    if (grouping) {
        actions.push({
            onSubmit: (v: string): void => dispatch(new SetGroupBy(v)),
            options: grouping,
            title: 'Group by',
            value: state.groupBy,
        });
    }
    if (sorting) {
        actions.push({
            onSubmit: (v: string): void => dispatch(new SetSortBy(v)),
            options: sorting,
            title: 'Sort by',
            value: state.sortBy,
        });
    }

    useEffect(() => {
        if (!selectAllCheckboxRef.current) {
            return;
        }
        const selected = state.selected.length;

        selectAllCheckboxRef.current.checked =
            selected > 0 && selected === state.items.length;
        selectAllCheckboxRef.current.indeterminate =
            selected > 0 && selected < state.items.length;
    }, [state.selected]);

    const onChangeSelectAll = (e: ChangeEvent<HTMLInputElement>): void => {
        dispatch(new SelectAll(e.target.checked));
    };

    return (
        <div
            className="align-items-center d-flex justify-content-between panel-heading"
            style={{ padding: '10px 8px' }}
        >
            {/* Filters */}
            <div className="align-items-center d-flex gap-3">
                <label className="d-block m-0 px-2">
                    <input
                        onChange={onChangeSelectAll}
                        ref={selectAllCheckboxRef}
                        type="checkbox"
                    />
                </label>

                {state.selected.length > 0 ? (
                    <span className="text-muted">
                        {state.selected.length} selected
                    </span>
                ) : (
                    state.filterByValues.map((s) => (
                        <span
                            className={
                                state.filterBy.includes(s)
                                    ? 'fw-bold'
                                    : 'text-muted'
                            }
                            key={s}
                            onClick={(): void => dispatch(new SetFilterBy(s))}
                            role="button"
                        >
                            {
                                state.items.filter(
                                    (item) =>
                                        item[state.filterByAttribute] === s,
                                ).length
                            }{' '}
                            {s}
                        </span>
                    ))
                )}
            </div>

            {/* Actions */}
            <div className="d-flex gap-4">
                <DataTableSelectsList
                    selects={state.selected.length > 0 ? bulks : actions}
                    state={state}
                />
            </div>
        </div>
    );
}
