// Exception because the API in ruby returns snake case fields
/* eslint @typescript-eslint/camelcase: "off" */

import { SearchModel, SearchResponse } from 'holberton-school-intranet-api';

import { get } from '../../api/utils';

import { ISearchResult } from './SearchBar';

export type SearchResponseNormalized = {
    [key in SearchModel]: {
        items: ISearchResult[];
        loadMoreURI?: string;
    };
};

export interface ISearchResponse {
    error?: string;
    query: string;
    results: SearchResponseNormalized;
}

export async function searchModels(
    uri: string,
    csrfToken: string,
    models: SearchModel[],
): Promise<SearchResponseNormalized> {
    const response = await get<SearchResponse>(uri, csrfToken);

    const res: SearchResponseNormalized = {} as SearchResponseNormalized;
    models.forEach((model) => {
        res[model] = {
            items: response[model]?.items.map(({ batch, id, link, name }) => {
                return model === 'users'
                    ? {
                          badge: batch,
                          id,
                          label: name,
                          link,
                      }
                    : {
                          id,
                          label: name,
                          link,
                      };
            }),
            loadMoreURI: response[model]?.next,
        };
    });
    return res;
}
