import * as React from 'react';
import { ReactElement, useState } from 'react';

import Icon from '../common/Icon';
import Switch from '../common/Switch';

import ColoredIcon from './ColoredIcon';

interface IProps {
    id: string;
    url: string;
}

export default function CreateVideoRoom({ id, url }: IProps): ReactElement {
    const [color, setColor] = useState<string | undefined>(undefined);
    const params = [
        { id: 'coffee-room', label: 'Coffee room' },
        { id: 'display-creator-name', label: 'Display creator name' },
        { id: 'temporary-room', label: 'Temporary room' },
        { id: 'private-room', label: 'Private room' },
        { id: 'tag-school-locations', label: 'Tag school locations' },
        { id: 'tag-cohort', label: 'Tag Cohort' },
        { id: 'subjects', label: 'Subjects' },
    ];

    return (
        <div className="modal fade" id={id} role="dialog">
            <div
                className="modal-dialog"
                role="document"
                style={{ top: '40px' }}
            >
                <form action={url} method="post">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div>
                                <h2 className="modal-title">New video room</h2>
                                <div
                                    style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                    }}
                                >
                                    <label
                                        htmlFor="create-video-room-active"
                                        style={{
                                            fontWeight: 500,
                                            marginBottom: 0,
                                            marginRight: '12px',
                                        }}
                                    >
                                        Active room
                                    </label>
                                    <Switch
                                        checked
                                        id="create-video-room-active"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="modal-body">
                            <div
                                style={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '0 15px',
                                }}
                            >
                                <div>
                                    <label>Name</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <label>Value</label>
                                    <ColorPicker
                                        color={color}
                                        onColor={setColor}
                                    />
                                    <ColoredIcon color={color} size={80} />
                                </div>
                                {params.map(({ id, label }) => (
                                    <div key={id}>
                                        <label
                                            htmlFor={`create-video-room-${id}`}
                                        >
                                            {label}
                                        </label>
                                        <Switch
                                            id={`create-video-room-${id}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="btn btn-md btn-default"
                                data-dismiss="modal"
                                type="button"
                            >
                                Cancel
                            </button>
                            <button className="btn btn-md btn-primary">
                                Create video room
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

interface IColorPickerProps {
    color?: string;
    onColor?: (color: string) => void;
}

function ColorPicker({ color, onColor }: IColorPickerProps): ReactElement {
    const [hover, setHover] = useState<string>(null);
    const colors = [
        '#B80000',
        '#DB3E00',
        '#FCCB00',
        '#008B02',
        '#006B76',
        '#1273DE',
        '#004DCF',
        '#5300EB',
        '#EB9694',
        '#FAD0C3',
        '#FEF3BD',
        '#C1E1C5',
        '#BEDADC',
        '#C4DEF6',
        '#BED3F3',
        '#D4C4FB',
    ];

    return (
        <div style={{ alignItems: 'center', display: 'flex' }}>
            <div
                onMouseLeave={(): void => setHover(null)}
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(8, 25px)',
                    gridTemplateRows: 'repeat(2, 25px)',
                }}
            >
                {colors.map((c) => (
                    <div
                        key={c}
                        onClick={(): void => onColor && onColor(c)}
                        onMouseEnter={(): void => setHover(c)}
                        style={{
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            padding:
                                c === color ? '3px' : c === hover ? '1px' : 0,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: c,
                                height: '100%',
                                width: '100%',
                            }}
                        />
                    </div>
                ))}
            </div>
            <div
                style={{
                    cursor: 'pointer',
                    marginLeft: '8px',
                    visibility: color ? 'visible' : 'hidden',
                }}
            >
                <Icon
                    color="#9EB2B9"
                    fixedWidth
                    name="times-circle"
                    onClick={(): void => onColor(undefined)}
                />
            </div>
        </div>
    );
}
