import * as React from 'react';
import { CSSProperties, MouseEvent, ReactElement } from 'react';

import Tooltip from './Tooltip';

/**
 * This component follows the Font Awesome documentation:
 * https://fontawesome.com/v4.7.0/examples
 *
 * @fixedWidth Give Icon a fixed width. Great to use when different icon widths
 *             throw off alignment.
 * @size       Scale the Icon. If your icons are getting chopped off on top and
 *             bottom, make sure you have sufficient line-height.
 * @spin       Get the Icon to rotate.
 * @text       A text following the icon
 */

interface IProps {
    backgroundColor?: string;
    className?: string;
    color?: string;
    fixedWidth?: boolean;
    fontWeight?: string;
    name: string;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    size?: 'lg' | '2x' | '3x' | '4x' | '5x';
    spin?: boolean;
    text?: string;
    title?: string;
}

export default function Icon({
    backgroundColor,
    className,
    color,
    fixedWidth = false,
    fontWeight,
    name,
    onClick,
    size,
    spin = false,
    text,
    title,
}: IProps): ReactElement {
    const customStyle = {
        backgroundColor: backgroundColor,
        color: color,
        fontWeight: fontWeight,
    } as CSSProperties;
    const classNames = ['fa', `fa-${name}`];

    if (size) {
        classNames.push(`fa-${size}`);
    }
    if (fixedWidth) {
        classNames.push('fa-fw');
    }
    if (spin) {
        classNames.push('fa-spin');
    }
    if (className) {
        classNames.push(className);
    }

    const renderIconAndText = (): ReactElement => (
        <>
            <i
                aria-hidden="true"
                className={classNames.join(' ')}
                onClick={onClick}
                role={onClick ? 'button' : undefined}
                style={customStyle}
            />
            {text && <span className="ml-2">{text}</span>}
        </>
    );

    if (title) {
        return <Tooltip title={title}>{renderIconAndText()}</Tooltip>;
    }

    return renderIconAndText();
}
