import * as React from 'react';
import { ReactElement } from 'react';
import { SearchBoxProvided } from 'react-instantsearch-core';

import Icon from '../../../common/Icon';

export default function SearchBox({
    currentRefinement,
    refine,
}: SearchBoxProvided): ReactElement {
    return (
        <div className="input-group">
            <input
                className="form-control"
                onChange={(e): void => refine(e.currentTarget.value)}
                placeholder="Search for projects..."
                type="search"
                value={currentRefinement}
            />
            <span className="input-group-btn">
                <a
                    aria-controls="advanced-filters"
                    aria-expanded="false"
                    className="btn btn-default"
                    data-target="#advanced-filters"
                    data-toggle="collapse"
                    role="button"
                >
                    <Icon fixedWidth name="chevron-right" text="Filters" />
                </a>
            </span>
        </div>
    );
}
