import hljs from 'highlight.js/lib/core';
import cpp from 'highlight.js/lib/languages/cpp';
import { Html } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect } from 'react';

import 'highlight.js/styles/github.css';

interface IProps {
    content: Html;
}

export default function Code({ content }: IProps): ReactElement {
    useEffect(() => {
        hljs.configure({});
        // We import only the languages we use to avoid importing all of them and making the final bundle too big
        hljs.registerLanguage('cpp', cpp);
    }, []);

    useEffect(() => {
        document.querySelectorAll('pre code').forEach((block: HTMLElement) => {
            hljs.highlightBlock(block);
        });
    }, [content]);

    return <div dangerouslySetInnerHTML={{ __html: content }} />;
}
