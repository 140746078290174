import * as React from 'react';
import { ReactElement, useState } from 'react';

import Icon from './Icon';
import Select from './select/Select';

interface IItem {
    label: string;
    value: string;
}

interface IProps {
    allowBlank: boolean;
    className: string;
    id: string;
    items: IItem[];
    name: string;
    placeholder: string;
}

export default function SearchInSelect({
    allowBlank,
    className,
    id,
    items,
    name,
    placeholder,
}: IProps): ReactElement {
    const [selected, setSelected] = useState<IItem | null>(
        allowBlank || items?.length === 0 ? null : items[0],
    );

    return (
        <span className="align-items-center d-flex">
            <span style={{ flex: 1 }}>
                <Select
                    onSubmit={setSelected}
                    options={items.map((i) => ({ label: i.label, value: i }))}
                >
                    <div
                        className={`${className} align-items-center d-flex gap-3 justify-content-between`}
                        role="button"
                    >
                        {selected ? (
                            <span>{selected.label}</span>
                        ) : (
                            <span className="text-muted">{placeholder}</span>
                        )}
                        <small>
                            <Icon name="angle-down" />
                        </small>
                    </div>
                </Select>
            </span>

            {allowBlank && selected && (
                <Icon
                    className="ml-2 text-muted"
                    fixedWidth
                    name="times-circle"
                    onClick={(): void => setSelected(null)}
                />
            )}

            <input id={id} name={name} type="hidden" value={selected?.value} />
        </span>
    );
}
