import { IContainerSpec } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, Reducer, useReducer } from 'react';

import { pluralize } from '../../utils';
import Icon from '../common/Icon';
import Search from '../common/Search';

import UserContainer from './UserContainer';
import reducer, {
    IAction,
    IState,
    IUserContainerState,
    SearchContainerSpecs,
    ToggleOnlyRunning,
} from './reducer';

export interface IProps {
    containerModelName: string;
    containerSpecs: IContainerSpec[];
    containersLimit: number;
    csrfToken: string;
    startStatusURI: string;
    startURI: string;
}

function containerSpecToState(
    containerSpec: IContainerSpec,
): IUserContainerState {
    return {
        containerSpec,
        error: null,
        info: null,
        loading: null,
    };
}

export default function ContainerSpecs({
    containerModelName,
    containerSpecs,
    containersLimit,
    csrfToken,
    startStatusURI,
    startURI,
}: IProps): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
        allSpecs: containerSpecs.map(containerSpecToState),
        onlyRunning: false,
        searchQuery: '',
        searchedSpecs: containerSpecs.map(containerSpecToState),
    });

    const onSearch = (searchQuery: string): void => {
        dispatch(new SearchContainerSpecs(searchQuery));
    };

    return (
        <div>
            <div className="d-flex gap-4 sandboxes-filters">
                <a
                    className={`btn btn-${
                        state.onlyRunning ? '' : 'outline-'
                    }primary`}
                    onClick={(): void => dispatch(new ToggleOnlyRunning())}
                >
                    <Icon name="filter" text="Running only" />
                </a>

                <Search
                    onSearch={onSearch}
                    placeholder="Search for an image ..."
                    search={state.searchQuery}
                />
            </div>

            <div className="mt-3">
                <h3>
                    {pluralize(state.searchedSpecs.length, 'image')}
                    <small className="ml-2">
                        (
                        {
                            state.allSpecs.filter(
                                (cs) => cs.containerSpec.container,
                            ).length
                        }
                        /{pluralize(containersLimit, containerModelName)}{' '}
                        spawned)
                    </small>
                </h3>
            </div>

            <div className="mt-3">
                {state.searchedSpecs.map((containerState) => (
                    <UserContainer
                        containerModelName={containerModelName}
                        csrfToken={csrfToken}
                        dispatch={dispatch}
                        key={containerState.containerSpec.id}
                        startStatusURI={startStatusURI}
                        startURI={startURI}
                        state={containerState}
                    />
                ))}
            </div>
        </div>
    );
}
