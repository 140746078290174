import * as React from 'react';
import { ChangeEvent, ReactElement } from 'react';

interface IProps {
    checked?: boolean;
    disabled?: boolean;
    id: string;
    onCheck?: (checked: boolean) => void;
}

export default function Switch({
    checked = false,
    disabled = false,
    id,
    onCheck,
}: IProps): ReactElement {
    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        onCheck && onCheck(event.target.checked);
    };

    return (
        <label
            htmlFor={id}
            style={{
                boxSizing: 'content-box',
                display: 'inline-block',
                marginBottom: 0,
                position: 'relative',
            }}
        >
            <input
                checked={checked}
                disabled={disabled}
                hidden
                id={id}
                onChange={onChange}
                type="checkbox"
            />
            <div
                className={checked ? 'bg-primary' : ''}
                style={{
                    WebkitTransition: 'background-color 0.25s ease',
                    backgroundColor: checked ? undefined : '#dedede',
                    borderRadius: '9999em',
                    content: '""',
                    cursor: 'pointer',
                    display: 'block',
                    height: '100%',
                    position: 'absolute',
                    transition: 'background-color 0.25s ease',
                    width: '100%',
                }}
            />
            <div
                style={{
                    clip: 'rect(0 0 0 0)',
                    color: 'transparent',
                    cursor: 'pointer',
                    display: 'block',
                    height: '24px',
                    marginBottom: '0px',
                    textIndent: '-150%',
                    userSelect: 'none',
                    width: '42px',
                }}
            />
            <div
                style={{
                    WebkitTransition: 'left 0.25s ease',
                    backgroundColor: '#fff',
                    borderRadius: '50%',
                    boxShadow: '0 0 2px rgb(0 0 0 / 45%)',
                    content: '""',
                    cursor: 'pointer',
                    display: 'block',
                    height: '18px',
                    left: checked ? '21px' : '3px',
                    position: 'absolute',
                    top: '3px',
                    transition: 'left 0.25s ease',
                    width: '18px',
                }}
            />
        </label>
    );
}
