import { IPresignedURLResponse } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useRef, useState } from 'react';

import { post } from '../../api/utils';

interface IProps {
    csrfToken: string;
    fileType: string;
    getPutUrl: string;
}

type Status =
    | 'Error'
    | 'GettingPresignedUrl'
    | 'Ready'
    | 'Uploaded'
    | 'Uploading';

export default function FileUploader({
    csrfToken,
    fileType,
    getPutUrl,
}: IProps): ReactElement {
    const [currentStatus, setCurrentStatus] = useState<Status>('Ready');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const [uploadProgress, setUploadProgress] = useState<number>(0);

    const xhrRef = useRef<XMLHttpRequest>(new XMLHttpRequest());

    useEffect(() => {
        xhrRef.current.onload = (): void => {
            if (xhrRef.current.status !== 200) {
                setErrorMessage(
                    `Unexpected status:${xhrRef.current.statusText} when uploading file.`,
                );
                setCurrentStatus('Error');
            }
        };

        xhrRef.current.onerror = (): void => {
            setErrorMessage("Can't upload file.");
            setCurrentStatus('Error');
        };

        xhrRef.current.upload.onprogress = (e: ProgressEvent): void => {
            setUploadProgress(Math.round((e.loaded / e.total) * 100));
        };

        xhrRef.current.onreadystatechange = (): void => {
            if (xhrRef.current.readyState === 4) {
                setCurrentStatus('Uploaded');
            }
        };
    }, []);

    const reset = (): void => {
        setUploadProgress(0);
        setCurrentStatus('Ready');
        setFileName('');
    };

    const onAbortClick = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        xhrRef.current.abort();
        reset();
    };

    const onFileChange = async (
        e: React.ChangeEvent<HTMLInputElement>,
    ): Promise<void> => {
        const file = e.target.files[0];
        setCurrentStatus('GettingPresignedUrl');
        setFileName(file.name);

        try {
            const response = await post<IPresignedURLResponse>(
                getPutUrl,
                csrfToken,
                {
                    filename: file.name,
                    filesize: file.size,
                    filetype: file.type,
                },
            );
            if (response.url) {
                setCurrentStatus('Uploading');
                xhrRef.current.open('PUT', response.url, true);
                xhrRef.current.send(file);
            }
        } catch (err) {
            setErrorMessage(err.message);
            setCurrentStatus('Error');
        }
    };

    const onRetryClick = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        reset();
    };

    return (
        <div className="fileUploader">
            {currentStatus === 'Ready' && (
                <div className="file">
                    <input
                        accept={fileType}
                        className="form-control"
                        onChange={onFileChange}
                        type="file"
                    />
                </div>
            )}

            {currentStatus === 'GettingPresignedUrl' && (
                <div className="uploading" style={{ opacity: 0.7 }}>
                    <i className="fa fa-upload"></i> Uploading {fileName} {}
                    <strong>in progress [{uploadProgress}%]</strong>.
                    <div className="progress">
                        <div
                            aria-valuemax={100}
                            aria-valuemin={0}
                            aria-valuenow={uploadProgress}
                            className="progress-bar progress-bar-animated"
                            role="progressbar"
                            style={{ width: '0%' }}
                        >
                            Preparing upload...
                        </div>
                    </div>
                </div>
            )}

            {currentStatus === 'Uploading' && (
                <div className="uploading">
                    <i className="fa fa-upload"></i> Uploading {fileName} {}
                    <strong>in progress [{uploadProgress}%]</strong>.
                    <a className="abort" href="#" onClick={onAbortClick}>
                        <i className="fa fa-times"></i> abort
                    </a>
                    <div className="progress">
                        <div
                            aria-valuemax={100}
                            aria-valuemin={0}
                            aria-valuenow={uploadProgress}
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: uploadProgress + '%' }}
                        >
                            {uploadProgress}%
                        </div>
                    </div>
                </div>
            )}

            {currentStatus === 'Error' && (
                <div className="error bg-warning text-dark card-body">
                    <i className="fa fa-exclamation-triangle"></i> Error:{' '}
                    {errorMessage} {}
                    <a className="retry" href="#" onClick={onRetryClick}>
                        <i className="fa fa-undo"></i> retry
                    </a>
                </div>
            )}

            {currentStatus === 'Uploaded' && (
                <div className="success bg-success text-white">
                    <i className="fa fa-check-circle"></i> {fileName} has been
                    uploaded.
                </div>
            )}
        </div>
    );
}
