import * as React from 'react';
import { ReactElement, ReactNode } from 'react';

import Icon from '../Icon';

import Modal, { ModalSize } from './Modal';

interface IProps {
    children: ReactNode;
    title: string;
    icon?: string;
    modalSize?: ModalSize;
    modalTitle?: string;
    size?: string;
    variant?: 'default' | 'primary';
}

export default function ModalButton({
    children,
    title,
    icon,
    modalSize,
    modalTitle = title,
    size,
    variant = 'default',
}: IProps): ReactElement {
    const modalId = `${Math.random()
        .toString(36)
        .slice(-5)}-modal`;

    return (
        <>
            <a
                className={`btn${size ? ` btn-${size}` : ''} btn-${variant}`}
                data-target={`#${modalId}`}
                data-toggle="modal"
            >
                {icon ? <Icon name={icon} text={title} /> : title}
            </a>
            <Modal id={modalId} size={modalSize} title={modalTitle}>
                {children}
            </Modal>
        </>
    );
}
