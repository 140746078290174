import { IEventRsvp } from 'holberton-school-intranet-api';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';

export interface IState {
    average: number;
    eventRsvps: IEventRsvp[];
    scoreWeight: number;
}

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IAction {}
/* eslint-enable @typescript-eslint/no-empty-interface */

export class UpdateEventRsvps implements IAction {
    constructor(public eventRsvps: IEventRsvp[]) {}
}

export class SetScoreWeight implements IAction {
    constructor(public scoreWeight: number) {}
}

function computeAverage(draft: WritableDraft<IState>): void {
    const sum = draft.eventRsvps.reduce((acc, er) => acc + er.score, 0.0);
    draft.average = sum / draft.eventRsvps.length;
}

export function reducer(state: IState, action: IAction): IState {
    if (action instanceof UpdateEventRsvps) {
        return produce(state, (draft) => {
            const rsvps = action.eventRsvps;

            draft.eventRsvps = draft.eventRsvps.map(
                (er) => rsvps.find((r) => r.id === er.id) || er,
            );

            computeAverage(draft);
        });
    }

    if (action instanceof SetScoreWeight) {
        return produce(state, (draft) => {
            draft.scoreWeight = action.scoreWeight;

            computeAverage(draft);
        });
    }

    return state;
}
