import * as React from 'react';
import { ReactElement } from 'react';

import CalendarIcon from '../../common/CalendarIcon';

interface IProps {
    position: 'left' | 'right';
    scale: number;
    type: 'Deadline' | 'Release';
    value: string;
}

export default function Milestone({
    position,
    scale,
    type,
    value,
}: IProps): ReactElement {
    return (
        <div
            style={{
                left: position === 'left' ? '-5%' : undefined,
                position: 'absolute',
                right: position === 'right' ? '-5%' : undefined,
                top: '-5%',
            }}
        >
            <CalendarIcon
                color={type === 'Deadline' ? '#F75031' : '#21C551'}
                scale={scale * 0.375}
                value={value}
            />
        </div>
    );
}
