import produce from 'immer';
import { Draft, WritableDraft } from 'immer/dist/internal';
import { Key } from 'react';

import { dig } from '../../../utils';
import { match } from '../../common/Search';

interface INamable {
    id: number;
    full_name?: string;
    name: string;
}

interface IGroup<T> {
    items: T[];
    label: string;
}
export interface IState<T> {
    csrfToken: string;
    error: string | null;
    filterBy: string[];
    filterByAttribute: string;
    filterByValues: string[];
    filteredItems: T[];
    groupBy: string;
    groupedItems: IGroup<T>[];
    items: T[];
    keyExtractor: (item: T) => Key;
    loading: boolean;
    searchBy: string[];
    searchQuery: string;
    selected: Key[];
    sortBy: string;
}

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IAction {}
/* eslint-enable @typescript-eslint/no-empty-interface */

export class FilterItems implements IAction {}

export class SearchItems implements IAction {
    constructor(public searchQuery: string) {}
}

export class SelectAll implements IAction {
    constructor(public checked: boolean) {}
}
export class SelectOne implements IAction {
    constructor(public key: Key, public checked: boolean) {}
}

export class SetError implements IAction {
    constructor(public error: string | null) {}
}
export class SetLoading implements IAction {
    constructor(public loading: boolean) {}
}
export class SetFilterBy implements IAction {
    constructor(public value: string) {}
}
export class SetGroupBy implements IAction {
    constructor(public groupBy: string) {}
}
export class SetSortBy implements IAction {
    constructor(public sortBy: string) {}
}

function filterItems<T>(draft: WritableDraft<IState<T>>): void {
    draft.filteredItems = draft.items
        .filter((item) => {
            // Match filters
            if (!draft.filterBy.includes(item[draft.filterByAttribute])) {
                return false;
            }

            // Match search query
            return (
                draft.searchQuery === '' ||
                draft.searchBy.some((k) =>
                    match(draft.searchQuery, dig(item, k).toString()),
                )
            );
        })
        .sort((a, b) =>
            `${dig<Draft<T>, string | number>(a, draft.sortBy)}`.localeCompare(
                `${dig<Draft<T>, string | number>(b, draft.sortBy)}`,
            ),
        );

    const acc = draft.filteredItems.reduce<
        Record<number, WritableDraft<IGroup<T>>>
    >((acc, item) => {
        const obj: INamable = dig(item, draft.groupBy);

        if (!acc[obj.id]) {
            acc[obj.id] = {
                items: [],
                label: obj.full_name || obj.name,
            };
        }
        acc[obj.id].items.push(item);
        return acc;
    }, {});
    draft.groupedItems = Object.values(acc).reduce<WritableDraft<IGroup<T>>[]>(
        (a, g) => a.concat(g),
        [],
    );
}

export function reducer<T>(state: IState<T>, action: IAction): IState<T> {
    if (action instanceof FilterItems) {
        return produce(state, (draft) => {
            filterItems(draft);
        });
    }

    if (action instanceof SearchItems) {
        const searchQuery = action.searchQuery;

        return produce(state, (draft) => {
            draft.searchQuery = searchQuery;
            filterItems(draft);
        });
    }

    if (action instanceof SelectAll) {
        return produce(state, (draft) => {
            draft.selected = action.checked
                ? draft.items.map((item) => state.keyExtractor(item as T))
                : [];
        });
    }
    if (action instanceof SelectOne) {
        return produce(state, (draft) => {
            const asSet = new Set(draft.selected);
            if (action.checked) {
                asSet.add(action.key);
            } else {
                asSet.delete(action.key);
            }
            draft.selected = Array.from(asSet);
        });
    }

    if (action instanceof SetError) {
        return produce(state, (draft) => {
            draft.error = action.error;
        });
    }

    if (action instanceof SetLoading) {
        return produce(state, (draft) => {
            draft.loading = action.loading;
        });
    }

    if (action instanceof SetFilterBy) {
        return produce(state, (draft) => {
            const newSet = new Set(draft.filterBy);

            if (newSet.has(action.value)) {
                newSet.delete(action.value);
            } else {
                newSet.add(action.value);
            }
            draft.filterBy = Array.from(newSet);
            filterItems(draft);
        });
    }

    if (action instanceof SetGroupBy) {
        return produce(state, (draft) => {
            draft.groupBy = action.groupBy;
            filterItems(draft);
        });
    }

    if (action instanceof SetSortBy) {
        return produce(state, (draft) => {
            draft.sortBy = action.sortBy;
            filterItems(draft);
        });
    }

    return state;
}
