import { IProject } from 'holberton-school-intranet-api';
import * as React from 'react';
import { Fragment, ReactElement, useEffect, useRef, useState } from 'react';

import Milestone from './Milestone';
import ProjectCircle from './ProjectCircle';
import Curve from './progress/Curve';
import NinetyDegrees from './progress/NinetyDegrees';
import Straight from './progress/Straight';
import { isRowFromLeftToRight } from './utils';

export interface IContainerProps {
    itemsPerRow: number;
    maxItemsPerRow: number;
    pathThickness: number;
    projects: IProject[];
    scale?: number;
    scrollingElementSelector: string;
    straightPathMaxWidth: number;
}

function groupProjects(
    projects: IProject[],
    itemsPerRow: number,
): IProject[][] {
    const groups = projects.reduce((acc, project, i) => {
        if (i % itemsPerRow === 0) {
            acc.push([project]);
        } else {
            acc[acc.length - 1].push(project);
        }

        return acc;
    }, [] as IProject[][]);

    // The last group must contain only one project - the last one - to be displayed bigger
    const lastGroup = groups[groups.length - 1];
    const isAlone = lastGroup.length === 1;
    if (!isAlone) {
        const lastProject = lastGroup.pop();
        groups.push([lastProject]);
    }

    // Put the latest at the beginning because we want to go from bottom to top
    groups.reverse();

    return groups;
}

export default function Container({
    itemsPerRow,
    maxItemsPerRow,
    pathThickness,
    projects,
    scale = 1.0,
    scrollingElementSelector,
    straightPathMaxWidth,
}: IContainerProps): ReactElement {
    // Since we display the projects in a specific order in a "snake" mode, relying on CSS Flexbox only is not possible.
    // We could use the order property but it's better to have a good structure rather than CSS spaghetti code
    const [projectRows, setProjectRows] = useState<IProject[][]>(
        groupProjects(projects, Math.min(maxItemsPerRow, itemsPerRow)),
    );
    const [spaceBetweenTwoRows, setSpaceBetweenTwoRows] = useState<number>(0);

    const row0Ref = useRef<HTMLDivElement>(null);
    const row1Ref = useRef<HTMLDivElement>(null);
    const currentProjectRef = useRef<HTMLDivElement>(null);

    const circleWidth = scale * 100;
    // The current project is the last accessible one
    const currentProject = projects
        .slice()
        .reverse()
        .find((p) => p.isAccessible);

    useEffect(() => {
        if (row0Ref.current && row1Ref.current) {
            const resize = (): void => {
                setSpaceBetweenTwoRows(
                    row1Ref.current.getClientRects()[0].top -
                        row0Ref.current.getClientRects()[0].top +
                        pathThickness / 2,
                );

                // Recompute the number of items per row according to width
                const { width } = row0Ref.current.getClientRects()[0];
                const availableWidth =
                    width - straightPathMaxWidth * (maxItemsPerRow - 1);
                const newItemsPerRow = Math.min(
                    maxItemsPerRow,
                    Math.ceil(availableWidth / circleWidth),
                );
                setProjectRows(groupProjects(projects, newItemsPerRow));
            };

            resize();

            // ResizeObserver is not yet in the spec
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const ro = new ResizeObserver(() => {
                resize();
            });
            ro.observe(row0Ref.current);
            ro.observe(row1Ref.current);
        }
    }, []);

    useEffect(() => {
        if (currentProjectRef.current) {
            const scrollingElement = document.querySelector(
                scrollingElementSelector,
            );
            if (!scrollingElement || !currentProjectRef.current.offsetParent) {
                return;
            }

            // - circleWidth to have some space before the circle instead of having it cut
            scrollingElement.scroll({
                behavior: 'smooth',
                top:
                    (currentProjectRef.current.offsetParent as HTMLDivElement)
                        .offsetTop -
                    circleWidth / 2,
            });
        }
    }, [scrollingElementSelector, currentProjectRef.current]);

    // The last project is displayed bigger than the others
    const bigScale = scale * 1.5;

    return (
        <div
            className="pathway"
            style={{
                margin: 'auto',
                // Otherwise the elements are too separated from each other on large screens
                maxWidth: 700,
                // Otherwise the elements overlap each other
                minWidth: 170,
                width: '50%',
            }}
        >
            {projectRows.map((row, rowIdx) => (
                <div
                    key={`group-${rowIdx}`}
                    ref={(element): void => {
                        // We only need 2 rows to make our computation
                        if (rowIdx === 0) {
                            row0Ref.current = element;
                        } else if (rowIdx === 1) {
                            row1Ref.current = element;
                        }
                    }}
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: isRowFromLeftToRight(projectRows, rowIdx)
                            ? 'row'
                            : 'row-reverse',
                        flexWrap: 'nowrap',
                        // 10 * 2 to have some padding on top and on bottom
                        height: bigScale * 100 + 20,
                        justifyContent: 'space-between',
                        position: 'relative',
                    }}
                >
                    <NinetyDegrees
                        currentProject={currentProject}
                        row={row}
                        rowIndex={rowIdx}
                        rows={projectRows}
                        spaceBetweenTwoRows={spaceBetweenTwoRows}
                        thickness={pathThickness}
                        // This should be under everything else
                        zIndex={1}
                    />

                    {row.map((project, pIndex) => (
                        <Fragment key={project.id.toString()}>
                            {pIndex > 0 && (
                                <Straight
                                    currentProject={currentProject}
                                    project={project}
                                    thickness={pathThickness}
                                    zIndex={
                                        100 * (projectRows.length - rowIdx + 1)
                                    }
                                />
                            )}

                            <div
                                ref={(ref): void => {
                                    if (
                                        currentProject &&
                                        project.id === currentProject.id
                                    ) {
                                        currentProjectRef.current = ref;
                                    }
                                }}
                                style={{
                                    margin:
                                        row.length === 1 ? 'auto' : undefined,
                                    position: 'relative',
                                    zIndex:
                                        100 * (projectRows.length - rowIdx + 1),
                                }}
                            >
                                <ProjectCircle
                                    project={project}
                                    scale={rowIdx === 0 ? bigScale : scale}
                                    withShadow={true}
                                />
                                {project.milestones?.release && (
                                    <Milestone
                                        position={
                                            isRowFromLeftToRight(
                                                projectRows,
                                                rowIdx,
                                            )
                                                ? 'left'
                                                : 'right'
                                        }
                                        scale={rowIdx === 0 ? bigScale : scale}
                                        type="Release"
                                        value={project.milestones.release}
                                    />
                                )}
                                {project.milestones?.deadline && (
                                    <Milestone
                                        position={
                                            isRowFromLeftToRight(
                                                projectRows,
                                                rowIdx,
                                            )
                                                ? 'right'
                                                : 'left'
                                        }
                                        scale={rowIdx === 0 ? bigScale : scale}
                                        type="Deadline"
                                        value={project.milestones.deadline}
                                    />
                                )}
                            </div>
                        </Fragment>
                    ))}

                    <Curve
                        currentProject={currentProject}
                        rowIndex={rowIdx}
                        rows={projectRows}
                        spaceBetweenTwoRows={spaceBetweenTwoRows}
                        thickness={pathThickness}
                        // Since paths can overlap, we want the top one to be higher than the bottom one
                        zIndex={10 * (projectRows.length - rowIdx + 1)}
                    />
                </div>
            ))}
        </div>
    );
}
