import { IProject } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { isPathColored } from '../utils';

interface IProps {
    currentProject?: IProject;
    project: IProject;
    thickness: number;
    zIndex: number;
}

export default function Straight({
    currentProject,
    project,
    thickness,
    zIndex,
}: IProps): ReactElement {
    return (
        <div
            className={`progress-rectangle ${
                isPathColored(project, currentProject) ? 'active' : ''
            }`}
            style={{
                flex: 1,
                height: thickness,
                zIndex,
            }}
        />
    );
}
