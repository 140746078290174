import {
    IEvent,
    IEventDuplicateValidationRules,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import Form from './Form';
import Report from './Report';

interface IProps {
    csrfToken: string;
    frequencies: {
        label: string;
        value: string;
    }[];
    uri: string;
    validationRules: IEventDuplicateValidationRules;
}

export default function Duplicate({
    csrfToken,
    frequencies,
    uri,
    validationRules,
}: IProps): ReactElement {
    const [events, setEvents] = useState<IEvent[]>([]);

    return (
        <>
            {events.length ? (
                <Report events={events} />
            ) : (
                <Form
                    csrfToken={csrfToken}
                    frequencies={frequencies}
                    onEvents={setEvents}
                    uri={uri}
                    validationRules={validationRules}
                />
            )}
        </>
    );
}
