import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import Tooltip from './Tooltip';

interface IProps {
    color?: string;
    scale?: number;
    showDayName?: boolean;
    value: string;
    variant?: 'info' | 'primary';
}

export default function CalendarIcon({
    color,
    scale = 1.0,
    showDayName = false,
    value,
    variant,
}: IProps): ReactElement {
    const title = moment(value)
        .utcOffset(value)
        .format('YYYY-MM-DD HH:mm ([GMT]Z)');
    const m = moment(value);
    const dateTime = m.format();
    const monthName = m.format('MMM');
    const day = m.format('DD');
    const dayName = m.format('dddd');

    return (
        <Tooltip title={title}>
            <time
                className="user-select-none"
                dateTime={dateTime}
                style={{
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    borderRadius: '0.6em',
                    boxShadow: '0 0 10px 2px #e5e6e6',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: `${scale}em`,
                    height: '6em',
                    overflow: 'hidden',
                    width: '6em',
                }}
            >
                <strong
                    className={variant ? `bg-${variant}` : undefined}
                    style={{
                        backgroundColor: color,
                        color: '#fff',
                        fontSize: '1.5em',
                        lineHeight: '1.25em',
                        padding: '0.1em 0',
                        textAlign: 'center',
                        top: 0,
                        width: '100%',
                    }}
                >
                    {monthName}
                </strong>
                <span
                    style={{
                        color: '#2f2f2f',
                        fontSize: '2.6em',
                        fontWeight: 'bold',
                        letterSpacing: '-0.05em',
                        lineHeight: '0.75em',
                        margin: 'auto',
                    }}
                >
                    {day}
                </span>
                {showDayName && (
                    <span
                        className={variant ? `text-${variant}` : undefined}
                        style={{
                            color: color,
                            fontSize: '0.8em',
                            fontWeight: 500,
                        }}
                    >
                        {dayName}
                    </span>
                )}
            </time>
        </Tooltip>
    );
}
