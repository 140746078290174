import { CSSProperties } from 'react';

interface IStyleAtOptions {
    lightness?: number;
    saturation?: number;
}

function defaultStyleAtOptions(lIdx: number): IStyleAtOptions {
    return {
        lightness: (70 + 85 * lIdx) % 100,
        saturation: 75,
    };
}

export function styleAt(
    hIndex: number,
    hTotal: number,
    lIndex: number,
    options?: IStyleAtOptions,
): CSSProperties {
    const { lightness, saturation } = {
        ...defaultStyleAtOptions(lIndex),
        ...options,
    };
    const hue = (360 / (hTotal + 1)) * (hIndex + 1);

    return {
        backgroundColor: `hsl(${hue}deg, ${saturation}%, ${lightness}%)`,
        color: lightness > 50 ? '#292728' : '#f1f1f1',
    };
}
