import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import Library from './Library';
import PlayerDescription from './PlayerDescription';
import { IVideo } from './types';

interface IProps {
    onVideoChange?: (video: IVideo) => void;
    selected?: IVideo;
    videos: IVideo[];
}

export default function PlayerLibrary({
    onVideoChange,
    selected,
    videos,
}: IProps): ReactElement {
    const [video, setVideo] = useState<IVideo>(null);
    const [search] = useState<string>('');

    useEffect(() => {
        setVideo(selected);
    }, [selected]);

    useEffect(() => {
        onVideoChange && onVideoChange(video);
    }, [video]);

    return (
        <div>
            {video && (
                <PlayerDescription
                    playerOptions={{ autoplay: true }}
                    video={video}
                />
            )}

            <Library
                onVideoClick={setVideo}
                search={search}
                video={video}
                videos={videos}
            />
        </div>
    );
}
