import * as React from 'react';
import { ReactElement, useState } from 'react';
import {
    RefinementListExposed,
    RefinementListProvided,
} from 'react-instantsearch-core';

import Unrefinable from './Unrefinable';

export default function RefinementList({
    canRefine,
    items,
    limit,
    refine,
    showMore,
    showMoreLimit,
}: RefinementListExposed & RefinementListProvided): ReactElement {
    const [cursor, setCursor] = useState<number>(
        showMore ? limit : showMoreLimit,
    );

    if (!canRefine) {
        return <Unrefinable />;
    }

    return (
        <div className="form-group">
            {items
                .sort((a, b) => a.label.localeCompare(b.label))
                .slice(0, cursor)
                .map((item) => (
                    <div className="checkbox" key={item.label}>
                        <label>
                            <input
                                checked={item.isRefined}
                                disabled={!canRefine}
                                onChange={(): void => refine(item.value)}
                                type="checkbox"
                            />{' '}
                            {item.label}{' '}
                            <span className="badge">{item.count}</span>
                        </label>
                    </div>
                ))}

            {showMore && (
                <a
                    href="#"
                    onClick={(e): void => {
                        e.preventDefault();

                        setCursor((current) =>
                            current >= showMoreLimit ? limit : showMoreLimit,
                        );
                    }}
                >
                    Show {cursor >= showMoreLimit ? 'less' : 'more'}
                </a>
            )}
        </div>
    );
}
