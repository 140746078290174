import { IUserActivity } from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import CircleProfilePicture from '../common/CircleProfilePicture';
import Icon from '../common/Icon';

import Card from './Card';

interface IProps {
    leastActiveUsers: IUserActivity[];
}

export default function LeastActive({
    leastActiveUsers,
}: IProps): ReactElement {
    const hasAny = leastActiveUsers.length > 0;

    return (
        <Card sensitive={hasAny} title="Least active students">
            {hasAny ? (
                <div className="d-flex flex-wrap gap-2">
                    {leastActiveUsers.map((userActivity) => (
                        <a
                            className="position-relative"
                            href={userActivity.user.uri}
                            key={userActivity.user.id}
                        >
                            <CircleProfilePicture
                                size={60}
                                user={userActivity.user}
                            />
                            <div
                                className="bg-danger fs-6 p-1 position-absolute rounded-circle"
                                style={{ bottom: 0, right: 0 }}
                            >
                                {moment().diff(
                                    userActivity.last_activity,
                                    'day',
                                )}
                                d
                            </div>
                        </a>
                    ))}
                </div>
            ) : (
                <div className="empty-state fs-5 border-0 mt-5">
                    <Icon name="user" />
                    <span className="empty-state-title">Nothing to show</span>
                </div>
            )}
        </Card>
    );
}
