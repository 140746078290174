import { IProject } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    project: IProject;
    reviewTypeHumanized: string | null;
}

export default function ProjectEncouragement({
    project,
    reviewTypeHumanized,
}: IProps): ReactElement {
    const { completion } = project;

    let message = reviewTypeHumanized;

    if (completion > 0) {
        if (completion <= 80) {
            message = 'Still some tasks to work on!';
        } else if (completion < 100) {
            message = 'Good job! You can still grab some points!';
        } else {
            message = 'Congratulations! You made it!';
        }
    }

    if (!message) {
        return null;
    }

    return <span>{message}</span>;
}
