import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    value: number;
}

export default function ProgressBar({ value }: IProps): ReactElement {
    return (
        <div className="progress">
            <div
                className="active progress-bar progress-bar-primary progress-bar-striped"
                role="progressbar"
                style={{
                    minWidth: '3em',
                    width: `${value}%`,
                }}
            />
        </div>
    );
}
