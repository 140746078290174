import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    className?: string;
    error: string;
}

export default function ErrorInline({
    className,
    error,
}: IProps): ReactElement {
    return <p className={`${className || ''} text-danger`}>{error}</p>;
}
