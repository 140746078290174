import {
    GroupNaming,
    ICurrentUser,
    IGroupActivityGroupUser,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import CircleProfilePicture from '../common/CircleProfilePicture';
import Spinner from '../common/Spinner';
import { OnSelectHandler } from '../search/SearchAndSelect';

import UserControls, {
    OnCancelClickHandler,
    OnMoveClickHandler,
} from './UserControls';
import UserRowMetadata from './UserRowMetadata';

interface IProps {
    csrfToken: string;
    currentUser: ICurrentUser;
    disabled: boolean;
    editing: boolean;
    loading: boolean;
    groupNaming: GroupNaming;
    onCancelMovingToAnotherGroupClick: OnCancelClickHandler;
    onMoveToAnotherGroupClick: OnMoveClickHandler;
    onMoveToNewGroupClick: OnMoveClickHandler;
    onRemoveFromGroupClick?: OnMoveClickHandler;
    onSelectPeerToMoveWith: OnSelectHandler;
    searchURI: string;
    user: IGroupActivityGroupUser;
}

export default function UserRow({
    csrfToken,
    currentUser,
    disabled,
    editing,
    loading,
    groupNaming,
    onCancelMovingToAnotherGroupClick,
    onMoveToAnotherGroupClick,
    onMoveToNewGroupClick,
    onRemoveFromGroupClick,
    onSelectPeerToMoveWith,
    searchURI,
    user,
}: IProps): ReactElement {
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <CircleProfilePicture
                        border={currentUser.id === user.id}
                        color="primary"
                        size={44}
                        tooltip={false}
                        user={user}
                    />
                </div>
                <div className="flex-grow-1 ml-2">
                    <div
                        className={
                            currentUser.id === user.id
                                ? 'font-weight-bold text-primary'
                                : undefined
                        }
                    >
                        {user.link ? (
                            <a href={user.link}>{user.full_name}</a>
                        ) : (
                            <span>{user.full_name}</span>
                        )}
                    </div>
                    <code>{user.id}</code>
                </div>
                {!currentUser.student && (
                    <div>
                        {loading && <Spinner />}

                        {!loading && (
                            <UserControls
                                csrfToken={csrfToken}
                                disabled={disabled}
                                editing={editing}
                                groupNaming={groupNaming}
                                onCancelMovingToAnotherGroupClick={
                                    onCancelMovingToAnotherGroupClick
                                }
                                onMoveToAnotherGroupClick={
                                    onMoveToAnotherGroupClick
                                }
                                onMoveToNewGroupClick={onMoveToNewGroupClick}
                                onRemoveFromGroupClick={onRemoveFromGroupClick}
                                onSelectPeerToMoveWith={onSelectPeerToMoveWith}
                                searchURI={searchURI}
                                user={user}
                            />
                        )}
                    </div>
                )}
            </div>
            {!currentUser.student && (
                <div>
                    <UserRowMetadata csrfToken={csrfToken} user={user} />
                </div>
            )}
        </>
    );
}
