import * as React from 'react';
import { ReactElement, ReactNode, Reducer, useEffect, useReducer } from 'react';

import reducer, { IAction, IState, SetDragged, SetList } from '../reducer';

import DNDItem from './DNDItem';

type itemCallback<T, Ret> = (item: T) => Ret;

interface IProps<T> {
    className?: string;
    draggable?: itemCallback<T, boolean> | boolean;
    dragged?: T | null;
    droppable?: boolean;
    itemClassName?: itemCallback<T, string> | string;
    keyExtractor: itemCallback<T, string>;
    list: T[];
    onChange?: (list: T[]) => void;
    onDrag?: itemCallback<T, void>;
    onItemDropped?: (item: T, index: number) => void;
    renderItem: itemCallback<T, ReactNode>;
}

export default function DNDList<T>({
    className,
    draggable = false,
    dragged,
    droppable = false,
    itemClassName,
    keyExtractor,
    list,
    onChange,
    onDrag,
    onItemDropped,
    renderItem,
}: IProps<T>): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState<T>, IAction>>(reducer, {
        dragged: null,
        initialList: list,
        list: list,
    });

    useEffect(() => {
        dispatch(new SetList(list));
    }, [list]);

    useEffect(() => {
        dispatch(new SetDragged(dragged));
    }, [dragged]);

    useEffect(() => {
        if (onChange && !state.dragged && state.list !== state.initialList) {
            onChange(state.list);
        }
    }, [state.dragged]);

    return (
        <div className={className}>
            {state.list.map((item) => (
                <DNDItem
                    className={
                        itemClassName instanceof Function
                            ? itemClassName(item)
                            : itemClassName
                    }
                    dispatch={dispatch}
                    draggable={
                        draggable instanceof Function
                            ? draggable(item)
                            : draggable
                    }
                    droppable={droppable}
                    item={item}
                    key={keyExtractor(item)}
                    keyExtractor={keyExtractor}
                    onDrag={onDrag}
                    onItemDropped={onItemDropped}
                    state={state}
                >
                    {renderItem(item)}
                </DNDItem>
            ))}
        </div>
    );
}
