import { Weekday } from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { patch } from '../../../../api/utils';
import { pluralize } from '../../../../utils';
import ErrorInline from '../../../common/ErrorInline';
import { IAction, IState, SetLoading, UpdateNoProjectDays } from '../reducer';

interface IProps {
    dispatch: (action: IAction) => void;
    state: IState;
}

export default function NoProjectDays({
    dispatch,
    state,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);

    const onChange = async (weekday: Weekday): Promise<void> => {
        setError(null);
        dispatch(new SetLoading(true));

        const newNoProjectDays = new Set(state.noProjectDays);
        if (newNoProjectDays.has(weekday)) {
            newNoProjectDays.delete(weekday);
        } else {
            newNoProjectDays.add(weekday);
        }

        try {
            await patch(state.updateBatchURI, state.csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                batch: {
                    no_project_days: Array.from(newNoProjectDays),
                },
                /* eslint-enable @typescript-eslint/camelcase */
            });
            dispatch(new UpdateNoProjectDays(Array.from(newNoProjectDays)));
        } catch (err) {
            setError(err.message);
        }

        dispatch(new SetLoading(false));
    };

    const totalDays = Array.from(state.noProjectDays).reduce(
        (total, noProjectDay) => {
            let newTotal = total;
            const date = moment(state.startDate).day(noProjectDay);
            if (date.isBefore(state.startDate)) {
                date.add(1, 'week');
            }
            while (date.isSameOrBefore(state.endDate)) {
                newTotal++;
                date.add(1, 'week');
            }

            return newTotal;
        },
        0,
    );

    return (
        <div>
            <div className="field">
                <span>
                    <label>No-project days</label>
                    <small className="ml-2 text-muted">
                        {pluralize(totalDays, 'day')}
                    </small>
                </span>

                <div className="btn-group">
                    {(moment.weekdays() as Weekday[]).map((weekday) => (
                        <a
                            className={`btn btn-sm btn-${
                                state.noProjectDays.includes(weekday)
                                    ? 'info'
                                    : 'default'
                            }${state.loading ? ' disabled' : ''}`}
                            key={weekday}
                            onClick={(): Promise<void> => onChange(weekday)}
                            role="button"
                        >
                            {weekday.slice(0, 3)}
                        </a>
                    ))}
                </div>
            </div>

            {error && <ErrorInline error={error} />}
        </div>
    );
}
