import { ITeamContribution, IUser } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useState } from 'react';

import { post } from '../../api/utils';
import { pluralize } from '../../utils';
import Icon from '../common/Icon';

interface IProps {
    contribution: ITeamContribution | null;
    userId: IUser['id'];
    csrfToken: string;
}

export default function TeamContribution({
    contribution,
    csrfToken,
    userId,
}: IProps): ReactElement {
    const [loading, setLoading] = useState<boolean>(false);
    const [skipped, setSkipped] = useState<boolean>(contribution.skip);

    /* eslint-disable @typescript-eslint/camelcase */
    const { add, del, toggle_skip_uri, total } = contribution;
    /* eslint-enable @typescript-eslint/camelcase */

    const onToggleSkipClick = async (): Promise<void> => {
        try {
            setLoading(true);
            await post(toggle_skip_uri, csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                user_id: userId,
                /* eslint-enable @typescript-eslint/camelcase */
            });
            setSkipped((prev) => !prev);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="align-items-center d-flex mt-2">
            <span className="text-muted">
                <Icon
                    className=""
                    fixedWidth
                    name="code-fork"
                    text={total.toString()}
                    title={pluralize(total, 'commit')}
                />
            </span>
            <span className="text-success ml-1">
                <Icon
                    fixedWidth
                    name="plus"
                    text={add.toString()}
                    title={pluralize(add, 'addition')}
                />
            </span>
            <span className="text-danger ml-1">
                <Icon
                    fixedWidth
                    name="minus"
                    text={del.toString()}
                    title={pluralize(del, 'deletion')}
                />
            </span>
            <button
                className={`btn btn-xs ml-2 ${
                    skipped ? 'btn-warning' : 'btn-default'
                }`}
                disabled={loading}
                onClick={onToggleSkipClick}
            >
                {skipped ? 'Unskip' : 'Skip'} contribution
            </button>
        </div>
    );
}
