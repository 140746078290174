import { IEvent } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { isURL, pluralize } from '../../../../utils';
import CalendarIcon from '../../../common/CalendarIcon';
import DateTime from '../../../common/DateTime';
import Icon from '../../../common/Icon';

interface IProps {
    event: IEvent;
}

export default function EventPreview({ event }: IProps): ReactElement {
    return (
        <div className="d-flex flex-column gap-3">
            <h3 className="m-0">{event.title}</h3>

            <div className="align-items-center d-flex gap-5 sm-gap">
                <CalendarIcon
                    scale={1}
                    showDayName={true}
                    value={event.start_at}
                    variant="primary"
                />
                <div className="d-flex flex-column gap-3">
                    <span className="align-items-center d-flex text-muted gap-2">
                        <Icon fixedWidth name="clock-o" />
                        <span>
                            <div className="d-inline-block">
                                <DateTime
                                    showDate={false}
                                    value={event.start_at}
                                />
                            </div>
                            - {event.duration_in_words}
                        </span>
                    </span>

                    <span className="align-items-center d-flex text-muted gap-2">
                        {isURL(event.location) ? (
                            <>
                                <Icon fixedWidth name="link" />
                                <a
                                    href={event.location}
                                    rel="noreferrer"
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: 190,
                                    }}
                                    target="_blank"
                                >
                                    {event.location}
                                </a>
                            </>
                        ) : event.location ? (
                            <>
                                <Icon fixedWidth name="map-marker" />
                                {event.location}
                            </>
                        ) : null}
                    </span>
                </div>
            </div>

            <a
                className="btn btn-default mt-2"
                href={event.uri}
                rel="noreferrer"
                target="_blank"
            >
                <Icon fixedWidth name="external-link" />
                <span>Open</span>
            </a>

            <div className="mt-2">
                <h3 className="fs-3 mb-2 mt-0">About</h3>

                <div className="d-flex flex-column gap-2 text-muted">
                    {event.author && (
                        <span className="align-items-center d-flex gap-2">
                            <Icon fixedWidth name="pencil-square-o" />
                            <span>Created by {event.author.full_name}</span>
                        </span>
                    )}

                    <span className="align-items-center d-flex gap-2">
                        {event.is_optional ? (
                            <>
                                <Icon fixedWidth name="toggle-off" />
                                <span>Optional participation</span>
                            </>
                        ) : (
                            <>
                                <Icon fixedWidth name="toggle-on" />
                                <span>Mandatory participation</span>
                            </>
                        )}
                    </span>

                    <span className="align-items-center d-flex gap-2">
                        <Icon fixedWidth name="user-o" />
                        <span>
                            <strong>{event.event_rsvps_count}</strong>{' '}
                            {pluralize(event.event_rsvps_count, 'participant', {
                                strOnly: true,
                            })}
                        </span>
                    </span>

                    {event.grouping_mode && (
                        <span className="align-items-center d-flex gap-2">
                            <Icon fixedWidth name="users" />
                            <span>
                                <strong>{event.event_groups_count}</strong>{' '}
                                {pluralize(event.event_groups_count, 'group', {
                                    strOnly: true,
                                })}
                            </span>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}
