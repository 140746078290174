import { IBatch, ISchoolLocation } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import MultiSLBatches from './MultiSLBatches';
import SingleSLBatches from './SingleSLBatches';

interface IProps {
    batches: IBatch[];
    id: string;
    name: string;
    schoolLocations: ISchoolLocation[];
    value: number[];
}

export default function Batches(props: IProps): ReactElement {
    return props.schoolLocations.length > 1 ? (
        <MultiSLBatches {...props} />
    ) : (
        <SingleSLBatches {...props} />
    );
}
