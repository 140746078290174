import { IBatchPlanningItem } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { ISession } from '../Projects';

import SessionTimelinePanel from './SessionTimelinePanel';

interface IProps {
    csrfToken: string;
    dailyWorkHours: number;
    onUpdate: (response: IBatchPlanningItem[]) => void;
    sessions: ISession[];
}

export default function ProjectsTimeline({
    csrfToken,
    dailyWorkHours,
    onUpdate,
    sessions,
}: IProps): ReactElement {
    return (
        <ul
            className="p-5 timeline"
            style={{ listStyle: 'none', position: 'relative' }}
        >
            {sessions.map((session, i) => (
                <SessionTimelinePanel
                    csrfToken={csrfToken}
                    dailyWorkHours={dailyWorkHours}
                    key={`session-${i}`}
                    onUpdate={onUpdate}
                    session={session}
                />
            ))}
        </ul>
    );
}
