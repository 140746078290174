import * as React from 'react';
import { ReactElement } from 'react';

import { IVideo } from './types';

interface IProps {
    video: IVideo;
}

export default function VideoDescription({ video }: IProps): ReactElement {
    return (
        <div>
            <div
                className="sm-gap"
                dangerouslySetInnerHTML={{
                    __html: video.description,
                }}
            />
        </div>
    );
}
