import { IVideoRoom } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import ColoredIcon from './ColoredIcon';
import VideoRoomLabels from './VideoRoomLabels';

interface IProps {
    videoRooms: IVideoRoom[];
}

export default function VideoRoomsList({ videoRooms }: IProps): ReactElement {
    return (
        <div className="video-rooms-list panel panel-default">
            <table className="table table-hover table-striped">
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center', width: '350px' }}>
                            NAME
                        </th>
                        <th style={{ width: '180px' }}>CREATION DATE</th>
                        <th style={{ width: '180px' }}>AUTHOR</th>
                        <th>TAGS</th>
                        <th colSpan={1} style={{ width: '135px' }} />
                    </tr>
                </thead>
                <tbody>
                    {videoRooms.map((videoRoom) => (
                        <VideoRoomRow
                            key={videoRoom.id}
                            videoRoom={videoRoom}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

interface IVideoRoomRowProps {
    videoRoom: IVideoRoom;
}

function VideoRoomRow({ videoRoom }: IVideoRoomRowProps): ReactElement {
    return (
        <tr className={`${videoRoom.active ? '' : 'offline'}`}>
            <td style={{ alignItems: 'center', display: 'flex' }}>
                <ColoredIcon size={60} />
                <div className="video-room-name" style={{ marginLeft: '16px' }}>
                    {videoRoom.name}
                    <div className="video-room-author">
                        <div className="video-room-creation">
                            {videoRoom.createdAt}
                        </div>
                        <div className="video-room-author">
                            {videoRoom.createdBy}
                        </div>
                    </div>
                </div>
            </td>
            <td className="video-room-creation">{videoRoom.createdAt}</td>
            <td className="video-room-author">{videoRoom.createdBy}</td>
            <td className="video-room-labels">
                <VideoRoomLabels videoRoom={videoRoom} />
            </td>
            <td style={{ textAlign: 'center' }}>
                <a
                    className="btn btn-md btn-primary"
                    href={videoRoom.url}
                    rel="noreferrer"
                    style={{ width: 'fit-content' }}
                    target="_blank"
                >
                    Open<span> this room</span>
                </a>
            </td>
        </tr>
    );
}
