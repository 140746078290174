import { ISearchModel } from 'holberton-school-intranet-api';
import * as React from 'react';
import { CSSProperties, ReactElement, useEffect, useState } from 'react';

import ErrorAlert from '../common/ErrorAlert';
import Icon from '../common/Icon';
import { BuilderSearchItemRenderer } from '../curriculums/search/BuilderSearch';

import { ISearchResult } from './SearchBar';
import { searchModels } from './api-rest-adapter';

interface IProps {
    className?: string;
    csrfToken: string;
    id?: string;
    items: ISearchResult[];
    loadMoreURI?: string;
    model: ISearchModel;
    onLoadedMoreItems: (items: ISearchResult[], nextURI?: string) => void;
    renderItem: BuilderSearchItemRenderer;
    style?: CSSProperties;
}

export default function SearchResultList({
    className,
    csrfToken,
    id,
    items,
    loadMoreURI,
    model,
    onLoadedMoreItems,
    renderItem,
    style,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [itemList, setItemList] = useState<ISearchResult[]>(items);
    const [nextURI, setNextURI] = useState<string | undefined>(loadMoreURI);

    useEffect(() => setItemList(items), [items]);
    useEffect(() => setNextURI(loadMoreURI), [loadMoreURI]);

    const onLoadMore = async (): Promise<void> => {
        setError(null);
        setLoading(true);

        try {
            const apiResults = await searchModels(nextURI, csrfToken, [
                model.name,
            ]);
            if (apiResults[model.name]) {
                onLoadedMoreItems(
                    apiResults[model.name].items,
                    apiResults[model.name].loadMoreURI,
                );
                setNextURI(apiResults[model.name].loadMoreURI);
            }
        } catch (err) {
            setError(err.message);
        }

        setLoading(false);
    };

    return (
        <div
            className={['list-group', className].join(' ')}
            id={id}
            style={style}
        >
            {itemList.map((item) => renderItem(item, model))}

            {nextURI && (
                <div
                    className="list-group-item"
                    onClick={loading ? undefined : onLoadMore}
                    style={{
                        cursor: loading ? undefined : 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {loading ? (
                        <Icon fixedWidth name="spinner" spin />
                    ) : (
                        <a>
                            Load more&nbsp;
                            <Icon fixedWidth name="chevron-down" />
                        </a>
                    )}
                </div>
            )}

            {error && <ErrorAlert error={error} />}
        </div>
    );
}
