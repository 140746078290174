import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    error: string;
    noMargin?: boolean;
}

export default function ErrorAlert({
    error,
    noMargin = false,
}: IProps): ReactElement {
    let className = 'alert alert-danger';
    if (noMargin) {
        className += ' mb-0';
    }

    return (
        <div className={className} role="alert">
            {error}
        </div>
    );
}
