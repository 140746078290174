import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import Tooltip from './Tooltip';

interface IProps {
    showDate?: boolean;
    showTime?: boolean;
    value: string;
}

export default function DateTime({
    showDate = true,
    showTime = true,
    value,
}: IProps): ReactElement {
    return (
        <Tooltip
            title={moment(value)
                .utcOffset(value)
                .format('YYYY-MM-DD HH:mm ([GMT]Z)')}
        >
            <span className="datetime">
                {moment(value).format(
                    `${showDate ? 'll' : ''} ${showTime ? 'LT' : ''}`,
                )}
            </span>
        </Tooltip>
    );
}
