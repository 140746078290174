import {
    IEvaluationQuizCheckboxQuestion,
    IEvaluationQuizMultipleChoiceQuestion,
    IEvaluationQuizQuestion,
    IEvaluationQuizSelectQuestion,
    IEvaluationQuizSubmitResponse,
    IEvaluationQuizSubmitResponseInProgress,
    IEvaluationQuizSubmitResponseNotStarted,
} from 'holberton-school-intranet-api';

export function isCheckboxQuestion(
    question: IEvaluationQuizQuestion,
): question is IEvaluationQuizCheckboxQuestion {
    return question.type === 'Checkbox';
}

export function isInProgress(
    response: IEvaluationQuizSubmitResponse | null,
): response is IEvaluationQuizSubmitResponseInProgress {
    return (
        response &&
        !!(response as IEvaluationQuizSubmitResponseInProgress).question
    );
}

export function isNotStarted(
    response: IEvaluationQuizSubmitResponse,
): response is IEvaluationQuizSubmitResponseNotStarted {
    return !!(response as IEvaluationQuizSubmitResponseNotStarted).action;
}

export function isMultipleChoiceQuestion(
    question: IEvaluationQuizQuestion,
): question is IEvaluationQuizMultipleChoiceQuestion {
    return !!(question as IEvaluationQuizMultipleChoiceQuestion).items;
}

export function isRadioableQuestion(
    question: IEvaluationQuizQuestion,
): question is IEvaluationQuizSelectQuestion {
    return !!(question as IEvaluationQuizSelectQuestion).is_radio;
}
