import * as React from 'react';
import { ReactElement } from 'react';

import BuilderSearch, {
    BuilderSearchItemRenderer,
    IProps as IBuilderSearchProps,
} from '../curriculums/search/BuilderSearch';

import CurriculumItemableSummaryCard from './CurriculumItemableSummaryCard';

interface IProps {
    searchImplementations: IBuilderSearchProps['implementations'];
}

export default function CurriculumItemablesBrowser({
    searchImplementations,
}: IProps): ReactElement {
    const renderItem: BuilderSearchItemRenderer = (item): ReactElement => (
        <div className="list-group-item" key={item.id}>
            <CurriculumItemableSummaryCard item={item} />
        </div>
    );

    return (
        <BuilderSearch
            implementations={searchImplementations}
            renderItem={renderItem}
        />
    );
}
