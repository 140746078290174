import {
    IBatchPlanningItem,
    MilestoneType,
    ReleaseScope,
    SessionChangeDirection,
} from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ChangeEvent, ReactElement, useState } from 'react';

import Icon from '../../../common/Icon';
import Tooltip from '../../../common/Tooltip';
import { ISessionItem } from '../Projects';

interface IProps {
    item: ISessionItem;
    onChangeSession?: (
        bpi: IBatchPlanningItem,
        direction: SessionChangeDirection,
    ) => Promise<void>;
    onSubmitMilestone?: (
        bpi: IBatchPlanningItem,
        type: MilestoneType,
        date: string,
        scope?: ReleaseScope,
    ) => Promise<void>;
}

const sessionChangeDirectionsMapping = new Map<SessionChangeDirection, string>([
    ['up', 'arrow-up'],
    ['down', 'arrow-down'],
]);

export default function SessionItem({
    item: { bpi, possibleChangeDirections },
    onChangeSession,
    onSubmitMilestone,
}: IProps): ReactElement {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [type, setType] = useState<MilestoneType>('start');
    const [scope, setScope] = useState<ReleaseScope>('everyone');
    const [date, setDate] = useState<string>(bpi.starts_at);

    const onChangeType = (e: ChangeEvent<HTMLSelectElement>): void => {
        setType(e.target.value as MilestoneType);
    };
    const onChangeScope = (e: ChangeEvent<HTMLSelectElement>): void => {
        setScope(e.target.value as ReleaseScope);
    };
    const onChangeDate = (e: ChangeEvent<HTMLInputElement>): void => {
        setDate(e.target.value);
    };

    const onSubmit = async (): Promise<void> => {
        setIsLoading(true);
        await onSubmitMilestone(bpi, type, date, scope);
        setIsEditing(false);
        setIsLoading(false);
    };

    return (
        <div className="list-group-item">
            <div className="align-items-center d-flex justify-content-between">
                <div>
                    <h4 className="list-group-item-heading m-0">
                        <a href={bpi.project.uri}>{bpi.project.name}</a>
                        {bpi.in_team && (
                            <span className="ml-2 text-muted">
                                <Icon name="users" title="Team project" />
                            </span>
                        )}
                    </h4>

                    <small className="text-muted">Weight: {bpi.weight}</small>
                </div>

                <div className="align-items-center d-flex gap-4">
                    {bpi.uri && (
                        <Tooltip title="See planning item">
                            <a className="text-info" href={bpi.uri}>
                                <Icon name="calendar-o" />
                            </a>
                        </Tooltip>
                    )}

                    {onSubmitMilestone && (
                        <Tooltip title="Add milestone">
                            <a
                                onClick={(): void => setIsEditing(true)}
                                role="button"
                            >
                                <Icon name="plus" />
                            </a>
                        </Tooltip>
                    )}

                    {onChangeSession && (
                        <>
                            <span>|</span>

                            {Array.from(sessionChangeDirectionsMapping).map(
                                ([d, icon]) => (
                                    <span key={d} style={{ width: '13px' }}>
                                        {possibleChangeDirections.includes(
                                            d,
                                        ) ? (
                                            <Tooltip title={`Move ${d}`}>
                                                <a
                                                    onClick={(): Promise<
                                                        void
                                                    > =>
                                                        onChangeSession(bpi, d)
                                                    }
                                                    role="button"
                                                >
                                                    <Icon name={icon} />
                                                </a>
                                            </Tooltip>
                                        ) : (
                                            <span />
                                        )}
                                    </span>
                                ),
                            )}
                        </>
                    )}
                </div>
            </div>

            {isEditing && (
                <form className="align-items-center d-flex gap-3 justify-content-between mt-2">
                    <select
                        className="form-control w-auto"
                        disabled={isLoading}
                        onChange={onChangeType}
                        value={type}
                    >
                        <option value="start">Release</option>
                        <option value="end">Deadline</option>
                    </select>

                    {type === 'start' && (
                        <select
                            className="form-control  w-auto"
                            disabled={isLoading}
                            onChange={onChangeScope}
                            value={scope}
                        >
                            <option value="completed">Optional</option>
                            <option value="everyone">Mandatory</option>
                        </select>
                    )}

                    <div
                        className={`mt-0 field${
                            moment(date).isValid() ? '' : '_with_errors'
                        }`}
                    >
                        <input
                            className="form-control"
                            disabled={isLoading}
                            min={moment
                                .max(moment(), moment(bpi.starts_at))
                                .format('YYYY-MM-DD')}
                            onChange={onChangeDate}
                            type="date"
                            value={date}
                        />
                    </div>

                    {isLoading ? (
                        <Icon fixedWidth name="spinner" spin />
                    ) : (
                        <>
                            {moment(date).isValid() && (
                                <Icon name="check" onClick={onSubmit} />
                            )}
                            <Icon
                                className="text-danger"
                                name="times"
                                onClick={(): void => setIsEditing(false)}
                            />
                        </>
                    )}
                </form>
            )}
        </div>
    );
}
