import { ITag } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import { post } from '../../api/utils';
import Select, { IOption } from '../common/select/Select';

import Tag from './Tag';

interface IProps {
    createTagURI: string;
    csrfToken: string;
    initialOptions: ITag[];
    initialValues: ITag[];
    inputId: string;
    name: string;
}

export default function TagFormField({
    createTagURI,
    csrfToken,
    initialOptions,
    initialValues,
    inputId,
    name,
}: IProps): ReactElement {
    const [allOptions, setAllOptions] = useState<IOption<ITag>[]>(
        initialOptions.map((tag) => ({
            destroyable: false,
            label: tag.value,
            value: tag,
        })),
    );
    const [options, setOptions] = useState<IOption<ITag>[]>([...allOptions]);
    const [values, setValues] = useState<ITag[]>(initialValues);

    useEffect(() => {
        setOptions(
            allOptions.filter((o) => !values.some((v) => v.id === o.value.id)),
        );
    }, [allOptions, values]);

    const onChangeOption = (option: ITag | null): void => {
        if (!option) {
            return;
        }
        setValues(values.filter((v) => v.id !== option.id).concat([option]));
    };

    const onCreateOption = async (value: string): Promise<void> => {
        const response = await post<ITag>(createTagURI, csrfToken, {
            tag: {
                value,
            },
        });
        setAllOptions((arr) =>
            arr.concat([
                {
                    destroyable: false,
                    label: response.value,
                    value: {
                        id: response.id,
                        uri: response.uri,
                        value: response.value,
                    },
                },
            ]),
        );
    };

    return (
        <div>
            <Select
                inputId={inputId}
                onCreateOption={onCreateOption}
                onSubmit={onChangeOption}
                options={options}
            >
                <a>+ Add a tag</a>
            </Select>

            <div className="align-items-center d-flex flex-wrap gap-3 my-2">
                {values.map((value) => (
                    <span key={value.id}>
                        <input name={name} type="hidden" value={value.id} />
                        <Tag
                            key={value.id}
                            label={value.value}
                            onDeleteClick={(): void => {
                                setValues(
                                    values.filter((v) => v.id !== value.id),
                                );
                            }}
                        />
                    </span>
                ))}

                {/* Rails require the field to be present to reset all the values if none is provided */}
                {values.length === 0 && <input name={name} type="hidden" />}
            </div>
        </div>
    );
}
