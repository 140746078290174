import { IEvent } from 'holberton-school-intranet-api';
import moment from 'moment';
import * as React from 'react';
import { ReactElement } from 'react';

import { pluralize } from '../../../utils';
import Icon from '../../common/Icon';

interface IProps {
    events: IEvent[];
}

function dateStr(date: string): string {
    const m = moment(date);
    let fmt = 'MMM Do YY, h';

    if (m.minute()) {
        fmt += ':m';
    }
    fmt += 'a';

    return m.format(fmt);
}

export default function Report({ events }: IProps): ReactElement {
    return (
        <div className="d-flex flex-column gap-2">
            <label>Created {pluralize(events.length, 'event')}</label>

            <div className="list-group">
                {events.map((event) => (
                    <a
                        className="list-group-item"
                        href={event.uri}
                        key={event.id}
                        rel="noreferrer"
                        target="_blank"
                    >
                        <span className="d-flex fs-4 gap-2">
                            <code>{event.id}</code>
                            <span className="fw-bold">{event.title}</span>
                        </span>
                        <small className="align-items-center d-flex gap-2 text-muted">
                            {dateStr(event.start_at)}
                            <Icon name="long-arrow-right" />
                            {dateStr(event.end_at)}
                        </small>
                    </a>
                ))}
            </div>
        </div>
    );
}
