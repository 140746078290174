import {
    ICurriculumItemableSearchResult,
    ISearchModel,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import BuilderSearchAlgolia, {
    IProps as IBuilderSearchAlgoliaProps,
} from './algolia/BuilderSearchAlgolia';
import BuilderSearchLocal, {
    IProps as IBuilderSearchLocalProps,
} from './local/BuilderSearchLocal';

/**
 * Defines the renderItem method that needs to be implemented in a search implementation (algolia, local)
 * The model is passed as 2nd parameter because it's not available in the item for local search
 */
export type BuilderSearchItemRenderer = (
    item: ICurriculumItemableSearchResult,
    model: ISearchModel,
) => ReactElement;

export interface IProps {
    implementations: {
        algolia: Omit<IBuilderSearchAlgoliaProps, 'renderItem'>;
        local: Omit<IBuilderSearchLocalProps, 'renderItem'>;
    };
    renderItem: BuilderSearchItemRenderer;
}

export default function BuilderSearch({
    implementations: { algolia, local },
    renderItem,
}: IProps): ReactElement {
    // Even if it's not used by default, we keep the local search. It can be useful for multiple reasons:
    //   - it forces us to keep the search components abstract and separate concerns
    //   - in case Algolia is down or degraded, the builder can still work in degraded mode
    let searchComponent = (
        <BuilderSearchLocal
            csrfToken={local.csrfToken}
            renderItem={renderItem}
            searchModels={local.searchModels}
            searchURI={local.searchURI}
        />
    );

    const { searchSettings } = algolia;
    if (
        searchSettings.app_id &&
        searchSettings.index_name &&
        searchSettings.key
    ) {
        searchComponent = (
            <BuilderSearchAlgolia
                renderItem={renderItem}
                searchSettings={searchSettings}
            />
        );
    }

    return searchComponent;
}
