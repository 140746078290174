import produce from 'immer';

export interface IState<T> {
    dragged: T | null;
    initialList: T[];
    list: T[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAction {}

export class CancelDragging implements IAction {}
export class StartDragging<T> implements IAction {
    dragged: T;

    constructor(dragged: T) {
        this.dragged = dragged;
    }
}
export class StopDragging<T> implements IAction {}
export class UpdateDragging<T> implements IAction {
    item: T;

    constructor(item: T) {
        this.item = item;
    }
}
export class SetList<T> implements IAction {
    list: T[];

    constructor(list: T[]) {
        this.list = list;
    }
}
export class SetDragged<T> implements IAction {
    item: T | null;

    constructor(item: T | null) {
        this.item = item;
    }
}

export default function reducer<T>(
    state: IState<T>,
    action: IAction,
): IState<T> {
    if (action instanceof StartDragging) {
        return produce(state, (draft) => {
            draft.dragged = action.dragged;
        });
    }

    if (action instanceof CancelDragging) {
        return produce(state, (draft) => {
            draft.dragged = null;
            draft.list = draft.initialList;
        });
    }

    if (action instanceof StopDragging) {
        return produce(state, (draft) => {
            draft.dragged = null;
        });
    }

    if (action instanceof UpdateDragging) {
        return produce(state, (draft) => {
            const newIdx = state.list.findIndex((i) => i === action.item);
            const oldIdx = state.list.findIndex((i) => i === state.dragged);

            draft.list.splice(oldIdx, 1);
            draft.list.splice(newIdx, 0, draft.dragged);
        });
    }

    if (action instanceof SetList) {
        return produce(state, (draft) => {
            draft.initialList = action.list;
            draft.list = action.list;
        });
    }

    if (action instanceof SetDragged) {
        return produce(state, (draft) => {
            draft.dragged = action.item;

            if (action.item) {
                draft.list.splice(0, 0, action.item);
            } else {
                draft.list = draft.initialList;
            }
        });
    }

    return state;
}
