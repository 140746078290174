import { IBlock, ICurriculumItem } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, Reducer, useReducer } from 'react';

import BuilderContentTable from './BuilderContentTable';
import BuilderManager from './BuilderManager';
import reducer, { IAction, IState } from './reducer';
import { IProps as IBuilderSearchProps } from './search/BuilderSearch';

interface IProps {
    blocks: IBlock[];
    canEdit: boolean;
    createBlockURI: string;
    createItemURI: string;
    csrfToken: string;
    curriculumID: number;
    items: ICurriculumItem[];
    searchImplementations: IBuilderSearchProps['implementations'];
    trackID: number;
}

export default function Builder(props: IProps): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState, IAction>>(reducer, {
        ...props,
        isLocked: false,
        items: props.items.map((item) => ({
            ...item,
            error: null,
            isEditing: false,
            isLoading: false,
        })),
        workHours: props.items.reduce(
            (acc, i) => acc + i.item.work_hours || 0,
            0,
        ),
    });

    return (
        <div className="d-flex flex-wrap gap-5">
            <div style={{ flex: 2, minWidth: '240px' }}>
                <BuilderManager
                    dispatch={dispatch}
                    searchImplementations={props.searchImplementations}
                    state={state}
                />
            </div>
            <div style={{ flex: 3 }}>
                <BuilderContentTable dispatch={dispatch} state={state} />
            </div>
        </div>
    );
}
