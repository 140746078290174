import { IProject } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useRef } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import CircularProgressBarRadialSeparators from '../../common/CircularProgressBarRadialSeparators';
import Tooltip from '../../common/Tooltip';

import ProjectCircleLabel from './ProjectCircleLabel';

interface IProps {
    placement?: 'regular' | 'tile';
    project: IProject;
    scale?: number;
    withShadow: boolean;
}

const CIRCULAR_PROGRESSBAR_STROKE_WIDTH = 3;

export default function ProjectCircle({
    placement = 'regular',
    project,
    scale = 1.0,
    withShadow,
}: IProps): ReactElement {
    const linkRef = useRef<HTMLAnchorElement>(null);
    const size = scale * 100;

    const onClick = (): void => {
        if (linkRef.current) {
            linkRef.current.click();
        }
    };

    return (
        <>
            {project.url && <a href={project.url} ref={linkRef} />}

            <div
                className={`project-circle ${
                    placement === 'tile' ? 'project-circle-on-tile' : ''
                }`}
                onClick={onClick}
                style={{
                    borderRadius: '100%',
                    boxShadow: withShadow
                        ? '10px 20px 10px #E5E6E6'
                        : undefined,
                    cursor: project.url ? 'pointer' : undefined,
                    height: size,
                    margin: 'auto',
                    padding: 5,
                    width: size,
                }}
            >
                <CircularProgressbarWithChildren
                    strokeWidth={CIRCULAR_PROGRESSBAR_STROKE_WIDTH}
                    styles={{
                        root: {
                            height: size - 10,
                            verticalAlign: 'middle',
                            width: size - 10,
                        },
                    }}
                    value={project.completion}
                >
                    <CircularProgressBarRadialSeparators
                        count={project.tasksCount}
                        // Needs to be a little bit more than CircularProgressbarWithChildren.strokeWidth to be displayed well
                        height={CIRCULAR_PROGRESSBAR_STROKE_WIDTH + 1}
                        // Prevents artifacts when the width is not an even number
                        width={Math.ceil((5 * scale) / 2) * 2}
                    />

                    <div className="position-relative">
                        <Tooltip title={project.name}>
                            <div
                                className={`align-items-center d-flex justify-content-center project-circle-body ${
                                    project.isAccessible ? 'active' : ''
                                }`}
                                style={{
                                    borderRadius: '100%',
                                    // Prevents the circle from being bigger on one side
                                    height: Math.floor((size - 25) / 2) * 2,
                                    width: Math.floor((size - 25) / 2) * 2,
                                }}
                            >
                                <img
                                    alt="Project badge"
                                    src={project.imagePath}
                                    width="60%"
                                />
                            </div>
                        </Tooltip>

                        <ProjectCircleLabel project={project} size={size} />
                    </div>
                </CircularProgressbarWithChildren>
            </div>
        </>
    );
}
