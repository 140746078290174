import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import Rheostat from 'rheostat';

interface IValues {
    max: number | undefined;
    min: number | undefined;
}

interface IProps extends IValues {
    current: IValues;
    onChange: ({ max, min }: IValues) => void;
}

export default function RangeSlider({
    current,
    max,
    min,
    onChange,
}: IProps): ReactElement | null {
    const [live, setLive] = useState<IValues>({ max: max || 0, min: min || 0 });

    useEffect(() => {
        // The values can change dynamically according to the implementation (i.e. with Algolia, they are set asynchronously)
        setLive({ max: max || 0, min: min || 0 });
    }, [max, min]);

    if (max === undefined || min === undefined) {
        return null;
    }

    return (
        <div>
            <Rheostat
                disabled={max === min}
                max={max}
                min={min}
                onChange={({ values: [min, max] }): void => {
                    onChange({ max, min });
                }}
                onValuesUpdated={({ values: [min, max] }): void => {
                    setLive({ max, min });
                }}
                values={[current.min, current.max]}
            />

            <div className="d-flex justify-content-between">
                <div className="font-weight-bold">{live.min}</div>
                <div className="font-weight-bold">{live.max}</div>
            </div>
        </div>
    );
}
