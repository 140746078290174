import { IVideoRoom } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import ColoredIcon from './ColoredIcon';
import VideoRoomLabels from './VideoRoomLabels';

interface IProps {
    videoRooms: IVideoRoom[];
}

export default function VideoRoomsGrid({ videoRooms }: IProps): ReactElement {
    return (
        <div className="video-rooms-grid">
            {videoRooms.map((videoRoom) => (
                <VideoRoomCell key={videoRoom.id} videoRoom={videoRoom} />
            ))}
        </div>
    );
}

interface IVideoRoomCellProps {
    videoRoom: IVideoRoom;
}

function VideoRoomCell({ videoRoom }: IVideoRoomCellProps): ReactElement {
    return (
        <div
            className={`panel panel-default ${
                videoRoom.active ? '' : 'offline'
            }`}
        >
            <div
                className="panel-body text-center"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                }}
            >
                <ColoredIcon size={70} />
                <div className="video-room-name">{videoRoom.name}</div>
                <div className="video-room-author" style={{ marginTop: '6px' }}>
                    {videoRoom.createdAt} by {videoRoom.createdBy}
                </div>
                <div className="video-room-labels" style={{ marginTop: '6px' }}>
                    <VideoRoomLabels videoRoom={videoRoom} />
                </div>
                <a
                    className="btn btn-md btn-primary btn-block"
                    href={videoRoom.url}
                    rel="noreferrer"
                    style={{ marginTop: 'auto' }}
                    target="_blank"
                >
                    Open this room
                </a>
            </div>
        </div>
    );
}
