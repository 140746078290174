import * as React from 'react';
import { CSSProperties, ReactElement } from 'react';

import Icon from './Icon';

interface IProps {
    current: number;
    disabled?: boolean;
    onClick: (page: number) => void;
    pages: number;
}

function pageRange(currentPage: number, totalPages: number): number[] {
    const current = currentPage,
        last = totalPages,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
        if (i <= 2 || i >= last - 1 || (i >= left && i < right)) {
            range.push(i);
        }
    }

    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push(-1);
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

export default function Pagination({
    current,
    disabled = false,
    onClick,
    pages,
}: IProps): ReactElement {
    const range = pageRange(current, pages);
    const customStyle: CSSProperties = {
        border: '1px solid transparent',
        borderRadius: '6px',
        lineHeight: '20px',
        padding: '5px 10px',
        textAlign: 'center',
        textDecoration: 'none',
        transition: 'border-color .2s cubic-bezier(0.3, 0, 0.5, 1)',
        userSelect: 'none',
    };

    return (
        <div className="d-flex justify-content-center">
            <div className="align-items-center d-flex gap-2">
                <span
                    onClick={(): void =>
                        current !== 1 && !disabled && onClick(current - 1)
                    }
                    role={current === 1 || disabled ? undefined : 'button'}
                    style={customStyle}
                >
                    <Icon fixedWidth name="angle-left" />
                    Previous
                </span>

                {range.map((p, idx) => (
                    <div key={`${p}.${idx}`}>
                        {p === -1 ? (
                            <span style={customStyle}>...</span>
                        ) : (
                            <span
                                className={current === p ? 'bg-primary' : ''}
                                onClick={(): void => !disabled && onClick(p)}
                                role={disabled ? undefined : 'button'}
                                style={customStyle}
                            >
                                {p}
                            </span>
                        )}
                    </div>
                ))}

                <span
                    onClick={(): void =>
                        current !== pages && !disabled && onClick(current + 1)
                    }
                    role={current === pages || disabled ? undefined : 'button'}
                    style={customStyle}
                >
                    Next
                    <Icon fixedWidth name="angle-right" />
                </span>
            </div>
        </div>
    );
}
