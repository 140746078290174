import {
    IEvaluationQuizMultipleChoiceQuestion,
    IEvaluationQuizQuestionOptionItem,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Code from '../common/Code';

import { useContainerContext } from './ContainerContext';
import { isCheckboxQuestion, isRadioableQuestion } from './api-utils';

interface IProps {
    answers: string[];
    item: IEvaluationQuizQuestionOptionItem;
    onChangeAnswers: (newAnswers: string[]) => Promise<void>;
    question: IEvaluationQuizMultipleChoiceQuestion;
}

export default function AnswerOption({
    answers,
    item,
    onChangeAnswers,
    question,
}: IProps): ReactElement {
    const context = useContainerContext();
    const { submitting } = context;

    /* eslint-disable @typescript-eslint/camelcase */
    const isAutoSubmit = question.auto_submit;
    const isRadio = isRadioableQuestion(question) && question.is_radio;
    /* eslint-enable @typescript-eslint/camelcase */

    const isOptionChecked = !!answers.find((a) => a === item.value);

    const onCardClick = async (): Promise<void> => {
        if (submitting) {
            return;
        }

        const checked = !isOptionChecked;
        const { value } = item;

        let newAnswers = answers;

        // Some quizzes (i.e.: C quiz) use Checkbox questions in auto_submit mode. This is the behavior of a Select radio.
        // So for backward compatibility we need to implement them as as a radio, even if it's a checkbox
        if (isRadio || isAutoSubmit) {
            if (checked) {
                newAnswers = [value];
            } else {
                newAnswers = [];
            }
        } else if (isCheckboxQuestion(question)) {
            if (checked) {
                if (value === question.cleanup_item) {
                    newAnswers = [value];
                } else {
                    newAnswers = answers
                        .filter((a) => a !== question.cleanup_item)
                        .concat([value]);
                }
            } else {
                newAnswers = answers.filter((a) => a !== value);
            }
        } else if (checked) {
            newAnswers = answers.concat([value]);
        } else {
            newAnswers = answers.filter((a) => a !== value);
        }

        onChangeAnswers(newAnswers);
    };

    return (
        <div className="answer-option" key={item.value} onClick={onCardClick}>
            <button
                className={`btn ${
                    isOptionChecked ? 'btn-primary' : 'btn-default'
                }`}
                disabled={submitting}
                type="button"
            >
                {isOptionChecked && <i className="fa fa-check" />}
            </button>

            <div className="content">
                <Code content={item.html} />
            </div>
        </div>
    );
}
