import {
    ICurrentUser,
    IGroupActivity,
    IGroupActivityFilters,
    IGroupActivityGroupUser,
    IGroupActivityJoinGroupRequest,
} from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import { get, post, put } from '../../api/utils';
import { pluralize } from '../../utils';
import Icon from '../common/Icon';
import Spinner from '../common/Spinner';
import ModalButton from '../common/modal/ModalButton';
import Generate, { IProps as GenerateProps } from '../events/groups/Generate';
import SearchAndSelect from '../search/SearchAndSelect';
import { ISearchResult } from '../search/SearchBar';

import Group from './Group';
import UserRow from './UserRow';

interface IProps {
    csrfToken: string;
    currentUser: ICurrentUser;
    groupMaxSize: number | null;
    groupNaming: 'group' | 'team';
    grouping?: GenerateProps;
    item: IGroupActivity;
    joinGroupURI: string;
    leaveGroupURI: string;
    searchGroupsURI: string;
    searchURI: string;
    studentCanChangeGroup: boolean;
}

export default function Editor({
    csrfToken,
    currentUser,
    groupMaxSize,
    groupNaming,
    grouping,
    item,
    joinGroupURI,
    leaveGroupURI,
    searchGroupsURI,
    searchURI,
    studentCanChangeGroup,
}: IProps): ReactElement {
    const [editingUserId, setEditingUserId] = useState<
        IGroupActivityGroupUser['id'] | null
    >(null);
    const [itemLocal, setItemLocal] = useState<IGroupActivity>(item);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingUserId, setLoadingUserId] = useState<
        IGroupActivityGroupUser['id'] | null
    >(null);
    const [searchItem, setSearchItem] = useState<ISearchResult | null>(null);
    const [searchMessage, setSearchMessage] = useState<string | null>(null);
    const [filters, setFilters] = useState<IGroupActivityFilters>({});

    useEffect(() => {
        // Reset the search message everytime we load again
        (loading || loadingUserId) && setSearchMessage(null);
    }, [loading, loadingUserId]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        setFilters((v) => ({ ...v, user_id: searchItem?.id }));
    }, [searchItem]);

    const searchGroup = async (): Promise<void> => {
        try {
            setLoading(true);
            const response = await get<IGroupActivity, IGroupActivityFilters>(
                searchGroupsURI,
                csrfToken,
                filters,
            );
            setItemLocal(response);
            if (response.groups.length === 0) {
                const msg = `No ${groupNaming} matches the search criteria`;
                setSearchMessage(msg);
            }
        } catch (err) {
            alert(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        searchGroup();
    }, [filters]);

    const onCancelMovingToAnotherGroupClick = (): void => {
        setEditingUserId(null);
    };

    const onClearUser = async (): Promise<void> => {
        setSearchItem(null);
    };

    const onCreateANewGroup = async (): Promise<void> => {
        try {
            setLoading(true);
            const response = await post<
                IGroupActivity,
                IGroupActivityJoinGroupRequest
            >(joinGroupURI, csrfToken, {
                /* eslint-disable @typescript-eslint/camelcase */
                peer_id: null,
                /* eslint-enable @typescript-eslint/camelcase */
            });
            setItemLocal(response);
        } catch (err) {
            alert(err.message);
        } finally {
            setLoading(false);
        }
    };

    const onMoveToAnotherGroupClick = async (
        user: IGroupActivityGroupUser,
    ): Promise<void> => {
        setEditingUserId(user.id);
    };

    const onMoveToNewGroupClick = async (
        user: IGroupActivityGroupUser,
    ): Promise<void> => {
        try {
            setLoadingUserId(user.id);
            await post<void, IGroupActivityJoinGroupRequest>(
                joinGroupURI,
                csrfToken,
                {
                    /* eslint-disable @typescript-eslint/camelcase */
                    peer_id: null,
                    user_id: user.id,
                    /* eslint-enable @typescript-eslint/camelcase */
                },
            );
            setSearchItem({ id: user.id, label: user.full_name }); // Select the user so the search reflects the response filtered on the updated group
        } catch (err) {
            alert(err.message);
        } finally {
            setLoadingUserId(null);
        }
    };

    const onRemoveFromGroupClick = async (
        user: IGroupActivityGroupUser,
    ): Promise<void> => {
        try {
            setLoadingUserId(user.id);
            await put<void, IGroupActivityJoinGroupRequest>(
                leaveGroupURI,
                csrfToken,
                {
                    /* eslint-disable @typescript-eslint/camelcase */
                    user_id: user.id,
                    /* eslint-enable @typescript-eslint/camelcase */
                },
            );
            setFilters((v) => ({ ...v })); // Refresh the groups
        } catch (err) {
            alert(err.message);
        } finally {
            setLoadingUserId(null);
        }
    };

    const onSelectPeerToMoveWith = async (
        item: ISearchResult,
    ): Promise<void> => {
        try {
            setLoadingUserId(editingUserId);
            await post<void, IGroupActivityJoinGroupRequest>(
                joinGroupURI,
                csrfToken,
                {
                    /* eslint-disable @typescript-eslint/camelcase */
                    peer_id: item.id,
                    user_id: editingUserId,
                    /* eslint-enable @typescript-eslint/camelcase */
                },
            );
            setSearchItem(item); // Select the user so the search reflects the response filtered on the updated group
        } catch (err) {
            alert(err.message);
        } finally {
            setEditingUserId(null);
            setLoadingUserId(null);
        }
    };

    const onSelectUser = async (item: ISearchResult): Promise<void> => {
        setSearchItem(item);
    };

    const onToggleNotFullFilter = async (): Promise<void> => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        setFilters((v) => ({ ...v, is_not_full: !v.is_not_full }));
    };

    const isAloneInAGroup =
        itemLocal.group_pinned && itemLocal.group_pinned.users.length === 1;
    const isFiltered =
        itemLocal.groups.length === 1 && itemLocal.groups_total > 1;

    return (
        <div className="row">
            <div
                className={`col-sm-12${currentUser.student ? '' : ' col-md-9'}`}
            >
                {itemLocal.group_pinned && (
                    <>
                        <h4>Your {groupNaming}</h4>

                        <Group
                            csrfToken={csrfToken}
                            currentUser={currentUser}
                            editingUserId={editingUserId}
                            group={itemLocal.group_pinned}
                            groupMaxSize={groupMaxSize}
                            groupNaming={groupNaming}
                            joinGroupURI={joinGroupURI}
                            key={itemLocal.group_pinned.id}
                            leaveGroupURI={leaveGroupURI}
                            loading={loading}
                            loadingUserId={loadingUserId}
                            onCancelMovingToAnotherGroupClick={
                                onCancelMovingToAnotherGroupClick
                            }
                            onJoinCompleted={(response): void =>
                                setItemLocal(response)
                            }
                            onLeaveCompleted={(response): void =>
                                setItemLocal(response)
                            }
                            onMoveToAnotherGroupClick={
                                onMoveToAnotherGroupClick
                            }
                            onMoveToNewGroupClick={onMoveToNewGroupClick}
                            onRemoveFromGroupClick={onRemoveFromGroupClick}
                            onSelectPeerToMoveWith={onSelectPeerToMoveWith}
                            searchURI={searchURI}
                            studentCanChangeGroup={studentCanChangeGroup}
                        />
                    </>
                )}

                {currentUser.student &&
                    studentCanChangeGroup &&
                    !isAloneInAGroup && (
                        <div className="my-2 text-center">
                            <button
                                className="btn btn-primary btn-sm"
                                disabled={loading}
                                onClick={onCreateANewGroup}
                            >
                                Create a new {groupNaming}
                            </button>
                        </div>
                    )}

                {loading && (
                    <div className="my-2">
                        <Spinner />
                    </div>
                )}

                <h4>
                    Showing {itemLocal.groups.length}/
                    {pluralize(itemLocal.groups_total, groupNaming)}
                </h4>

                <div className="align-items-center d-flex gap-3 my-2">
                    <div className="w-full">
                        <SearchAndSelect
                            csrfToken={csrfToken}
                            model="users"
                            name="user_id"
                            onClear={onClearUser}
                            onSelect={onSelectUser}
                            placeholder="Search for a student"
                            searchURI={searchURI}
                            selectedItem={searchItem}
                        />
                    </div>

                    <button
                        className="btn btn-default"
                        disabled={loading}
                        onClick={onToggleNotFullFilter}
                    >
                        <Icon
                            fixedWidth
                            name="filter"
                            text={
                                filters.is_not_full
                                    ? `Show all ${groupNaming}s`
                                    : `Hide full ${groupNaming}s`
                            }
                        />
                    </button>
                </div>

                {isFiltered && (
                    <div className="my-2 text-center">
                        <button
                            className="btn btn-primary btn-sm"
                            disabled={loading}
                            onClick={(): void => setFilters({})}
                        >
                            See all{' '}
                            {pluralize(2, groupNaming, { strOnly: true })}
                        </button>
                    </div>
                )}

                {searchMessage && (
                    <div className="alert alert-warning my-2">
                        {searchMessage}
                    </div>
                )}

                <div className="groups-grid">
                    {itemLocal.groups.map((group) => (
                        <Group
                            csrfToken={csrfToken}
                            currentUser={currentUser}
                            editingUserId={editingUserId}
                            group={group}
                            groupMaxSize={groupMaxSize}
                            groupNaming={groupNaming}
                            joinGroupURI={joinGroupURI}
                            key={group.id}
                            leaveGroupURI={leaveGroupURI}
                            loading={loading}
                            loadingUserId={loadingUserId}
                            onCancelMovingToAnotherGroupClick={
                                onCancelMovingToAnotherGroupClick
                            }
                            onJoinCompleted={(response): void =>
                                setItemLocal(response)
                            }
                            onLeaveCompleted={(response): void =>
                                setItemLocal(response)
                            }
                            onMoveToAnotherGroupClick={
                                onMoveToAnotherGroupClick
                            }
                            onMoveToNewGroupClick={onMoveToNewGroupClick}
                            onRemoveFromGroupClick={onRemoveFromGroupClick}
                            onSelectPeerToMoveWith={onSelectPeerToMoveWith}
                            searchURI={searchURI}
                            studentCanChangeGroup={studentCanChangeGroup}
                        />
                    ))}
                </div>
            </div>

            {!currentUser.student && (
                <div className="col-sm-12 col-md-3 d-flex flex-column gap-3">
                    {grouping && (
                        <>
                            <div>
                                <h3 className="fs-3 mb-2 mt-0">Manage</h3>
                                <ModalButton
                                    icon="refresh"
                                    title="Generate"
                                    variant="primary"
                                >
                                    <Generate
                                        {...grouping}
                                        csrfToken={csrfToken}
                                    />
                                </ModalButton>
                            </div>

                            <hr className="w-full" />
                        </>
                    )}

                    <div>
                        <h3 className="fs-3 mb-2 mt-0">Summary</h3>
                        <div className="d-flex flex-column gap-2">
                            {grouping && (
                                <span className="align-items-center d-flex gap-2 text-muted">
                                    <Icon
                                        fixedWidth
                                        name={grouping.mode.icon}
                                        text={grouping.mode.label}
                                    />
                                </span>
                            )}

                            {groupMaxSize && (
                                <span className="align-items-center d-flex gap-2 text-muted">
                                    <Icon fixedWidth name="caret-up" />
                                    <strong>{groupMaxSize}</strong>
                                    per group at most
                                </span>
                            )}

                            <span className="align-items-center d-flex gap-2 text-muted">
                                {itemLocal.ungrouped.length > 0 ? (
                                    <>
                                        <Icon fixedWidth name="clock-o" />
                                        <strong>
                                            {itemLocal.ungrouped.length}
                                        </strong>
                                        {pluralize(
                                            itemLocal.ungrouped.length,
                                            'participant',
                                            { strOnly: true },
                                        )}{' '}
                                        without a group
                                    </>
                                ) : (
                                    <Icon
                                        fixedWidth
                                        name="check"
                                        text="Everyone has a group"
                                    />
                                )}
                            </span>

                            {itemLocal.ungrouped.length > 0 && (
                                <ul className="list-group">
                                    {itemLocal.ungrouped.map((user) => (
                                        <li
                                            className="list-group-item"
                                            key={user.id}
                                        >
                                            <UserRow
                                                csrfToken={csrfToken}
                                                currentUser={currentUser}
                                                disabled={loading}
                                                editing={
                                                    editingUserId === user.id
                                                }
                                                groupNaming={groupNaming}
                                                loading={
                                                    loadingUserId === user.id
                                                }
                                                onCancelMovingToAnotherGroupClick={
                                                    onCancelMovingToAnotherGroupClick
                                                }
                                                onMoveToAnotherGroupClick={
                                                    onMoveToAnotherGroupClick
                                                }
                                                onMoveToNewGroupClick={
                                                    onMoveToNewGroupClick
                                                }
                                                onSelectPeerToMoveWith={
                                                    onSelectPeerToMoveWith
                                                }
                                                searchURI={searchURI}
                                                user={user}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
