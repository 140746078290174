import * as React from 'react';
import { ReactElement, ReactNode, Reducer, useEffect, useReducer } from 'react';

import reducer, { IAction, IState, SetDragged, SetList } from '../reducer';

import DNDTableRow, { OnItemDropped } from './DNDTableRow';

type itemCallback<T, Ret> = (item: T) => Ret;

interface IProps<T> {
    className?: string;
    draggable?: itemCallback<T, boolean> | boolean;
    dragged?: T | null;
    droppable?: boolean;
    itemClassName?: itemCallback<T, string> | string;
    keyExtractor: itemCallback<T, string>;
    list: T[];
    onChange?: (list: T[]) => void;
    onDrag?: itemCallback<T, void>;
    onItemDropped: OnItemDropped<T>;
    renderItem: itemCallback<T, ReactNode>;
}

export default function DNDTable<T>({
    className,
    draggable = false,
    dragged,
    droppable = false,
    itemClassName,
    keyExtractor,
    list,
    onChange,
    onDrag,
    onItemDropped,
    renderItem,
}: IProps<T>): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState<T>, IAction>>(reducer, {
        dragged: null,
        initialList: list,
        list: list,
    });

    useEffect(() => {
        dispatch(new SetList(list));
    }, [list]);

    useEffect(() => {
        dispatch(new SetDragged(dragged));
    }, [dragged]);

    useEffect(() => {
        if (onChange && !state.dragged && state.list !== state.initialList) {
            onChange(state.list);
        }
    }, [state.dragged]);

    return (
        <table className={className}>
            <tbody>
                {state.list.map((item) => (
                    <DNDTableRow
                        className={
                            itemClassName instanceof Function
                                ? itemClassName(item)
                                : itemClassName
                        }
                        dispatch={dispatch}
                        draggable={
                            draggable instanceof Function
                                ? draggable(item)
                                : draggable
                        }
                        droppable={droppable}
                        item={item}
                        key={keyExtractor(item)}
                        keyExtractor={keyExtractor}
                        onDrag={onDrag}
                        onItemDropped={onItemDropped}
                        state={state}
                    >
                        {renderItem(item)}
                    </DNDTableRow>
                ))}
            </tbody>
        </table>
    );
}
