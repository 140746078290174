import { ICurriculumItemableSearchResult } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import Icon from '../common/Icon';
import CurriculumItemableSummaryCard from '../curriculum_itemables/CurriculumItemableSummaryCard';

interface IProps {
    csrfToken: string;
    items: ICurriculumItemableSearchResult[];
    name: string;
    selectURI: string;
}

export default function CurriculumItemableSelector({
    csrfToken,
    items,
    name,
    selectURI,
}: IProps): ReactElement {
    return (
        <ul className="list-group">
            {items.map((item) => (
                <li className="list-group-item" key={item.id}>
                    <CurriculumItemableSummaryCard
                        item={item}
                        renderActions={(): ReactElement => (
                            <form action={selectURI} method="post">
                                <input
                                    name="authenticity_token"
                                    type="hidden"
                                    value={csrfToken}
                                />

                                <input
                                    name={name}
                                    type="hidden"
                                    value={item.id}
                                />

                                <button
                                    className="btn btn-primary btn-sm"
                                    type="submit"
                                >
                                    <Icon fixedWidth name="plus" />
                                </button>
                            </form>
                        )}
                    />
                </li>
            ))}
        </ul>
    );
}
