import { IPlanningSession } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import { ISession } from '../../projects/Projects';
import Session from '../../projects/timeline/Session';

interface IProps {
    dailyWorkHours: number;
    session: IPlanningSession;
}

export default function SessionPreview({
    dailyWorkHours,
    session: planningSession,
}: IProps): ReactElement {
    const session: ISession = {
        endDate: planningSession.ends_at,
        items: planningSession.projects.map((bpi) => ({
            bpi,
            possibleChangeDirections: [],
        })),
        releaseScope: planningSession.projects[0].release_scope,
        startDate: planningSession.starts_at,
        workHours: planningSession.projects.reduce<number>(
            (acc, bpi) => acc + bpi.project.work_hours,
            0,
        ),
    };
    return <Session dailyWorkHours={dailyWorkHours} session={session} />;
}
