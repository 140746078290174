import * as React from 'react';
import {
    InputHTMLAttributes,
    Key,
    ReactElement,
    Reducer,
    useEffect,
    useReducer,
} from 'react';

import ErrorAlert from '../ErrorAlert';
import Icon from '../Icon';
import Panel from '../Panel';
import Search from '../Search';

import DataTableHeading from './DataTableHeading';
import DataTableRowsGroup from './DataTableRowsGroup';
import {
    FilterItems,
    IAction,
    IState,
    SearchItems,
    SetError,
    SetLoading,
    reducer,
} from './reducer';

export interface ISelect {
    hasInput?: boolean;
    onSubmit: (value: string, selected: Key[]) => void | Promise<void>;
    options: Record<string, string> | InputHTMLAttributes<HTMLInputElement>;
    title: string;
    value?: string;
}

export interface IFiltering {
    attribute: string;
    values: string[];
}

export interface IColumns<T, C> {
    columns: C[];
    keyExtractor: (col: C) => Key;
    renderCell: (item: T, index: number, col?: C) => ReactElement;
    renderHeading?: (col: C, index: number) => ReactElement;
}

interface ICallToAction {
    icon: string;
    onClick: () => Promise<void>;
    title: string;
}

interface IProps<T, C> {
    bulks: ISelect[];
    columns: IColumns<T, C>;
    csrfToken: string;
    cta?: ICallToAction;
    filtering: IFiltering;
    grouping: Record<string, string>;
    items: T[];
    keyExtractor: (item: T) => Key;
    renderItem: (item: T) => ReactElement;
    searchBy: string[];
    sorting: Record<string, string>;
}

export default function DataTable<T, C>({
    bulks,
    columns,
    csrfToken,
    cta,
    filtering,
    grouping,
    items,
    keyExtractor,
    renderItem,
    searchBy,
    sorting,
}: IProps<T, C>): ReactElement {
    const [state, dispatch] = useReducer<Reducer<IState<T>, IAction>>(reducer, {
        csrfToken,
        error: null,
        filterBy: [filtering.values[0]],
        filterByAttribute: filtering.attribute,
        filterByValues: filtering.values,
        filteredItems: [],
        groupBy: Object.values(grouping)[0],
        groupedItems: [],
        items,
        keyExtractor,
        loading: false,
        searchBy,
        searchQuery: '',
        selected: [],
        sortBy: Object.values(sorting)[0],
    });

    useEffect(() => dispatch(new FilterItems()), []);

    const onClickCTA = async (): Promise<void> => {
        if (state.loading) {
            return;
        }

        try {
            dispatch(new SetLoading(true));
            dispatch(new SetError(null));
            await cta.onClick();
        } catch (err) {
            dispatch(new SetError(err.message));
        } finally {
            dispatch(new SetLoading(false));
        }
    };

    return (
        <div className="d-flex flex-column gap-5">
            <div className="align-items-center d-flex gap-3">
                <Search
                    onSearch={(query: string): void =>
                        dispatch(new SearchItems(query))
                    }
                    placeholder="Search for users ..."
                    search={state.searchQuery}
                />

                {cta && (
                    <button
                        className="btn btn-sm btn-primary"
                        disabled={state.loading}
                        onClick={onClickCTA}
                    >
                        <Icon
                            name={state.loading ? 'circle-o-notch' : cta.icon}
                            spin={state.loading}
                            text={cta.title}
                        />
                    </button>
                )}
            </div>

            {state.error && <ErrorAlert error={state.error} />}

            <Panel className="overflow-visible">
                <DataTableHeading
                    bulks={state.items.length > 0 ? bulks : []}
                    dispatch={dispatch}
                    grouping={grouping}
                    sorting={sorting}
                    state={state}
                />

                {state.filteredItems.length ? (
                    <table className="align-middle table table-hover">
                        {columns.renderHeading && (
                            <thead>
                                <tr>
                                    <th />
                                    {columns.columns.map((item, index) => (
                                        <th key={columns.keyExtractor(item)}>
                                            {columns.renderHeading(item, index)}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {state.groupedItems.map((group, i) => (
                                <DataTableRowsGroup
                                    columns={columns}
                                    dispatch={dispatch}
                                    group={group}
                                    key={i}
                                    renderItem={renderItem}
                                    state={state}
                                />
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="panel-body">
                        <div className="alert alert-info">
                            <span>Nothing matches the search query</span>
                        </div>
                    </div>
                )}
            </Panel>
        </div>
    );
}
