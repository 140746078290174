import * as React from 'react';
import { ReactElement, ReactNode, useState } from 'react';

import Icon from './Icon';
import Tooltip from './Tooltip';

interface IProps {
    children?: ReactNode;
    color: string;
    icon?: string;
    onClick?: () => void;
    size: number;
    title?: string;
}

export default function CircleButton({
    children,
    color,
    icon,
    onClick,
    size,
    title,
}: IProps): ReactElement {
    const [hover, setHover] = useState<boolean>(false);

    return (
        <Tooltip title={title}>
            <div
                onClick={onClick}
                onMouseEnter={(): void => setHover(true)}
                onMouseLeave={(): void => setHover(false)}
                style={{
                    alignItems: 'center',
                    backgroundColor: hover ? color : 'transparent',
                    border: `1px solid ${color}`,
                    borderRadius: '50%',
                    color: hover ? '#fff' : color,
                    cursor: 'pointer',
                    display: 'inline-flex',
                    height: `${size}px`,
                    justifyContent: 'center',
                    margin: 'auto',
                    textAlign: 'center',
                    width: `${size}px`,
                }}
            >
                {icon ? <Icon name={icon} /> : <span>{children}</span>}
            </div>
        </Tooltip>
    );
}
