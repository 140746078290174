import { IUserScore } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement } from 'react';

import CircleProfilePicture from '../common/CircleProfilePicture';
import Icon from '../common/Icon';

import Card from './Card';

interface IProps {
    lowScoreUsers: IUserScore[];
}

export default function LeastActive({ lowScoreUsers }: IProps): ReactElement {
    const hasAny = lowScoreUsers.length > 0;

    return (
        <Card sensitive={hasAny} title="Low score">
            {hasAny ? (
                <div className="d-flex flex-wrap gap-2">
                    {lowScoreUsers.map((userScore) => (
                        <a
                            className="position-relative"
                            href={userScore.user.uri}
                            key={userScore.user.id}
                        >
                            <CircleProfilePicture
                                size={60}
                                user={userScore.user}
                            />
                            <div
                                className="bg-danger fs-6 p-1 position-absolute rounded-circle"
                                style={{ bottom: 0, right: 0 }}
                            >
                                {userScore.score}%
                            </div>
                        </a>
                    ))}
                </div>
            ) : (
                <div className="empty-state fs-5 border-0 mt-5">
                    <Icon name="user" />
                    <span className="empty-state-title">Nothing to show</span>
                </div>
            )}
        </Card>
    );
}
