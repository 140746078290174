import * as React from 'react';
import { DragEvent, ReactElement, ReactNode } from 'react';

import {
    CancelDragging,
    IAction,
    IState,
    StartDragging,
    StopDragging,
    UpdateDragging,
} from '../reducer';

interface IProps<T> {
    children: ReactNode;
    className?: string;
    dispatch: (action: IAction) => void;
    draggable: boolean;
    droppable: boolean;
    item: T;
    keyExtractor: (item: T) => string;
    onDrag?: (item: T) => void;
    onItemDropped: (item: T, index: number) => void;
    state: IState<T>;
}

export default function DNDItem<T>({
    children,
    className,
    dispatch,
    draggable,
    droppable,
    item,
    keyExtractor,
    onDrag,
    onItemDropped,
    state,
}: IProps<T>): ReactElement {
    const isDragged =
        droppable &&
        state.dragged &&
        keyExtractor(item) === keyExtractor(state.dragged);

    const onDragEnd = (e: DragEvent): void => {
        e.preventDefault();

        onDrag && onDrag(null);

        if (e.dataTransfer.dropEffect === 'none') {
            dispatch(new CancelDragging());
        }
    };

    const onDragEnter = (e: DragEvent): void => {
        if (!droppable) {
            return;
        }

        e.preventDefault();

        if (state.dragged && item !== state.dragged) {
            e.dataTransfer.dropEffect = 'move';
            dispatch(new UpdateDragging(item));
        }
    };

    const onDragStart = (e: DragEvent): void => {
        e.dataTransfer.effectAllowed = 'copyMove';

        /**
         * Using a timeout of 0 allows the browser to render the drag "ghost"
         * before it is made invisible
         */
        setTimeout(() => {
            dispatch(new StartDragging(item));

            onDrag && onDrag(item);
        }, 0);
    };

    const onDrop = (): void => {
        if (!droppable) {
            return;
        }

        dispatch(new StopDragging());

        if (
            onItemDropped &&
            !state.list.every((v, i) => state.initialList[i] === v)
        ) {
            const index = state.list.findIndex((i) => i === item);
            onItemDropped(item, index);
        }
    };

    return (
        <div
            className={className}
            draggable={draggable}
            onDragEnd={onDragEnd}
            onDragEnter={onDragEnter}
            onDragOver={(e: DragEvent): void => droppable && e.preventDefault()}
            onDragStart={onDragStart}
            onDrop={onDrop}
            style={isDragged ? { position: 'relative' } : undefined}
        >
            <div style={isDragged ? { visibility: 'hidden' } : undefined}>
                {children}
            </div>

            {isDragged && (
                <div
                    className="align-items-center d-flex justify-content-center bg-info border-info text-info"
                    style={{
                        border: '2px dashed',
                        height: '100%',
                        left: 0,
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                    }}
                >
                    <span className="fs-3 fw-bold">Release to drop</span>
                </div>
            )}
        </div>
    );
}
