import * as React from 'react';
import { ReactElement, useState } from 'react';

import { patch } from '../../api/utils';
import ErrorAlert from '../common/ErrorAlert';
import Icon from '../common/Icon';

import ProjectReadyForReviewBody, {
    IProjectPeerReview,
} from './ProjectReadyForReviewBody';

export interface IProps {
    csrfToken: string;
    firstReview: boolean;
    peerReview: IProjectPeerReview;
    toggled: boolean;
}

export default function ProjectReadyForReview({
    csrfToken,
    firstReview,
    peerReview,
    toggled,
}: IProps): ReactElement {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [readyForReview, setReadyForReview] = useState<boolean>(toggled);

    const onUpdateReadyForReview = async (): Promise<void> => {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            await patch(peerReview.readyForReviewURI, csrfToken, {});
            setReadyForReview(!readyForReview);
            setError(null);
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    return (
        <div className="row gap">
            <div className="col-md-12">
                {!readyForReview && (
                    <div className="text-center">
                        <button
                            className="btn btn-lg btn-primary"
                            disabled={loading}
                            onClick={(): void => {
                                if (
                                    confirm(
                                        'Are you sure? your project will become available for peer review',
                                    )
                                ) {
                                    onUpdateReadyForReview();
                                }
                            }}
                        >
                            {loading && <Icon fixedWidth name="spinner" spin />}
                            Ready for a {firstReview ? '' : 'new'} manual review
                        </button>
                    </div>
                )}

                {readyForReview && (
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">
                                Ready for manual review
                            </h3>
                        </div>

                        <div className="panel-body">
                            <ProjectReadyForReviewBody
                                csrfToken={csrfToken}
                                peerReview={peerReview}
                            />
                        </div>
                    </div>
                )}

                {error && (
                    <div className="sm-gap">
                        <ErrorAlert error={error} />
                    </div>
                )}
            </div>
        </div>
    );
}
