import * as React from 'react';
import { ReactElement } from 'react';

interface IProps {
    name: string;
    onSelect: () => void;
    selected: boolean;
}

export default function SchoolLocationSelector({
    name,
    onSelect,
    selected,
}: IProps): ReactElement {
    return (
        <button
            className={`btn btn-${selected ? 'primary' : 'outline-primary'}`}
            onClick={onSelect}
            style={{ outline: 'none' }}
        >
            {name}
        </button>
    );
}
