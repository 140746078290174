import { IProject } from 'holberton-school-intranet-api';
import * as React from 'react';
import { CSSProperties, ReactElement } from 'react';

import { isPathColored, isRowFromLeftToRight } from '../utils';

interface IProps {
    currentProject?: IProject;
    row: IProject[];
    rows: IProject[][];
    rowIndex: number;
    spaceBetweenTwoRows: number;
    thickness: number;
    zIndex: number;
}

export default function NinetyDegrees({
    currentProject,
    row,
    rows,
    rowIndex,
    spaceBetweenTwoRows,
    thickness,
    zIndex,
}: IProps): ReactElement | null {
    const fromLTR = isRowFromLeftToRight(rows, rowIndex);

    // First row corresponding to the last projects since they are reversed
    const isFirstRow = rowIndex === 0;
    // Last row corresponding to the first projects
    const isLastRow = rowIndex === rows.length - 1;

    if (!isFirstRow && !isLastRow) {
        return null;
    }

    const firstRowStyle: CSSProperties = {
        borderBottomLeftRadius: fromLTR ? undefined : 100,
        borderBottomRightRadius: fromLTR ? 100 : undefined,
        borderWidth: fromLTR
            ? //    |
              // ___|
              `0 ${thickness}px ${thickness}px 0`
            : // |
              // |___
              `0 0 ${thickness}px ${thickness}px`,
        bottom: spaceBetweenTwoRows / 2 - 0.75 * thickness, // 0.75 because we need to take into account the thickness of the bars and the position of <Straight /> components
        left: fromLTR ? spaceBetweenTwoRows / 2 : -spaceBetweenTwoRows / 2,
    };
    const lastRowStyle: CSSProperties = {
        borderTopLeftRadius: 100,
        borderWidth: fromLTR
            ? // ___
              // |
              // |
              `${thickness}px 0 0 ${thickness}px`
            : // Not possible
              undefined,
        left: fromLTR ? -spaceBetweenTwoRows / 2 : undefined,
        top: spaceBetweenTwoRows / 2 - 0.75 * thickness, // 0.75 because we need to take into account the thickness of the bars and the position of <Straight /> components,
    };

    const active = isFirstRow
        ? row[0].completion >= 100
        : isPathColored(row[0], currentProject);

    return (
        <div
            // We use the first project of the row
            className={`progress-bordered ${active ? 'active' : ''}`}
            style={{
                borderStyle: 'solid',
                height: 250,
                position: 'absolute',
                width: '100%',
                zIndex,
                ...(isFirstRow ? firstRowStyle : lastRowStyle),
            }}
        />
    );
}
