import { IVideoRoom } from 'holberton-school-intranet-api';
import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';

import { match } from '../common/Search';

import VideoRoomsGrid from './VideoRoomsGrid';
import VideoRoomsHeadPanel, { VideoRoomsLayout } from './VideoRoomsHeadPanel';
import VideoRoomsList from './VideoRoomsList';

interface IProps {
    createVideoRoomURI: string | null;
    videoRooms: IVideoRoom[];
}

const LAYOUT_STORAGE_KEY = 'videoRoomsLayout';
const getLayout = (): VideoRoomsLayout => {
    const storedLayout = localStorage.getItem(LAYOUT_STORAGE_KEY);
    if (!['grid', 'layout'].includes(storedLayout)) {
        return 'grid';
    }
    return storedLayout as VideoRoomsLayout;
};

export default function Container({
    createVideoRoomURI,
    videoRooms,
}: IProps): ReactElement {
    const [videoRoomsList, setVideoRoomsList] = useState<IVideoRoom[]>(
        videoRooms,
    );
    const [search, setSearch] = useState<string>('');
    const [layout, setLayout] = useState<VideoRoomsLayout>(getLayout());

    useEffect(() => {
        localStorage.setItem(LAYOUT_STORAGE_KEY, layout);
    }, [layout]);

    useEffect(() => {
        let filtered = videoRooms;
        if (search) {
            filtered = videoRooms.filter(
                (vr) =>
                    match(search, vr.name) ||
                    match(search, vr.createdBy) ||
                    match(search, vr.block) ||
                    match(search, vr.level),
            );
        }

        setVideoRoomsList(filtered);
    }, [search]);

    return (
        <div>
            <VideoRoomsHeadPanel
                createVideoRoomURI={createVideoRoomURI}
                layout={layout}
                search={search}
                setLayout={setLayout}
                setSearch={setSearch}
            />

            {layout === 'grid' ? (
                <VideoRoomsGrid videoRooms={videoRoomsList} />
            ) : (
                <VideoRoomsList videoRooms={videoRoomsList} />
            )}
        </div>
    );
}
