import * as React from 'react';
import { ReactElement } from 'react';

import { useContainerContext } from './ContainerContext';

export default function End(): ReactElement {
    const context = useContainerContext();
    const { progression } = context;

    if (!progression || !['finished', 'overdue'].includes(progression.status)) {
        return null;
    }

    return (
        <div className="overview">
            <div
                className="description"
                dangerouslySetInnerHTML={{ __html: progression.html }}
            />

            <div>
                <a className="btn btn-lg btn-primary" href="/">
                    Go back to home
                </a>
            </div>
        </div>
    );
}
